import React, { useState, useEffect } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';
import { Table, Input, Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import NumberFormat from 'react-number-format';

import { GET_INVESTMENTS, INVESTMENT_COUNT } from './apollo_queries';

const columns = [
  {
    title: 'Image',
    dataIndex: 'imageUrl',
    sorter: true,
    showSorterTooltip: false,
    render: (url) => (
      <span>
        <img src={url} alt="lending" width={40} height={40} />
      </span>
    ),
  },
  {
    title: 'Address',
    dataIndex: 'address',
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: 'Maturity date',
    dataIndex: 'maturityDate',
    sorter: true,
    showSorterTooltip: false,
    render: (d) => (d ? <span>{moment(d).format('LLL')}</span> : 'N/A'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Funded',
    dataIndex: 'investmentBalance',
    render: (n) => (
      <NumberFormat
        displayType="text"
        value={n}
        thousandSeparator={true}
        prefix={'$'}
        renderText={(value) => <div>{value}</div>}
      />
    ),
  },
  {
    title: 'Action',
    dataIndex: '_id',
    render: (id, record) => (
      <Space size="middle">
        <Link
          style={{ color: '#1abc9c' }}
          to={`/properties-and-investments/${id}`}
        >
          Update
        </Link>

        <Link style={{ color: '#9b59b6' }} to={`/create-payout/${id}`}>
          Create payout
        </Link>
      </Space>
    ),
  },
];

const { Search } = Input;

export function Properties() {
  // initial load hooks
  const totalInvestment = useQuery(INVESTMENT_COUNT);
  const total = get(totalInvestment, 'data.investmentCount', 10);
  const history = useHistory();
  const location = useLocation();

  // local state
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState('updatedAt');
  const [sortDirection, setSortDirection] = useState('DESC');

  const { p } = qs.parse(location.search);

  useEffect(() => {
    if (p) {
      setPage(parseInt(p));
    }
  }, [p]);

  // updating hooks
  const { data, loading, refetch } = useQuery(GET_INVESTMENTS, {
    variables: { count: 10, q: query, page, sortBy, sortDirection },
  });

  const sessions = get(data, 'getInvestments', []);

  const onChange = (pagination, _, sorter) => {
    if (pagination.current !== page) {
      setPage(pagination.current);
      history.push(`?p=${pagination.current}`);
    }

    if (sorter) {
      if (sorter.field !== sortBy) {
        setSortBy(sorter.field);
      }

      if (sorter.order) {
        setSortDirection(sorter.order === 'ascend' ? 'ASC' : 'DESC');
      }
    }
  };

  return (
    <div style={{ padding: 15, paddingLeft: 10, paddingRight: 10 }}>
      <Search
        placeholder="Search here ..."
        enterButton="Search"
        size="large"
        onSearch={() => refetch()}
        onChange={(e) => setQuery(e.target.value)}
        value={query}
      />
      <br />
      <br />
      {(loading || totalInvestment.loading) && (
        <div className="web-load-wrap">
          <LoadingOutlined />
        </div>
      )}
      <Table
        rowKey={'_id'}
        columns={columns}
        dataSource={sessions}
        onChange={onChange}
        pagination={{
          total,
          pageSize: 10,
          position: ['bottomCenter'],
          current: page,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          hideOnSinglePage: true,
          pageSizeOptions: ['10', '20'],
        }}
        loading={loading || totalInvestment.loading}
      />
    </div>
  );
}
