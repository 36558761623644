import { LoadingOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Tag } from 'antd';
import { get, truncate } from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-apollo';
import { Link, useHistory } from 'react-router-dom';
import { ARTICLES } from './apollo_queries';
import moment from 'moment';
import { TableMainLabel, TableSubLabel } from '../components/styled';

const { Search } = Input;

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    sorter: true,
    showSorterTooltip: false,
    render: (title, { subtitle }) => (
      <Fragment>
        <TableMainLabel>
          {truncate(title, { length: 40, separator: ' ' })}
        </TableMainLabel>
        {subtitle && (
          <TableSubLabel>
            {truncate(subtitle, { length: 40, separator: ' ' })}
          </TableSubLabel>
        )}
      </Fragment>
    ),
  },

  {
    title: 'Route',
    dataIndex: 'route',
    sorter: true,
    showSorterTooltip: false,
    render: (r, { slug }) => (
      <Tag
        style={{ letterSpacing: 0.8, fontWeight: 'bold' }}
        color="geekblue"
      >{`/${r}${slug ? `/${slug}` : ''}`}</Tag>
    ),
  },
  {
    title: 'Archived',
    dataIndex: 'archived',
    sorter: true,
    showSorterTooltip: false,
    render: (a) =>
      a ? <Tag color="#7f8c8d">YES</Tag> : <Tag color="#2ecc71">NO</Tag>,
  },
  {
    title: 'Last updated',
    dataIndex: 'updatedAt',
    sorter: true,
    showSorterTooltip: false,
    render: (d) => <div>{moment(d).startOf('hour').fromNow()}</div>,
  },
  {
    title: 'Action',
    render: (_, item) => (
      <Space size="middle">
        <Link style={{ color: '#3498db' }} to={`/articles/${item._id}`}>
          Open
        </Link>
      </Space>
    ),
  },
];

export function Articles({ constLoading = false, totalArticles = 0 }) {
  const history = useHistory();
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState('updatedAt');
  const [sortDirection, setSortDirection] = useState('DESC');
  const { data, loading, error, refetch } = useQuery(ARTICLES, {
    variables: { count: 100, page: 1, q: query, sortBy, sortDirection },
  });

  useEffect(() => {
    async function r() {
      await refetch();
    }

    r();
  }, [refetch]);

  if (constLoading) {
    return (
      <div className="web-load-wrap">
        <LoadingOutlined />
      </div>
    );
  }

  if (error) {
    return <div>Please refresh page, if error persists contact JC</div>;
  }

  const onChange = (pagination, _, sorter) => {
    if (pagination.current !== page) {
      setPage(pagination.current);
      history.push(`?p=${pagination.current}`);
    }

    if (sorter) {
      if (sorter.field !== sortBy) {
        setSortBy(sorter.field);
      }

      if (sorter.order) {
        setSortDirection(sorter.order === 'ascend' ? 'ASC' : 'DESC');
      }
    }
  };

  const items = get(data, 'articles', []);

  return (
    <div style={{ paddingBottom: 30 }} className="web-chat__main-content">
      <div className="tr__header">
        <div className="top_header">
          <div style={{ fontWeight: 'bolder' }} className="sub-text">
            Articles
          </div>
        </div>
        <div className="tr__b">
          <Button
            style={{ marginLeft: 10 }}
            role="button"
            onClick={() => history.push('/add-article')}
            type="primary"
          >
            Create
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            role="button"
            onClick={() => history.goBack()}
            type="primary"
            danger
          >
            Back
          </Button>
        </div>
      </div>
      <div className="creating">
        <div className="create-b b-n m-c">
          <div style={{ background: 'white' }}>
            <Search
              placeholder="Search..."
              enterButton="Search"
              size="large"
              onSearch={() => refetch()}
              onChange={(e) => setQuery(e.target.value)}
              value={query}
            />
            <br />
            <br />
            <Table
              rowKey={'_id'}
              columns={columns}
              dataSource={items}
              onChange={onChange}
              pagination={{
                total: totalArticles,
                pageSize: 10,
                position: ['bottomCenter'],
                current: page,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                hideOnSinglePage: true,
                pageSizeOptions: ['10'],
              }}
              size={10}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
