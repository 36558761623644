import React, { useEffect } from 'react';

export function Repairs() {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location.href =
        'https://constitutionmgmt.typeform.com/to/Zcj9Ua5a';
    }
  }, []);

  return (
    <a href="https://constitutionmgmt.typeform.com/to/Zcj9Ua5a">
      Go to repairs
    </a>
  );
}
