import { Alert, Button, message, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { TableMainLabel } from '../components/styled';
import { HANDLES } from './apollo_queries';
import { isEmpty } from 'lodash';
import { CSVLink } from 'react-csv';
import { CHECK_USER_KYC_ADMIN } from './apollo_mutations';
import { Fragment } from 'react';
import Modal from 'antd/lib/modal/Modal';
import NumberFormat from 'react-number-format';

function KycChecker({
  userId = '',
  userAccountId = '',
  refetch = () => Promise.resolve({}),
  name = '',
}) {
  const [check, checkData] = useMutation(CHECK_USER_KYC_ADMIN);
  const [userKyc, setUserKyc] = useState(null);

  const checkKyc = async () => {
    try {
      const { data } = await check({ variables: { userId, userAccountId } });
      const resp = data?.checkKycByAdmin;
      setUserKyc(resp);
      await refetch();
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <Fragment>
      <Button
        onClick={() => checkKyc()}
        loading={checkData.loading}
        disabled={checkData.loading}
      >
        Check KYC
      </Button>
      <Modal
        width={800}
        title={`${name} | ${userAccountId}`}
        visible={!isEmpty(userKyc)}
        onOk={() => setUserKyc(null)}
        onCancel={() => setUserKyc(null)}
      >
        {userKyc &&
          (userKyc.verification_status === 'review' ||
            userKyc.verification_status === 'failed') && (
            <Link
              style={{ fontWeight: 'bolder', marginBottom: 20 }}
              to={`/users/${userId}`}
            >
              See reason(s) of failure here
            </Link>
          )}
        <pre>
          <samp>{userKyc && JSON.stringify(userKyc, null, 4)}</samp>
        </pre>
      </Modal>
    </Fragment>
  );
}

export function SilaUserHandles() {
  const { data, loading, error, refetch } = useQuery(HANDLES);
  const [csvData, setCsvData] = useState([]);
  const history = useHistory();

  const columns = [
    {
      title: 'No.',
      render: (_, p, index) => <TableMainLabel>{++index}</TableMainLabel>,
    },
    {
      title: 'Handle',
      dataIndex: '_id',
      render: (t) => (
        <TableMainLabel>
          <pre style={{ margin: 0, padding: 0 }}>
            <samp style={{ margin: 0, padding: 0 }}>{t}</samp>
          </pre>
        </TableMainLabel>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'accountName',
      render: (t, { firstName, lastName, type }) => (
        <div>{type === 'entity' ? t : `${firstName} ${lastName}`}</div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (t) => <div>{t}</div>,
    },
    {
      title: 'Kyc',
      dataIndex: 'kyc',
      render: (t) => <div>{t}</div>,
    },
    {
      title: 'Login Email',
      render: (_, { user: { email } }) => <div>{email}</div>,
    },
    {
      title: 'Admin',
      render: (_, { user: { isAdmin } }) => <div>{isAdmin ? 'yes' : 'no'}</div>,
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      render: (t) => <div>{moment(t).format('LL')}</div>,
    },
    {
      title: 'Pending Deposit',
      dataIndex: 'pendingDepositTotal',
      render: (i) => (
        <NumberFormat
          displayType="text"
          value={i}
          thousandSeparator={true}
          prefix={'$'}
          renderText={(value) => <div>{value}</div>}
        />
      ),
    },
    {
      title: 'Pending Invest',
      dataIndex: 'pendingInvestTotal',
      render: (i) => (
        <NumberFormat
          displayType="text"
          value={i}
          thousandSeparator={true}
          prefix={'$'}
          renderText={(value) => <div>{value}</div>}
        />
      ),
    },
    {
      title: 'Deposit',
      dataIndex: 'currentDepositTotal',
      render: (i) => (
        <NumberFormat
          displayType="text"
          value={i}
          thousandSeparator={true}
          prefix={'$'}
          renderText={(value) => <div>{value}</div>}
        />
      ),
    },
    {
      title: 'Invest',
      dataIndex: 'currentInvestTotal',
      render: (i) => (
        <NumberFormat
          displayType="text"
          value={i}
          thousandSeparator={true}
          prefix={'$'}
          renderText={(value) => <div>{value}</div>}
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: '_id',
      render: (handle, { user, firstName, lastName, accountName, type }) => (
        <KycChecker
          userId={user._id}
          userAccountId={handle}
          refetch={refetch}
          name={type === 'entity' ? accountName : `${firstName} ${lastName}`}
        />
      ),
    },
  ];

  const items = data?.userAccounts || [];

  useEffect(() => {
    if (!isEmpty(items)) {
      const dataLocal = [
        [
          'No.',
          'Handle',
          'Name',
          'Type',
          'Kyc',
          'Login Email',
          'Admin',
          'Created',
        ],
      ];
      let index = 1;

      for (const i of items) {
        const row = [];
        row.push(index++);
        row.push(i._id);
        const name =
          i.type === 'entity' ? i.accountName : `${i.firstName} ${i.lastName}`;
        row.push(name);
        row.push(i.type);
        row.push(i.kyc);
        row.push(i?.user?.email?.toLowerCase() || '');
        row.push(i?.user?.isAdmin ? 'yes' : 'no');
        row.push(moment(i.createdAt).format('LL'));
        dataLocal.push(row);
      }

      setCsvData(dataLocal);
    }
  }, [loading, items]);

  return (
    <div style={{ paddingBottom: 30 }} className="web-chat__main-content">
      <div className="tr__header">
        <div className="top_header">
          <div style={{ fontWeight: 'bolder' }} className="sub-text">
            Sila User Handles
          </div>
        </div>
        <div className="tr__b">
          <Button onClick={() => {}} style={{ marginRight: 30 }}>
            <pre style={{ margin: 0, padding: 0 }}>
              <samp
                style={{ margin: 0, padding: 0 }}
              >{`Total ${items.length}`}</samp>
            </pre>
          </Button>

          <CSVLink data={csvData}>Download CSV</CSVLink>

          <Button
            style={{ marginLeft: 15 }}
            role="button"
            onClick={() => history.goBack()}
            type="primary"
            danger
          >
            Back
          </Button>
        </div>
      </div>
      <div className="creating">
        <div className="create-b b-n m-c">
          <div style={{ background: 'white' }}>
            {error && <Alert message={error.message} type="error" />}
            <Table
              loading={loading}
              columns={columns}
              dataSource={items}
              rowKey={'_id'}
              size="small"
              pagination={{
                pageSize: items.length,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
