import { Input, Modal, Select, Form, message } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useMutation } from 'react-apollo';
import { ChromePicker } from 'react-color';
import { tagTypes } from '../guide';
import { CREATE_TAG } from '../pages/apollo_mutations';

const FormItem = Form.Item;
const { Option } = Select;

export function AddTagModal({
  visible = false,
  title = '',
  handleClose = () => {},
  refetch = () => Promise.resolve({}),
}) {
  const [form] = Form.useForm();
  const [colorLocal, setColorLocal] = useState('');
  const handleChangeColor = (color) => {
    setColorLocal(color);
  };
  const [createTag, createData] = useMutation(CREATE_TAG);

  return (
    <Modal
      visible={visible}
      title={title}
      onOk={() => form.submit()}
      onCancel={handleClose}
      okText="Submit"
      confirmLoading={createData.loading}
    >
      <div style={{ background: 'white', padding: 20, paddingTop: 5 }}>
        <Form
          initialValues={{ type: tagTypes.property.name }}
          layout="vertical"
          form={form}
          name="create_tag"
          onFinish={async (values) => {
            if (!colorLocal) {
              return message.error('Color is required');
            }

            const { name, type } = values;

            if (!name) {
              return message.error('Label is required');
            }

            try {
              await createTag({
                variables: { data: { name, type, color: colorLocal.hex } },
              });
              await refetch();

              return handleClose();
            } catch (error) {
              message.error(error.message);
            }
          }}
        >
          <FormItem name="name" label="Label" required>
            <Input name="name" placeholder="Tag label" required />
          </FormItem>
          <FormItem name="type" label="Tag type" required>
            <Select required>
              {tagTypes.items.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem name="color" label="Tag color" required>
            <ChromePicker
              onChange={handleChangeColor}
              onSwatchHover={handleChangeColor}
              color={colorLocal}
              width={410}
            />
          </FormItem>
        </Form>
      </div>
    </Modal>
  );
}
