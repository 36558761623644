import React, { useEffect, useState } from 'react';
import { Table, InputNumber, Popconfirm, Form, Button, Select } from 'antd';
import { isEmpty } from 'lodash';
import { TRANSACTION_TYPES } from '../pages/CreatePayout';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const { Option } = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === 'number' ? (
      <InputNumber />
    ) : (
      <Select size="small">
        {TRANSACTION_TYPES.map((item) => (
          <Option key={item} value={item}>
            {item}
          </Option>
        ))}
      </Select>
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const EditableTable = ({
  originData = [],
  localColumns = [],
  title = '',
  loading = false,
  generalNote = '',
  onSubmit = () => Promise.resolve({}),
  pushToPayout = () => {},
  payoutId = '',
  amountToSend = '',
  isSelectable = false,
  isUsingOweAmount = false,
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editingKey, setEditingKey] = useState('');

  useEffect(() => {
    if (!isEmpty(originData)) {
      const newData = originData.map((item) => ({
        ...item,
        note: generalNote,
      }));
      setData(newData);
    }
  }, [generalNote, originData]);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    ...localColumns,
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Button>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Button danger>Cancel</Button>
            </Popconfirm>
          </span>
        ) : (
          <Button
            htmlType="button"
            disabled={editingKey !== ''}
            onClick={() => edit(record)}
          >
            Edit
          </Button>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'note' ? 'text' : 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onSelectChange = (selected = []) => {
    setSelectedRowKeys(selected);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Form form={form} component={false}>
      {isSelectable ? (
        <Table
          title={() => <h3>{title}</h3>}
          size="small"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
            total: originData.length,
            position: ['bottomCenter'],
            hideOnSinglePage: true,
            pageSize: 20,
          }}
          rowKey="_id"
          rowSelection={rowSelection}
        />
      ) : (
        <Table
          title={() => <h3>{title}</h3>}
          size="small"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
            total: originData.length,
            position: ['bottomCenter'],
            hideOnSinglePage: true,
            pageSize: 20,
          }}
          rowKey="_id"
        />
      )}

      <div style={{ height: 20 }}></div>

      <Button
        size="large"
        htmlType="button"
        type="primary"
        loading={loading}
        onClick={async () => {
          MySwal.fire({
            title: 'Please confirm payout action',
            icon: 'warning',
            confirmButtonText: 'Send',
            confirmButtonColor: '#e74c3c',
            showCancelButton: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              if (isSelectable) {
                const inputData = data.filter((i) =>
                  selectedRowKeys.includes(i._id)
                );
                await onSubmit(inputData);
              } else {
                await onSubmit(data);
              }
            }
          });
        }}
        disabled={
          isUsingOweAmount
            ? false
            : loading ||
              !amountToSend ||
              amountToSend === 0 ||
              parseFloat(amountToSend) === 0
        }
      >
        Send
      </Button>
      {payoutId && (
        <Button
          style={{ marginLeft: 10 }}
          size="large"
          htmlType="button"
          loading={loading}
          onClick={pushToPayout}
        >
          Go to payout summary
        </Button>
      )}
    </Form>
  );
};
