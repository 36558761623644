import React, { Fragment } from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { Avatar, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Linkify from 'linkifyjs/react';

function Message({ currentMessage, nextMessage, isSms = true }) {
  const renderDay = () => {
    const { createdAt } = currentMessage;

    console.log({ createdAt });

    if (!createdAt) {
      return null;
    }

    if (nextMessage) {
      if (moment(nextMessage.createdAt).diff(moment(createdAt), 'days') === 0) {
        return null;
      }

      if (moment(nextMessage.createdAt).diff(moment(createdAt), 'days') > 0) {
        return (
          <div className="section__date">{moment(createdAt).format('ll')}</div>
        );
      }
    }

    if (moment().diff(moment(createdAt), 'hours') === 0) {
      return (
        <div className="section__date">{moment(createdAt).calendar()}</div>
      );
    }

    return (
      <div className="section__date">{moment(createdAt).format('ll')}</div>
    );
  };

  return (
    <div>
      {renderDay()}
      <div className="text__main-wrap">
        <div className="avatar-w">
          {currentMessage.owner === 'Constitution Management' ? (
            <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
              C
            </Avatar>
          ) : (
            <Avatar
              style={{ backgroundColor: '#87d068' }}
              icon={<UserOutlined />}
            />
          )}
        </div>
        <div className="spacerh5" />
        <div className="info-w">
          <div>
            <span className="name-text">
              {currentMessage &&
                (currentMessage.owner ||
                  currentMessage.email ||
                  currentMessage.phoneNumber)}
            </span>
            &nbsp;&nbsp;
            <span className="name-tg">
              {moment(parseInt(currentMessage.createdAt)).format('LT')}
            </span>
          </div>

          {isEmpty(currentMessage.media) ? (
            <div
              className={
                currentMessage.owner === 'Constitution Management'
                  ? 'message-c message-m'
                  : 'message-c'
              }
            >
              {!isSms && currentMessage.subject && (
                <Linkify>
                  <div style={{ fontWeight: '800', fontSize: 14 }}>
                    Subject: {currentMessage.subject}
                  </div>
                </Linkify>
              )}
              <div style={{ height: 5 }}></div>
              <Linkify>
                {currentMessage.text.split('\n').map((item, index) => (
                  <Fragment key={index}>
                    {item}
                    <br />
                  </Fragment>
                ))}
              </Linkify>
            </div>
          ) : (
            <div
              className={
                isEmpty(currentMessage.text)
                  ? 'image__mw img_w_txt'
                  : 'image__mw'
              }
            >
              {currentMessage.media.map((item, index) =>
                item.type &&
                (item.type.includes('mp4') ||
                  item.type.includes('webm') ||
                  item.type.includes('ogg')) ? (
                  <video key={index} width="500" height="350" controls>
                    <source src={item.previewLink} type="video/mp4" />
                    <source src={item.previewLink} type="video/ogg" />
                    <source src={item.previewLink} type="video/webm" />
                  </video>
                ) : (
                  <a
                    key={index}
                    href={item.previewLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img alt="MMS Content" src={item.previewLink} />
                  </a>
                )
              )}

              {currentMessage.text && (
                <div
                  className={
                    currentMessage.owner === 'Constitution Management'
                      ? 'message-c m_w_img message-m'
                      : 'message-c m_w_img'
                  }
                >
                  {!isSms && currentMessage.subject && (
                    <Linkify>
                      <div style={{ fontWeight: '800', fontSize: 14 }}>
                        Subject: {currentMessage.subject}
                      </div>
                    </Linkify>
                  )}
                  <div style={{ height: 5 }}></div>
                  <Linkify>
                    {currentMessage.text.split('\n').map((item, index) => (
                      <Fragment key={index}>
                        {item}
                        <br />
                      </Fragment>
                    ))}
                  </Linkify>
                </div>
              )}
            </div>
          )}
          {currentMessage && currentMessage.error && (
            <Tag
              style={{
                fontSize: 9,
                padding: 0,
                border: 'none',
                background: 'transparent',
              }}
              color="error"
            >
              {currentMessage.error}
            </Tag>
          )}
        </div>
      </div>
    </div>
  );
}

export default Message;
