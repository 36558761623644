import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Input, Form, Select, message, DatePicker, Checkbox, Row, Col } from 'antd';
import { useMutation, useQuery } from 'react-apollo';
import moment from 'moment';

import { UPDATE_SESSION } from './apollo_mutations';
import { GET_SESSION } from './apollo_queries';
import { omit, get, isEmpty } from 'lodash';

const Option = Select.Option;
const TextArea = Input.TextArea;
const FormItem = Form.Item;

message.config({
  top: 100,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

function UpdateSession() {
  const history = useHistory();
  const match = useRouteMatch();
  const sessionId = get(match, 'params.id', '');
  const [form] = Form.useForm();

  useEffect(() => {
    function redirect() {
      if (!sessionId) {
        history.push('/');
      }
    }

    redirect();
  }, [history, sessionId]);

  const { data, error, loading } = useQuery(GET_SESSION, { variables: { id: sessionId } });
  const [mutate] = useMutation(UPDATE_SESSION);
  const session = get(data, 'getSession', {});
  const {
    phoneNumber,
    propertyId,
    email,
    name,
    state,
    city,
    address,
    showingAt,
    confirmed,
    leadInterested,
    sectionEight,
    personCategory,
    comment,
  } = session;

  return (
    <div className="web-chat__main-content">
      <div className="tr__header">
        <div className="top_header">
          <div style={{ fontWeight: 'bolder' }} className="sub-text">
            Update session
          </div>
          <div className="sub-text">{`Name: ${name}`}</div>
        </div>
        <div className="tr__b">
          <Button style={{ marginLeft: 10 }} role="button" onClick={() => history.push('/')} type="primary" danger>
            Back
          </Button>
        </div>
      </div>

      {error && (
        <div className="messages">
          <div className="messages-history">
            <div className="web-load-wrap">Error, please refresh the page.</div>
          </div>
        </div>
      )}

      {loading && (
        <div className="messages">
          <div className="messages-history">
            <div className="web-load-wrap">
              <LoadingOutlined />
            </div>
          </div>
        </div>
      )}

      {!error && !loading && (
        <Form
          style={{ width: '100%' }}
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 20 }}
          onFinish={async (values) => {
            let media = [];
            const input = omit(values, ['imageUrl']);

            if (values && values.imageUrl) {
              media.push({
                previewLink: values.imageUrl,
                downloadLink: values.imageUrl,
              });
            }

            const toSendSms = values && !!values.phoneNumber;
            const toSendEmail = values && !!values.email;

            input.media = media;
            input.toSendEmail = toSendEmail;
            input.toSendSms = toSendSms;
            input.phoneNumber = values && values.phoneNumber && `+1${values.phoneNumber}`;
            input.showingAt = values && values.showingAt && values.showingAt.toString();
            input.sessionId = sessionId;

            try {
              const { data } = await mutate({ variables: { data: input } });
              const session = get(data, 'updateSession', {});
              if (!isEmpty(session)) {
                message.info('Successfully updated session');
              }
            } catch (error) {
              message.error('Error', error.message);
            }
          }}
          initialValues={{
            phoneNumber: phoneNumber ? phoneNumber.replace('+1', '') : null,
            propertyId,
            email,
            name,
            state,
            city,
            address,
            showingAt: showingAt ? moment(parseFloat(showingAt)) : moment(),
            confirmed,
            leadInterested,
            sectionEight,
            personCategory,
            comment,
            job: session && session.job,
          }}
          name="update-session"
        >
          <div className="creating" style={{ paddingTop: 35 }}>
            <div className="f b-n m-c">
              <div style={{ background: 'white' }}>
                <FormItem
                  name="name"
                  label="Name"
                  required
                  rules={[{ type: 'string', required: true, message: 'Required' }]}
                >
                  <Input name="name" placeholder="Name" />
                </FormItem>

                <FormItem
                  name="address"
                  label="Address"
                  required
                  rules={[{ type: 'string', required: true, message: 'Required' }]}
                >
                  <Input name="address" placeholder="Address (property address if prospective tenant)" />
                </FormItem>

                <FormItem
                  name="personCategory"
                  label="Person Category"
                  required
                  rules={[{ type: 'string', required: true, message: 'Required' }]}
                >
                  <Select name="personCategory" style={{ width: '100%' }}>
                    <Option value={null}>Select type of recipient</Option>
                    <Option value="Prospective Tenant">Propective Tenant</Option>
                    <Option value="Prospective Employee">Propective Employee</Option>
                  </Select>
                </FormItem>

                <FormItem name="email" label="Email" rules={[{ type: 'email', message: 'Invalid format' }]}>
                  <Input name="email" placeholder="Email" type="email" />
                </FormItem>

                <FormItem name="phoneNumber" label="Phone number" rules={[{ len: 10, message: 'Invalid format' }]}>
                  <Input name="phonerNumber" placeholder="10 digit US number e.g. 2033496942" type="number" />
                </FormItem>

                <FormItem name="city" label="City">
                  <Input name="city" placeholder="City" />
                </FormItem>

                <FormItem name="state" label="State">
                  <Input name="state" placeholder="State" />
                </FormItem>
              </div>
            </div>
            <div className="f b-n m-c">
              <div style={{ background: 'white' }}>
                <FormItem name="propertyId" label="Property Address">
                  <Select name="propertyId" style={{ width: '100%' }}>
                    <Option value={null}>Select property address</Option>
                    <Option value="5835626">221 Jefferson St</Option>
                    <Option value="5362854">167 E Main St</Option>
                    <Option value="5365404">31 East St</Option>
                    <Option value="5743818">2352 Barnum Ave</Option>
                    <Option value="5830888">158 Park St</Option>
                  </Select>
                </FormItem>

                <FormItem name="job" label="Job type">
                  <Input name="job" placeholder="Job (if propective employee only)" />
                </FormItem>

                <FormItem name="showingAt" label="Showing date" className="add-plan-datepicker-field">
                  <DatePicker
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    style={{ width: '100%' }}
                    className="date-picker"
                  />
                </FormItem>

                <FormItem name="comment" label="Comment">
                  <TextArea name="comment" placeholder="Constitution management comment" />
                </FormItem>

                <Row gutter={24}>
                  <Col xs={0} sm={0} md={6}></Col>
                  <Col xs={24} sm={12} md={6}>
                    <FormItem name="leadInterested" valuePropName="checked">
                      <Checkbox>Lead Interested</Checkbox>
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <FormItem name="sectionEight" valuePropName="checked">
                      <Checkbox>Section 8</Checkbox>
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <FormItem name="confirmed" valuePropName="checked">
                      <Checkbox>Confirmed</Checkbox>
                    </FormItem>
                  </Col>
                </Row>

                <FormItem
                  wrapperCol={{
                    sm: {
                      span: 16,
                      offset: 6,
                    },
                  }}
                >
                  <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
                    Update
                  </Button>

                  <Button type="danger" htmlType="button" onClick={() => history.push(`/session/${sessionId}`)}>
                    Go to session
                  </Button>
                </FormItem>
              </div>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
}

export default UpdateSession;
