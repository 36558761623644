import { LoadingOutlined } from '@ant-design/icons';
import { Button, Collapse, Descriptions, Table, Tag } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { useQuery } from 'react-apollo';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { INVESTOR } from './apollo_queries';
import { KycFailSendEmailForm } from '../components/KycFailSendEmail';
import NumberFormat from 'react-number-format';

const { Panel } = Collapse;

const columns = [
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (status) =>
      status === 'success' ? (
        <Tag color="green">{status.toUpperCase()}</Tag>
      ) : (
        <Tag color="red">{status.toUpperCase()}</Tag>
      ),
  },
  {
    title: 'Transaction Type',
    key: 'type',
    dataIndex: 'type',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount) => (
      <NumberFormat
        displayType="text"
        value={amount}
        thousandSeparator={true}
        prefix={'$'}
        renderText={(value) => <span>{value}</span>}
      />
    ),
  },
  {
    title: 'Account Name',
    key: 'userAccount',
    dataIndex: 'userAccount',
    render: (_, { accountName }) => <span>{accountName}</span>,
  },
  {
    title: 'Transaction Date',
    key: 'createdAt',
    dataIndex: 'createdAt',
    render: (createdAt) => <span>{moment(createdAt).format('LLL')}</span>,
  },
];

export function InvestorDetails({ constLoading = false }) {
  const match = useRouteMatch();
  const userId = get(match, 'params.id', '');
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const history = useHistory();

  const { data, error, loading, refetch } = useQuery(INVESTOR, {
    variables: { userId, page, count: pageCount },
  });

  if (loading || constLoading) {
    return (
      <div className="web-load-wrap">
        <LoadingOutlined />
      </div>
    );
  }

  if (error) {
    return <div>Error. Please refresh the page or contact JC</div>;
  }

  const onChange = (pagination, _, sorter) => {
    if (pagination.current !== page) {
      setPage(pagination.current);
      history.push(`?p=${pagination.current}`);
    }

    if (pageCount !== pagination.pageSize) {
      setPageCount(pagination.pageSize);
    }
  };

  const {
    address,
    email,
    phoneNumber,
    createdAt,
    transactionItemsCount,
    transactionItems,
  } = get(data, 'getUserByAdmin', {});

  const individualKycFails = get(
    data,
    'getUserByAdmin.individualAccount.kycFails',
    []
  );
  const kyc = data?.getUserByAdmin?.individualAccount?.kyc || '';
  const accountName =
    data?.getUserByAdmin?.individualAccount?.accountName || '';
  const entityAccounts = get(data, 'getUserByAdmin.entityAccounts', []);
  const firstName = data?.getUserByAdmin?.individualAccount?.firstName || '';
  const lastName = data?.getUserByAdmin?.individualAccount?.lastName || '';
  const userFirstName = data?.getUserByAdmin?.firstName || '';
  const userLastName = data?.getUserByAdmin?.lastName || '';

  return (
    <div style={{ padding: 10 }}>
      <Descriptions
        title={
          firstName && lastName
            ? `${firstName} ${lastName}`
            : userFirstName && userLastName
            ? `${userFirstName} ${userLastName}`
            : email
        }
        size="small"
        extra={
          <Button onClick={async () => refetch()} type="primary">
            Refresh
          </Button>
        }
        bordered
      >
        <Descriptions.Item label="Address">{address}</Descriptions.Item>
        <Descriptions.Item label="Email">{email}</Descriptions.Item>
        <Descriptions.Item label="Phone">
          <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        </Descriptions.Item>
        <Descriptions.Item label="Registered date">
          {moment(createdAt).format('LLL')}
        </Descriptions.Item>
      </Descriptions>
      <div style={{ height: 20 }}></div>
      <Descriptions title="Individual account" size="small" bordered>
        <Descriptions.Item label="KYC Status">{kyc}</Descriptions.Item>
        <Descriptions.Item label="Account name">
          {accountName}
        </Descriptions.Item>
      </Descriptions>

      <div style={{ height: 20 }}></div>

      <Descriptions
        title="Transaction Details"
        size="small"
        bordered
      ></Descriptions>

      <Table
        rowKey={'_id'}
        columns={columns}
        dataSource={transactionItems}
        onChange={onChange}
        pagination={{
          total: transactionItemsCount,
          position: ['bottomCenter'],
          current: page,
          pageSize: pageCount,
          pageSizeOptions: ['10', '20', '30', '50'],
          hideOnSinglePage: true,
        }}
        size={pageCount}
        loading={loading || constLoading}
      />

      {!isEmpty(individualKycFails) && (
        <Fragment>
          <div style={{ height: 10 }}></div>
          <Collapse>
            {individualKycFails.map((item) => (
              <Panel
                header={get(
                  item,
                  'silaFailure.rawTag',
                  'Invalid, please disregard'
                )}
              >
                <KycFailSendEmailForm {...item} refetch={refetch} />
              </Panel>
            ))}
          </Collapse>
        </Fragment>
      )}
      <div style={{ height: 20 }}></div>

      {!isEmpty(entityAccounts) &&
        entityAccounts.map((item) => (
          <Fragment key={item._id}>
            <Descriptions title={`${item.accountName}`} size="small" bordered>
              <Descriptions.Item label="KYC Status">
                {item.kyc}
              </Descriptions.Item>
              <Descriptions.Item label="Address">
                {item.address}
              </Descriptions.Item>
            </Descriptions>
            {!isEmpty(item.kycFails) && (
              <Fragment>
                <div style={{ height: 10 }}></div>
                <Collapse style={{ borderColor: '#e74c3c' }}>
                  {item.kycFails.map((k) => (
                    <Panel
                      style={{ borderColor: '#e74c3c' }}
                      key={k._id}
                      header={get(
                        k,
                        'silaFailure.rawTag',
                        'Invalid, please disregard'
                      )}
                    >
                      <KycFailSendEmailForm {...k} refetch={refetch} />
                    </Panel>
                  ))}
                </Collapse>
              </Fragment>
            )}
            <div style={{ height: 20 }}></div>
          </Fragment>
        ))}
    </div>
  );
}
