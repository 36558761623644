import React, { useEffect, useState } from 'react';
import { object, bool, string, func, array } from 'prop-types';
import { message, Button, Select } from 'antd';
import { get, isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { usePlaidLink } from 'react-plaid-link';
import styled from 'styled-components';
import { RegisterIndividualForm } from '../components/RegisterIndividualForm';
import { RegisterEntityForm } from '../components/RegisterEntityForm';
import { IndividualInfo } from '../components/IndividualInfo';
import { Entities } from '../components/EntityInfo';
import { LINK_ACCOUNT } from './apollo_mutations';
import { isProd } from '..';
import { FundSilaAccount } from '../components/FundSilaAccount';
import NumberFormat from 'react-number-format';
import { PendingRewards } from '../components/PendingRewards';

const H3 = styled.h3`
  color: #1abc9c;
  font-weight: bolder;
  letter-spacing: 0.6;
  font-size: 18px;
`;

const propTypes = {
  constData: object,
  constError: object,
  constLoading: bool,
  kyc: string,
  isAdmin: bool,
  isCertified: bool,
  refetch: func,
  silaAccounts: array,
  hasIndividualBankAccount: bool,
  hasIndividualUserAccount: bool,
  hasEntityBankAccount: bool,
  hasEntityUserAccount: bool,
  firstName: string,
  lastName: string,
  individualAccount: object,
  entityAccounts: array,
};

const defaultProps = {
  constData: {},
  constError: null,
  constLoading: false,
  kyc: '',
  isAdmin: false,
  isCertified: false,
  refetch: () => {},
  silaAccounts: [],
  hasIndividualBankAccount: false,
  hasIndividualUserAccount: false,
  hasEntityBankAccount: false,
  hasEntityUserAccount: false,
  firstName: '',
  lastName: '',
  individualAccount: null,
  entityAccounts: [],
};

const { Option } = Select;

function LinkAccountButton({
  firstName,
  lastName,
  refetch,
  selectedUserAcount,
  email,
  businessName,
}) {
  const [plaidToken, setPlaidToken] = useState(null);
  const [linkAccount, linkAccountData] = useMutation(LINK_ACCOUNT);

  const onSuccess = async (token, metadata) => {
    console.log({ metadata });
    setPlaidToken(token);
    const accountName = get(
      metadata,
      'account.name',
      `${firstName} ${lastName}`
    );
    try {
      await linkAccount({
        variables: {
          userAccountId: selectedUserAcount,
          token,
          accountName,
          bankAccountId: metadata?.account_id || '',
        },
      });
      await refetch();
      message.success('Request success');
    } catch (error) {
      message.error(
        error.message
          ? error.message.replace('GraphQL error:', '')
          : 'Please try again',
        8
      );
    }
  };

  const { open, ready, error } = usePlaidLink({
    clientName: businessName,
    env: isProd ? 'production' : 'sandbox',
    product: ['auth'],
    accountSubtypes: {
      depository: ['checking'],
    },
    publicKey: 'fa9dd19eb40982275785b09760ab79',
    userLegalName: `${firstName} ${lastName}`,
    userEmailAddress: email,
    token: plaidToken ? plaidToken : null,
    onSuccess,
    webhook: 'https://api.silamoney.com/0.2/plaid_webhook_auth',
  });

  useEffect(() => {
    if (error) {
      message.error(error, 5);
    }
  }, [error]);

  return (
    <Button
      type="primary"
      htmlType="button"
      disabled={!ready}
      loading={linkAccountData.loading}
      onClick={open}
      style={{ marginRight: 5, marginLeft: 15 }}
    >
      Link Bank Account
    </Button>
  );
}

export function RegisterToSila(props) {
  const {
    hasEntityUserAccount,
    hasIndividualUserAccount,
    refetch,
    firstName,
    lastName,
    individualAccount,
    entityAccounts,
    email,
    constLoading,
    pendingRewards,
  } = props;

  const history = useHistory();
  const [selectedUserAcount, selectUserAccount] = useState(null);
  const [businessName, setBusinessName] = useState(
    `${individualAccount?.firstName || ''} ${individualAccount?.lastName || ''}`
  );
  // const [sync, syndData] = useMutation(SYNC_SILA);

  if (constLoading) {
    return (
      <div className="web-load-wrap">
        <LoadingOutlined />
      </div>
    );
  }

  const defaultAccount = isEmpty(entityAccounts)
    ? null
    : entityAccounts.filter((i) => i.isDefault);
  const defaultAccountOne = isEmpty(defaultAccount) ? null : defaultAccount[0];

  return (
    <div className="web-chat__main-content wc__solo">
      <div className="tr__header">
        <div className="top_header">
          <div style={{ fontWeight: 'bolder' }} className="sub-text">
            Constlending - Sila Registration Status
          </div>
        </div>
        <div className="tr__b">
          {hasEntityUserAccount && !isEmpty(defaultAccountOne) && (
            <FundSilaAccount
              accountName={get(defaultAccountOne, 'accountName', '')}
              userAccountId={get(defaultAccountOne, '_id', '')}
              bankAccounts={get(defaultAccountOne, 'bankAccounts', [])}
              availableFund={get(defaultAccountOne, 'availableFund', 0)}
            />
          )}
          {!isEmpty(individualAccount) && (
            <Select
              style={{ width: 240 }}
              value={selectedUserAcount}
              onChange={(value, option) => {
                selectUserAccount(value);
                setBusinessName(option.children);
              }}
            >
              <Option value={null}>Select a user account</Option>
              <Option
                value={individualAccount._id}
              >{`${individualAccount?.firstName} ${individualAccount?.lastName}`}</Option>
              {!isEmpty(entityAccounts) &&
                entityAccounts.map((item) => (
                  <Option key={item._id} value={item._id}>
                    {item.accountName}
                  </Option>
                ))}
            </Select>
          )}

          {selectedUserAcount && (
            <LinkAccountButton
              selectedUserAcount={selectedUserAcount}
              refetch={refetch}
              firstName={firstName}
              lastName={lastName}
              email={email}
              businessName={businessName}
            />
          )}

          <Button
            style={{ marginLeft: 10 }}
            role="button"
            onClick={() => refetch()}
            type="dashed"
            danger
          >
            Refresh Data
          </Button>

          <Button
            style={{ marginLeft: 10 }}
            role="button"
            onClick={() => history.push('/')}
            type="primary"
            danger
          >
            Back
          </Button>

          {/* <Button
            style={{
              marginLeft: 10,
              backgroundColor: 'mediumaquamarine',
              borderColor: 'mediumaquamarine',
            }}
            role="button"
            onClick={async () => {
              try {
                const { data } = await sync();
                const total = get(data, 'syncMirrorWithSila', 0);
                message.success(`Transaction items sync with Sila (${total})`);
              } catch (error) {
                message.error(error.message);
              }
            }}
            type="primary"
            loading={syndData.loading}
            danger
          >
            Sync Transactions
          </Button> */}
        </div>
      </div>
      <div className="creating creating-v">
        <div className="create-b b-n m-c f-15">
          <h1 style={{ fontWeight: 'bolder', color: '#7f8c8d' }}>
            Constitution Balance
          </h1>

          <NumberFormat
            displayType="text"
            value={get(defaultAccountOne, 'availableFund', 0).toFixed(2)}
            thousandSeparator={true}
            prefix={'$'}
            renderText={(value) => (
              <h1
                style={{
                  fontWeight: 'bolder',
                  color: '#34495e',
                  fontSize: 34,
                  fontStyle: 'italic',
                }}
              >
                {value}
              </h1>
            )}
          />

          <H3>Entity</H3>
          {hasEntityUserAccount ? (
            <Entities entityAccounts={entityAccounts} refetch={refetch} />
          ) : (
            <RegisterEntityForm
              refetch={refetch}
              closeAc={() => {}}
              entityAccounts={entityAccounts}
            />
          )}
        </div>

        <hr />
        <div className="create-b b-n m-c f-15">
          <H3 style={{ color: '#e74c3c' }}>Pending Rewards</H3>

          {isEmpty(pendingRewards) && (
            <pre>
              <samp>
                <h1>No pending rewards.</h1>
              </samp>
            </pre>
          )}

          {!isEmpty(defaultAccountOne) && !isEmpty(pendingRewards) && (
            <PendingRewards
              refetch={refetch}
              adminUserAccountId={defaultAccountOne._id}
              rewards={pendingRewards}
            />
          )}
        </div>

        <hr />

        <div className="create-b b-n m-c f-14">
          <H3>Individual</H3>
          {hasIndividualUserAccount ? (
            <IndividualInfo
              firstName={firstName}
              lastName={lastName}
              individualAccount={individualAccount}
              refetch={refetch}
              usePlaidLink={usePlaidLink}
            />
          ) : (
            <RegisterIndividualForm refetch={refetch} />
          )}
        </div>
      </div>
    </div>
  );
}

RegisterToSila.defaultProps = defaultProps;
RegisterToSila.propTypes = propTypes;
