import React, { useState } from 'react';
import { Layout, message, Checkbox, Button } from 'antd';
import { CSVLink } from 'react-csv';
import { Table } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import isEmpty from 'lodash/isEmpty';

import CSVUploader from '../components/CSVUploader';
import { CREATE_SESSION_BULK } from './apollo_mutations';

const csvData = [['phoneNumber', 'address', 'email', 'personCategory', 'job', 'text', 'subject', 'media', 'name']];
const columns = csvData[0].map((item) => ({
  title: item,
  dataIndex: item,
  key: item,
  render: (text) =>
    typeof text === 'object' ? (
      text ? (
        <img src={text.downloadLink} style={{ width: 25, height: 25 }} alt="constitution" />
      ) : null
    ) : (
      text
    ),
}));
const { Content } = Layout;

function SendFromCsv() {
  const [uploadItems, setUploadItems] = useState([]);
  const [toSendSms, setToSendSms] = useState(false);
  const [toSendEmail, setToSendEmail] = useState(false);

  const [mutate] = useMutation(CREATE_SESSION_BULK);

  const handleSubmit = async () => {
    try {
      if (isEmpty(uploadItems)) {
        message.info('CSV should contain items');
        return null;
      }

      const itemsLen = uploadItems.length;
      const nameLen = uploadItems.filter((item) => !!item.name).length;
      const catLen = uploadItems.filter((item) => !!item.personCategory).length;

      if (itemsLen !== nameLen) {
        message.info('Name is a required field');
        return null;
      }

      if (itemsLen !== catLen) {
        message.info('Person category is a required field');
        return null;
      }

      const { data } = await mutate({
        variables: {
          data: {
            items: uploadItems,
            toSendSms,
            toSendEmail,
          },
        },
      });

      if (data) {
        message.success(`Successfully saved ${uploadItems.length} sessions`);
      }
    } catch (error) {
      message.error('Please try again');
    }
  };

  return (
    <Layout className="layout">
      <Content style={{ padding: 10 }}>
        <CSVLink data={csvData}>
          <DownloadOutlined /> Download accepted CSV format here
        </CSVLink>
        <CSVUploader
          onDrop={(result) => {
            setUploadItems(result);
          }}
        />
        <Checkbox checked={toSendEmail} onClick={() => setToSendEmail(!toSendEmail)}>
          Send Email
        </Checkbox>
        <Checkbox checked={toSendSms} onClick={() => setToSendSms(!toSendSms)}>
          Send SMS
        </Checkbox>
        <Table rowKey="email" dataSource={uploadItems} columns={columns} />
        <Button type="primary" onClick={handleSubmit} style={{ marginTop: 15, marginRight: 10 }}>
          Submit
        </Button>
        <Button type="primary" danger onClick={() => setUploadItems([])} style={{ marginTop: 15 }}>
          Clear
        </Button>
      </Content>
    </Layout>
  );
}

export default SendFromCsv;
