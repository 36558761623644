import { Alert, Button, Form, Input, message, Tag } from 'antd';
import React, { Fragment } from 'react';
import { useMutation } from '@apollo/react-hooks';
import {
  RESOLVE_KYC_FAILURE,
  SEND_DOC_TO_SILA,
} from '../pages/apollo_mutations';
import { isEmpty } from 'lodash';

const { Item } = Form;

export function KycFailSendEmailForm({
  _id = '',
  refetch = () => Promise.resolve({}),
  resolved = null,
  documents = [],
}) {
  const [form] = Form.useForm();
  const [mutate, { loading }] = useMutation(SEND_DOC_TO_SILA);
  const [resolve, resolveData] = useMutation(RESOLVE_KYC_FAILURE);
  return (
    <Form
      form={form}
      name={_id}
      layout="vertical"
      scrollToFirstError
      initialValues={{
        from: 'invest@constlending.com',
      }}
      onFinish={async (values) => {
        try {
          await mutate({ variables: { ...values, failureId: _id } });
          await refetch();
          message.success('Email sent');
        } catch (error) {
          message.error(error.message);
        }
      }}
    >
      {resolved ? (
        <Tag color="green">Resolved</Tag>
      ) : (
        <Button
          type="primary"
          danger
          loading={resolveData.loading}
          htmlType="button"
          onClick={async () => {
            try {
              await resolve({ variables: { failureId: _id } });
              await refetch();
              message.success('KYC failure resolved');
            } catch (error) {
              message.error(error.message);
            }
          }}
        >
          Resolve KYC Failure
        </Button>
      )}
      {!isEmpty(documents) && (
        <Fragment>
          <div style={{ height: 20 }}></div>
          <div>Documents</div>

          {documents.map((item) => (
            <div style={{ marginBottom: 10 }}>
              <a
                key={item.id}
                href={item.url}
                rel="noreferrer noopener"
                target="_blank"
              >
                <Alert message={item.filename} />
              </a>
            </div>
          ))}
        </Fragment>
      )}

      <div style={{ height: 20 }}></div>
      <Item
        name="to"
        label="Recepient"
        rules={[
          {
            type: 'email',
            message: 'Not valid email',
          },
          {
            required: true,
            message: 'Required',
          },
        ]}
      >
        <Input />
      </Item>
      <Item
        name="from"
        label="From"
        rules={[
          {
            type: 'email',
            message: 'Not valid email',
          },
          {
            required: true,
            message: 'Required',
          },
        ]}
      >
        <Input />
      </Item>
      <Item
        name="signature"
        label="Signature"
        rules={[
          {
            required: true,
            message: 'Required',
          },
        ]}
      >
        <Input />
      </Item>
      <Item>
        <Button
          disabled={resolved}
          loading={loading}
          type="primary"
          htmlType="submit"
        >
          Send
        </Button>
      </Item>
    </Form>
  );
}
