export const tagTypes = {
  property: {
    name: 'Property Type',
    types: [
      'Single Family',
      '2-4 Unit',
      '5+ Unit Multifamily',
      'Industrial',
      'Mixed Use',
      'Office',
      'Special Purpose',
      'Retail',
      'Portfolio',
    ],
  },
  loan: {
    name: 'Loan Type',
    types: ['Performing', 'Non-performing'],
  },
  items: [
    { value: 'Property Type', label: 'Property Type' },
    { value: 'Loan Type', label: 'Loan Type' },
  ],
};
