import { Button, Table, Space, message } from 'antd';
import React from 'react';
import { useMutation } from 'react-apollo';
import NumberFormat from 'react-number-format';
import { TableMainLabel } from '../components/styled';
import { ISSUE_REWARD } from '../pages/apollo_mutations';

export function PendingRewards({
  rewards = [],
  adminUserAccountId = '',
  refetch = () => Promise.resolve({}),
}) {
  const [issue, issueData] = useMutation(ISSUE_REWARD);

  const columns = [
    {
      title: 'Account name',
      dataIndex: 'accountName',
      render: (t) => <TableMainLabel>{t}</TableMainLabel>,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (t) => (
        <TableMainLabel>
          <NumberFormat
            displayType="text"
            value={t}
            thousandSeparator={true}
            prefix={'$'}
            renderText={(value) => <div>{value}</div>}
          />
        </TableMainLabel>
      ),
    },
    {
      title: 'Action',
      dataIndex: '_id',
      render: (id, item) => (
        <Space size="middle">
          <Button
            danger
            onClick={async () => {
              try {
                const data = await issue({
                  variables: {
                    reservedTransactionId: id,
                    amount: item.amount,
                    adminUserAccountId: adminUserAccountId,
                    userAccountId: item.userAccountId,
                  },
                });

                if (data) {
                  message.success('Success');
                  await refetch();
                }
              } catch (error) {
                message.error(error.message);
              }
            }}
          >
            Reward
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ background: 'white' }}>
      <Table
        columns={columns}
        loading={issueData.loading}
        dataSource={rewards}
        rowKey={'_id'}
        size="middle"
        pagination={{
          pageSize: 50,
        }}
      />
    </div>
  );
}
