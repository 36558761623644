import { Button, Descriptions, message, Table } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useMutation, useSubscription } from 'react-apollo';
import { CHECK_KYC } from '../pages/apollo_mutations';
import {
  LINK_ACCOUNT_SUB,
  UPDATE_KYC_USER,
} from '../pages/apollo_subscriptions';

const { Item } = Descriptions;

export const columns = [
  {
    title: 'Account Link Status',
    dataIndex: 'account_link_status',
  },
  {
    title: 'Account Name',
    dataIndex: 'bankAccountNameLinked',
  },
  {
    title: 'Account Number',
    dataIndex: 'account_number',
  },
  {
    title: 'Account Status',
    dataIndex: 'account_status',
  },
  {
    title: 'Account Type',
    dataIndex: 'account_type',
  },
];

export function IndividualInfo({
  firstName = '',
  lastName = '',
  refetch = () => Promise.resolve({}),
  individualAccount = {},
}) {
  const { kyc, bankAccounts = [] } = individualAccount;
  const [checkKyc, checkKycData] = useMutation(CHECK_KYC);

  return (
    <div>
      <Descriptions size="small" title="Summary" bordered>
        <Item label="Name">{`${firstName} ${lastName}`}</Item>
        <Item label="KYC Status">{kyc}</Item>
      </Descriptions>

      {kyc.toLowerCase() !== 'passed' && (
        <Button
          disabled={checkKycData.loading}
          loading={checkKycData.loading}
          type="primary"
          htmlType="button"
          onClick={async () => {
            try {
              await checkKyc({
                variables: {
                  userAccountId: individualAccount && individualAccount._id,
                },
              });
              await refetch();
              message.success('Request success');
            } catch (error) {
              message.error(
                error.message
                  ? error.message.replace('GraphQL error:', '')
                  : 'Please try again',
                8
              );
            }
          }}
          style={{ marginTop: 15, marginBottom: 15 }}
        >
          Check KYC
        </Button>
      )}

      <div style={{ height: 20 }} />

      {!isEmpty(bankAccounts) ? (
        <Table
          dataSource={bankAccounts}
          columns={columns}
          rowKey="account_name"
          size="small"
        ></Table>
      ) : (
        <div>No bank account linked</div>
      )}
    </div>
  );
}
