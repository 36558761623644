import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';
import { Table, Input, Badge, Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { GET_SESSIONS, SESSION_COUNT } from './apollo_queries';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
    showSorterTooltip: false,
    render: (text, { initialMessageReplied }) => (
      <div>
        <Badge status={initialMessageReplied ? 'success' : 'error'} />
        {text}
      </div>
    ),
  },
  {
    title: 'Phone',
    dataIndex: 'phoneNumber',
    sorter: true,
    showSorterTooltip: false,
    responsive: ['md'],
  },
  {
    title: 'Updated',
    dataIndex: 'updatedAt',
    sorter: true,
    showSorterTooltip: false,
    render: (t) => moment(parseInt(t)).fromNow(),
    responsive: ['lg'],
  },
  {
    title: 'Action',
    render: (_, item) => (
      <Space size="middle">
        <Link style={{ color: '#1abc9c' }} to={`/session/${item._id}/update`}>
          Update
        </Link>
        <Link style={{ color: '#3498db' }} to={`/session/${item._id}`}>
          Open
        </Link>
      </Space>
    ),
  },
];

const { Search } = Input;

function Home({ personCategory = 'Employee' }) {
  // initial load hooks
  const totalSession = useQuery(SESSION_COUNT, { variables: { personCategory } });
  const total = get(totalSession, 'data.sessionCount.count', 10);
  const history = useHistory();
  const location = useLocation();

  // local state
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState('updatedAt');
  const [sortDirection, setSortDirection] = useState('DESC');

  const { p } = qs.parse(location.search);

  useEffect(() => {
    if (p) {
      setPage(parseInt(p));
    }
  }, [p]);

  // updating hooks
  const { data, loading, refetch } = useQuery(GET_SESSIONS, {
    variables: { count: 10, q: query, page, sortBy, sortDirection, personCategory },
  });
  const sessions = get(data, 'getSessions', []);

  const onChange = (pagination, _, sorter) => {
    if (pagination.current !== page) {
      setPage(pagination.current);
      history.push(`?p=${pagination.current}`);
    }

    if (sorter) {
      if (sorter.field !== sortBy) {
        setSortBy(sorter.field);
      }

      if (sorter.order) {
        setSortDirection(sorter.order === 'ascend' ? 'ASC' : 'DESC');
      }
    }
  };

  return (
    <Fragment>
      <br />
      <Search
        placeholder="Search for name, phone, email, property ID, city or state"
        enterButton="Search"
        size="large"
        onSearch={() => refetch()}
        onChange={(e) => setQuery(e.target.value)}
        value={query}
      />
      <br />
      <br />
      {(loading || totalSession.loading) && (
        <div className="web-load-wrap">
          <LoadingOutlined />
        </div>
      )}
      <Table
        rowKey={'_id'}
        columns={columns}
        dataSource={sessions}
        onChange={onChange}
        pagination={{
          total,
          pageSize: 10,
          position: ['bottomCenter'],
          current: page,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          hideOnSinglePage: true,
          pageSizeOptions: ['10'],
        }}
        size={10}
        loading={loading || totalSession.loading}
      />
    </Fragment>
  );
}

export default Home;
