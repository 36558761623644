import React from 'react';
import { Button, Input, Form, DatePicker, message, Select } from 'antd';
import { omit } from 'lodash';
import moment from 'moment';
import { useMutation } from 'react-apollo';
import { CREATE_INDIVIDUAL } from '../pages/apollo_mutations';
import { US_STATES } from '../data/geo';

const FormItem = Form.Item;
const { Option } = Select;

export function RegisterIndividualForm({
  refetch = () => Promise.resolve({}),
}) {
  const [createIndividual, createIndividualData] = useMutation(
    CREATE_INDIVIDUAL
  );
  const [createIndividualForm] = Form.useForm();

  return (
    <Form
      form={createIndividualForm}
      initialValues={{}}
      onFinish={async (values) => {
        const { dateOfBirth } = values;
        if (!dateOfBirth) {
          message.error('Date of birth is required');
          return null;
        }
        const data = omit(values, ['dateOfBirth']);

        data['dateOfBirth'] = moment(dateOfBirth).format();

        try {
          await createIndividual({ variables: data });
          await refetch();
          message.success('Success');
        } catch (error) {
          message.error(
            error.message
              ? error.message.replace('GraphQL error:', '')
              : 'Please try again',
            8
          );
        }
      }}
      name="register-individual"
    >
      <div className="create-b b-n m-c">
        <FormItem name="firstName" label="First name" required>
          <Input name="firstName" placeholder="FIRST NAME" required />
        </FormItem>

        <FormItem name="lastName" label="Last name" required>
          <Input name="lastName" placeholder="LAST NAME" required />
        </FormItem>

        <FormItem name="email" label="Email" required>
          <Input name="email" placeholder="EMAIL" type="email" required />
        </FormItem>

        <FormItem name="dateOfBirth" label="Date of birth" required>
          <DatePicker placeholder="DATE OF BIRTH" name="dateOfBirth" />
        </FormItem>

        <FormItem name="phone" label="Phone" required>
          <Input
            name="phone"
            placeholder="2036658995 (CORRECT FORMAT)"
            type="tel"
            required
          />
        </FormItem>

        <FormItem name="address" label="Address" required>
          <Input name="address" placeholder="ADDRESS" required />
        </FormItem>

        <FormItem name="state" label="State" required>
          <Select
            showSearch
            filterOption={(iv, option) => {
              return (
                option &&
                typeof option.children === 'string' &&
                option.children.toLowerCase().includes(iv)
              );
            }}
          >
            {US_STATES.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem name="city" label="City" required>
          <Input name="city" placeholder="CITY" required />
        </FormItem>

        <FormItem name="zip" label="ZIP" required>
          <Input name="zip" placeholder="ZIP" required />
        </FormItem>

        <FormItem name="ssn" label="SSN" required>
          <Input name="ssn" placeholder="111122333" required />
        </FormItem>

        <FormItem>
          <Button
            type="dashed"
            htmlType="button"
            style={{ marginRight: 8 }}
            onClick={() => {
              createIndividualForm.resetFields();
            }}
          >
            Reset
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: 8 }}
            disabled={createIndividualData.loading}
            loading={createIndividualData.loading}
          >
            Save
          </Button>
        </FormItem>
      </div>
    </Form>
  );
}
