import React, { Component } from 'react';

import Message from './Message';

class Emails extends Component {
  render() {
    const { messages = [], isSms } = this.props;

    return (
      <div className="m-flex-m">
        {messages.map((item, index) => (
          <Message
            isSms={isSms}
            currentMessage={item}
            nextMessage={index === messages.length - 1 ? null : messages[index + 1]}
            key={item._id}
          />
        ))}
      </div>
    );
  }
}

export default Emails;
