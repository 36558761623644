import React, { useEffect, useRef, useState } from 'react';
import {
  SendOutlined,
  LoadingOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Button, message, Switch, Badge } from 'antd';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { GET_MESSAGES, GET_SESSION, GET_EMAILS } from './apollo_queries';
import { NEW_MESSAGE } from './apollo_subscriptions';
import { UPDATE_UNREAD_TO_ZERO, UPLOAD_FILE } from './apollo_mutations';
import Messages from '../components/Messages';
import Emails from '../components/Emails';
import moment from 'moment';

function ChatApp() {
  const scrollRef = useRef(null);
  const inputRef = useRef(null);
  const history = useHistory();
  const match = useRouteMatch();
  const sessionId = get(match, 'params.id', '');
  const [text, setText] = useState('');
  const [file, selectFile] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [sms, showSms] = useState(true);
  const count = 10;
  const offset = 0;
  const [updateUnread] = useMutation(UPDATE_UNREAD_TO_ZERO);

  useEffect(() => {
    function redirect() {
      if (!sessionId) {
        history.push('/');
      }
    }

    redirect();
  }, [history, sessionId]);

  useEffect(() => {
    async function u() {
      await updateUnread({ variables: { sessionId } });
    }

    u();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function ur() {
      await updateUnread({ variables: { sessionId } });
    }

    // eslint-disable-next-line
    return async () => {
      await ur();
    };
    // eslint-disable-next-line
  }, []);

  const sessionData = useQuery(GET_SESSION, {
    variables: { id: sessionId, mode: sms ? 'message' : 'email' },
  });
  const { data, fetchMore, loading, subscribeToMore } = useQuery(
    sms ? GET_MESSAGES : GET_EMAILS,
    {
      variables: {
        data: {
          offset,
          sessionId,
          count,
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const [mutate] = useMutation(UPLOAD_FILE, {
    optimisticResponse: {
      __typename: 'Mutation',
      uploadFile: {
        createdAt: moment().format(),
        media: null,
        owner: 'Constitution Management',
        sessionId,
        text,
        error: null,
        updatedAt: moment().format(),
        __typename: 'Message',
        _id:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15),
      },
    },
    update(proxy, { data: { uploadFile } }) {
      const dataFromCache = proxy.readQuery({
        query: GET_MESSAGES,
        variables: {
          data: {
            offset,
            count,
            sessionId,
          },
        },
      });

      setText('');

      const newData = {
        ...dataFromCache,
        getMessages: [uploadFile, ...dataFromCache.getMessages],
      };

      proxy.writeQuery({
        query: GET_MESSAGES,
        variables: {
          data: {
            offset,
            count,
            sessionId,
          },
        },
        data: newData,
      });
    },
  });

  let messages = get(data, `${sms ? 'getMessages' : 'getEmails'}`, []);
  const session = get(sessionData, 'data.getSession', null);

  useEffect(() => {
    function makeScroll() {
      if (
        !isLoadMore &&
        messages.length > 0 &&
        scrollRef &&
        scrollRef.current
      ) {
        return scrollRef.current.scrollTo({
          top: 99999,
          left: 0,
          behavior: 'auto',
        });
      }
    }

    makeScroll();
  }, [isLoadMore, messages.length]);

  const handleSubmit = async () => {
    try {
      if (text || file) {
        await mutate({
          variables: {
            file,
            sessionId,
            text,
            owner: 'Constitution Management',
          },
        });

        setIsLoadMore(false);
        setText('');

        selectFile(null);
        setImagePreview('');

        if (inputRef && inputRef.current) {
          inputRef.current.focus();
        }

        scrollRef.current.scrollTo({ top: 99999, left: 0, behavior: 'auto' });
      }
    } catch (error) {
      message.error('Error occurred, please try again');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      return handleSubmit();
    }
  };

  const total = get(sessionData, 'data.getSession.total', 0);
  const personCategory = get(sessionData, 'data.getSession.personCategory', 0);
  const isProspectiveTenant = personCategory === 'Prospective Tenant';

  return (
    <div className="web-chat__main-content">
      <Helmet>
        <title>Constitution Management</title>
      </Helmet>
      <div className="tr__header">
        <div className="top_header">
          <div className="sub-text header-title">{session && session.name}</div>
          {isProspectiveTenant && (
            <div className="sub-text header-info">
              {session &&
                session.propertyId &&
                `Property no.: ${session.propertyId}`}
            </div>
          )}
          {sms && session && session.phoneNumber && (
            <div className="sub-text header-title">
              Phone: {session.phoneNumber}
            </div>
          )}
          {!sms && session && session.email && (
            <div className="sub-text header-title">Email: {session.email}</div>
          )}
        </div>
        <div className="tr__b">
          <Switch
            checkedChildren="SMS/MMS"
            unCheckedChildren="Email"
            defaultChecked
            checked={sms}
            onChange={(mode) => showSms(mode)}
            loading={loading}
          />
          <Button
            style={{ marginLeft: 10 }}
            role="button"
            onClick={() => history.goBack()}
            type="primary"
            danger
          >
            Back
          </Button>
        </div>
      </div>
      <div className="messages">
        <div className="messages-history" ref={scrollRef}>
          {(loading || sessionData.loading) && (
            <div className="web-load-wrap">
              <LoadingOutlined />
            </div>
          )}
          {messages.length < total && (
            <div className="section__date c-flex ntop">
              <Button
                className="normal-text nt-sm"
                role="button"
                onClick={() => {
                  setIsLoadMore(true);

                  return fetchMore({
                    variables: {
                      data: {
                        offset: messages.length,
                        count,
                        sessionId,
                      },
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      return Object.assign({}, prev, {
                        getMessages: [
                          ...prev.getMessages,
                          ...fetchMoreResult.getMessages,
                        ],
                      });
                    },
                  });
                }}
              >
                {loading ? <LoadingOutlined /> : 'Load earlier messages'}
              </Button>
            </div>
          )}

          {sms ? (
            <Messages
              messages={messages}
              onNewMessage={() =>
                scrollRef.current.scrollTo({
                  top: 99999,
                  left: 0,
                  behavior: 'auto',
                })
              }
              subscribeToNewMessages={() =>
                subscribeToMore({
                  document: NEW_MESSAGE,
                  variables: { sessionId },
                  updateQuery: (prev, { subscriptionData }) => {
                    console.log({ subscriptionData });
                    if (!subscriptionData.data) return prev;

                    const newMessage = subscriptionData.data.newMessage;

                    return Object.assign({}, prev, {
                      getMessages: [newMessage, ...prev.getMessages],
                    });
                  },
                })
              }
            />
          ) : (
            <Emails isSms={sms} messages={messages} />
          )}
        </div>
        {sms && (
          <div className="tr__footer">
            <div className="section__inp">
              <button className="send-btn" onClick={handleSubmit}>
                {loading ? <LoadingOutlined /> : <SendOutlined />}
              </button>
              <div>
                <label htmlFor="upload-button" style={{ cursor: 'pointer' }}>
                  <PictureOutlined
                    style={{ fontSize: 30, marginRight: 10, color: '#95a5a6' }}
                  />
                </label>
                <input
                  type="file"
                  id="upload-button"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    e.preventDefault();
                    const reader = new FileReader();
                    const myFile = e.target.files[0];

                    reader.onloadend = () => {
                      selectFile(myFile);
                      setImagePreview(reader.result);
                    };

                    reader.readAsDataURL(myFile);
                  }}
                />
                {imagePreview ? (
                  <span
                    role="button"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      selectFile(null);
                      setImagePreview('');
                    }}
                  >
                    <Badge count="x" offset={[-10, 0]}>
                      <img
                        alt="constitution"
                        src={imagePreview}
                        style={{ height: 30, width: 30, marginRight: 10 }}
                      />
                    </Badge>
                  </span>
                ) : null}
              </div>
              <textarea
                placeholder="Type a message..."
                name="chatInput"
                id="chatInput"
                value={text}
                onChange={(e) => setText(e.target.value)}
                rows="1"
                className="chat-inp"
                ref={inputRef}
                onKeyPress={handleKeyPress}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatApp;
