import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Upload } from 'antd';
import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_INVESTMENT_DOC } from '../pages/apollo_mutations';
import { isEmpty } from 'lodash';

const FormItem = Form.Item;

export function DocumentUploaderForm({
  investmentId = '',
  refetch = () => Promise.resolve({}),
}) {
  const [form] = Form.useForm();
  const [upload, { loading }] = useMutation(CREATE_INVESTMENT_DOC);

  return (
    <Form
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 20 }}
      onFinish={async (values) => {
        const { name, document } = values;
        const data = { name, investmentId };

        if (!isEmpty(document)) {
          data['document'] = document[0]['originFileObj'];
        } else {
          return message.error('Document is required');
        }

        try {
          const resp = await upload({ variables: data });

          if (resp && !isEmpty(resp.data)) {
            await refetch();

            form.resetFields();

            return message.success('Success');
          } else {
            return message.error('Please try again');
          }
        } catch (error) {
          return message.error(error.message);
        }
      }}
    >
      <FormItem name="name" label="Name" required>
        <Input name="name" placeholder="Name" required />
      </FormItem>
      <FormItem
        name="document"
        required
        label="Document"
        valuePropName="fileList"
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }

          return e && e.fileList;
        }}
      >
        <Upload
          multiple={false}
          name="image"
          listType="picture"
          beforeUpload={() => false}
        >
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      </FormItem>
      <FormItem
        wrapperCol={{
          sm: {
            span: 16,
            offset: 6,
          },
        }}
      >
        <Button
          type="default"
          htmlType="submit"
          danger
          style={{ marginRight: 8 }}
          disabled={loading}
          loading={loading}
        >
          Save document
        </Button>
      </FormItem>
    </Form>
  );
}
