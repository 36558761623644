import { Alert, Button, Form, Input, message, Space, Table } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { TableMainLabel } from '../components/styled';
import { BORROWERS } from './apollo_queries';
import { isEmpty, get } from 'lodash';
import { CREATE_BORROWER, RESEND_BORROWER_LINK } from './apollo_mutations';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
} from '@ant-design/icons';

function ResendLink({ userId }) {
  const [resend, resendData] = useMutation(RESEND_BORROWER_LINK);
  return (
    <Button
      style={{
        marginLeft: 15,
        backgroundColor: 'mediumaquamarine',
        borderColor: 'mediumaquamarine',
      }}
      loading={resendData.loading}
      disabled={resendData.loading}
      role="button"
      onClick={async () => {
        try {
          await resend({ variables: { userId } });
          message.success('Sent');
        } catch (error) {
          message.error(error.message);
        }
      }}
      type="primary"
    >
      Resend link
    </Button>
  );
}

export function Borrowers() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(50);
  const [sortBy, setSortBy] = useState('updatedAt');
  const [sortDirection, setSortDirection] = useState('DESC');
  const { data, loading, error, refetch } = useQuery(BORROWERS, {
    variables: {
      count: pageCount,
      q: '',
      page,
      sortBy,
      sortDirection,
    },
  });
  const [createBorrower, createBorrowerData] = useMutation(CREATE_BORROWER);
  const [form] = Form.useForm();

  const columns = [
    {
      title: 'KYC',
      dataIndex: 'hasFailedKyc',
      render: (hasFailedKyc, { hasNotYetRequestKyc }) => (
        <div>
          {hasFailedKyc ? (
            <CloseCircleTwoTone size="large" twoToneColor="#e74c3c" />
          ) : hasNotYetRequestKyc ? (
            <ExclamationCircleTwoTone size="large" twoToneColor="#8e44ad" />
          ) : (
            <CheckCircleTwoTone size="large" twoToneColor="#1abc9c" />
          )}
        </div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'firstName',
      sorter: true,
      showSorterTooltip: false,
      render: (firstName, { lastName }) => (
        <TableMainLabel>{`${firstName} ${lastName}`}</TableMainLabel>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      showSorterTooltip: false,
      responsive: ['md'],
      render: (firstName) => <TableMainLabel>{firstName}</TableMainLabel>,
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      sorter: true,
      showSorterTooltip: false,
      render: (t) => <TableMainLabel>{moment(t).fromNow()}</TableMainLabel>,
      responsive: ['lg'],
    },
    {
      title: 'Action',
      dataIndex: '_id',
      render: (userId) => (
        <Space size="middle">
          <Link style={{ color: '#3498db' }} to={`/users/${userId}`}>
            Open
          </Link>
          <ResendLink userId={userId} />
        </Space>
      ),
    },
  ];

  const items = get(data, 'borrowers', []);

  const onChange = (pagination, _, sorter) => {
    if (pagination.current !== page) {
      setPage(pagination.current);
      history.push(`?p=${pagination.current}`);
    }

    if (sorter) {
      if (sorter.field !== sortBy) {
        setSortBy(sorter.field);
      }

      if (sorter.order) {
        setSortDirection(sorter.order === 'ascend' ? 'ASC' : 'DESC');
      }
    }

    if (pageCount !== pagination.pageSize) {
      setPageCount(pagination.pageSize);
    }
  };

  return (
    <div style={{ paddingBottom: 30 }} className="web-chat__main-content">
      <div className="tr__header">
        <div className="top_header">
          <div style={{ fontWeight: 'bolder' }} className="sub-text">
            Borrowers
          </div>
        </div>

        <div className="tr__b">
          <Button
            style={{ marginLeft: 15 }}
            role="button"
            onClick={() => refetch()}
            type="primary"
          >
            Refresh
          </Button>
          <Button
            style={{ marginLeft: 15 }}
            role="button"
            onClick={() => history.goBack()}
            type="primary"
            danger
          >
            Back
          </Button>
        </div>
      </div>
      <div className="creating">
        <div className="create-b b-n m-c">
          <div style={{ background: 'white' }}>
            {error && <Alert message={error.message} type="error" />}

            <div style={{ height: 10 }}></div>

            <h4
              style={{ fontWeight: 'bolder', opacity: 0.6, marginBottom: 20 }}
            >
              Register a borrower
            </h4>

            <Form
              form={form}
              name="customized_form_controls"
              layout="inline"
              onFinish={async (values) => {
                try {
                  await createBorrower({ variables: { ...values } });
                  message.success('Done');
                  await refetch();

                  if (!isEmpty(form)) {
                    form.resetFields();
                  }
                } catch (error) {
                  message.error(error.message);
                }
              }}
            >
              <Form.Item name="firstName" label="First name" required>
                <Input name="firstName" type="text" required />
              </Form.Item>
              <Form.Item name="lastName" label="Last name" required>
                <Input name="lastName" type="text" required />
              </Form.Item>
              <Form.Item name="email" label="Email" required>
                <Input name="email" type="email" required />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={createBorrowerData.loading}
                  disabled={createBorrowerData.loading}
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
            <small style={{ opacity: 0.6 }}>
              Submitting this form will send borrower a magic link to start KYC
              registration
            </small>

            <div style={{ height: 40 }}></div>

            <Table
              loading={loading}
              columns={columns}
              dataSource={items}
              onChange={onChange}
              rowKey={'_id'}
              size="small"
              pagination={{
                pageSize: pageCount,
                pageSizeOptions: ['10', '20', '30', '50'],
              }}
            />
            <div style={{ marginTop: 10 }}></div>
            <CloseCircleTwoTone twoToneColor="#e74c3c" />
            <small style={{ marginLeft: 8, marginRight: 20 }}>
              Has failed KYC verification(s)
            </small>
            <ExclamationCircleTwoTone twoToneColor="#8e44ad" />
            <small style={{ marginLeft: 8 }}>No KYC requests made yet</small>
          </div>
        </div>
      </div>
    </div>
  );
}
