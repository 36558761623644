import { LoadingOutlined } from '@ant-design/icons';
import { Button, Upload, Alert, message } from 'antd';
import React, { Fragment, useState } from 'react';
import { useMutation } from 'react-apollo';
import { UPDATE_PPM, UPDATE_SUBSCRIPTION_AGREEMENT } from './apollo_mutations';

export function Documents({
  ppm = '',
  subscriptionAgreement = '',
  refetch = () => Promise.resolve(null),
  constLoading = false,
}) {
  const [ppmDoc, setPpm] = useState(null);
  const [sa, setSa] = useState(null);
  const [updatePpm, ppmData] = useMutation(UPDATE_PPM);
  const [updateSa, saData] = useMutation(UPDATE_SUBSCRIPTION_AGREEMENT);

  if (constLoading) {
    return (
      <div className="web-load-wrap">
        <LoadingOutlined />
      </div>
    );
  }

  return (
    <div className="web-chat__main-content wc__solo">
      <div className="tr__header">
        <div className="top_header">
          <div style={{ fontWeight: 'bolder' }} className="sub-text">
            Documents
          </div>
        </div>
      </div>
      <div className="creating creating-v" style={{ padding: 20 }}>
        <Alert
          message={
            <div>
              <h3>PPM</h3>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Upload
                  fileList={ppmDoc}
                  multiple={false}
                  onChange={async (info) => {
                    setPpm(info.fileList);
                    try {
                      const pdata = await updatePpm({
                        variables: { ppm: info.file },
                      });

                      if (pdata.data) {
                        message.success('Success');
                      }

                      await refetch();
                    } catch (error) {
                      message.error(error.message, 5);
                    }
                  }}
                  beforeUpload={() => false}
                  listType="text"
                  disabled={ppmData.loading}
                >
                  <Button
                    htmlType="button"
                    loading={ppmData.loading}
                    disabled={ppmData.loading}
                  >
                    Upload
                  </Button>
                </Upload>
                <div style={{ width: 20 }}></div>
                {ppm ? (
                  <a target="_blank" rel="noreferrer noopener" href={ppm}>
                    Open document
                  </a>
                ) : (
                  <div>No document uploaded yet</div>
                )}
              </div>
              {ppm && (
                <Fragment>
                  <div style={{ height: 10 }} />
                  <pre style={{ margin: 0, padding: 0 }}>
                    <samp>{ppm}</samp>
                  </pre>
                </Fragment>
              )}
            </div>
          }
        />

        <div style={{ height: 20 }}></div>

        <Alert
          type="success"
          message={
            <div>
              <h3>Subscription Agreement</h3>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Upload
                  fileList={sa}
                  multiple={false}
                  disabled={saData.loading}
                  onChange={async (info) => {
                    setSa(info.fileList);
                    try {
                      const sadata = await updateSa({
                        variables: { subscriptionAgreement: info.file },
                      });

                      if (sadata.data) {
                        message.success('Success');
                      }

                      await refetch();
                    } catch (error) {
                      message.error(error.message, 5);
                    }
                  }}
                  beforeUpload={() => false}
                  listType="text"
                >
                  <Button
                    htmlType="button"
                    loading={saData.loading}
                    disabled={saData.loading}
                  >
                    Upload
                  </Button>
                </Upload>
                <div style={{ width: 20 }}></div>
                {subscriptionAgreement ? (
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={subscriptionAgreement}
                  >
                    Open document
                  </a>
                ) : (
                  <div>No document uploaded yet</div>
                )}
              </div>
              {subscriptionAgreement && (
                <Fragment>
                  <div style={{ height: 10 }} />
                  <pre>
                    <samp>{subscriptionAgreement}</samp>
                  </pre>
                </Fragment>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
}
