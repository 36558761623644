import { Badge } from 'antd';
import React from 'react';
import { TableMainLabel } from './styled';

export function UserStatus(props) {
  const { initialMessageReplied, name, unreadMessages } = props;

  return (
    <div>
      <Badge status={initialMessageReplied ? 'success' : 'error'} />
      <Badge offset={[14, 0]} count={unreadMessages}>
        <TableMainLabel>{name}</TableMainLabel>
      </Badge>
    </div>
  );
}
