import gql from 'graphql-tag';

export const NEW_MESSAGE = gql`
  subscription newMessage($sessionId: String!) {
    newMessage(sessionId: $sessionId) {
      _id
      sessionId
      owner
      text
      media {
        downloadLink
        previewLink
        type
      }
      error
      createdAt
      updatedAt
    }
  }
`;

export const LINK_ACCOUNT_SUB = gql`
  subscription lastLinkAccountStatus($id: String!) {
    lastLinkAccountStatus(id: $id) {
      id
      message
    }
  }
`;

export const UPDATE_KYC_USER = gql`
  subscription updateKycUser($id: String!) {
    updateKycUser(id: $id) {
      _id
    }
  }
`;

export const LAST_TRANSACTION = gql`
  subscription lastTransactionStatus($id: String!) {
    lastTransactionStatus(id: $id) {
      id
      message
    }
  }
`;

export const SESSION_UPDATE_SUB = gql`
  subscription sessionUpdateSub($id: String!) {
    sessionUpdateSub(id: $id) {
      _id
      initialMessageReplied
      phoneNumber
      propertyId
      email
      name
      state
      city
      address
      showingAt
      confirmed
      leadInterested
      sectionEight
      createdAt
      updatedAt
      personCategory
      comment
      job
      unreadMessages
    }
  }
`;
