import { split } from 'apollo-link';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { createUploadLink } from 'apollo-upload-client';
import store from 'store2';

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_ENDPOINT,
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS_API_ENDPOINT,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: () => {
      const token = store.get('constitution-token');

      return {
        authorization: token ? `Bearer ${token}` : '',
      };
    },
  },
});

const authLink = setContext((_, { headers }) => {
  const token = store.get('constitution-token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

export const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
});
