import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { InboxOutlined } from '@ant-design/icons';
import { message as m } from 'antd';

const csv = require('csvtojson');

export default class CSVUploader extends Component {
  state = {
    files: [],
  };

  onDrop = (acceptedFiles) => {
    this.setState({
      files: acceptedFiles,
    });

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        const fileAsBinaryString = reader.result;

        csv({
          noheader: true,
          output: 'json',
        })
          .fromString(fileAsBinaryString)
          .then((csvRows) => {
            const toJson = [];
            csvRows.forEach((aCsvRow, i) => {
              if (i !== 0) {
                const builtObject = {};

                Object.keys(aCsvRow).forEach((aKey) => {
                  const valueToAddInBuiltObject = aCsvRow[aKey];
                  let keyToAddInBuiltObject = csvRows[0][aKey];

                  keyToAddInBuiltObject = keyToAddInBuiltObject && keyToAddInBuiltObject.replace(/[^\w\s]/gi, '');

                  if (keyToAddInBuiltObject === 'media') {
                    builtObject[keyToAddInBuiltObject] = valueToAddInBuiltObject
                      ? {
                          previewLink: valueToAddInBuiltObject,
                          downloadLink: valueToAddInBuiltObject,
                        }
                      : null;
                  } else {
                    builtObject[keyToAddInBuiltObject] = valueToAddInBuiltObject;
                  }
                });

                const { message = null } = this.props;

                if (!!message) {
                  const {
                    workerName,
                    projectAddress,
                    dailyTaskDescription,
                    startTime,
                    endTime,
                    phoneNumber,
                  } = builtObject;

                  if (!workerName || !phoneNumber) {
                    m.error('Name and phone number are required');
                  } else {
                    builtObject[
                      'message'
                    ] = `Hi ${workerName}, Please reply to this message confirming if you are available to work the following job tomorrow:

Job site: ${projectAddress}
Job Description: ${dailyTaskDescription}
Start Time: ${startTime}
End Time: ${endTime}

When you arrive on the job site you must text us pictures of the areas you will be working on. At the end of the day you must send us pictures of your progress. If you fail to send either set of pictures your pay for this job may be delayed or cancelled.`;
                  }

                  toJson.push(builtObject);
                } else {
                  toJson.push(builtObject);
                }
              }
            });
            this.props.onDrop(toJson);
          });
      };

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');

      reader.readAsBinaryString(file);
    });
  };

  render() {
    const { message = null } = this.props;
    return (
      <section>
        <Dropzone className="d-zone" onDrop={this.onDrop.bind(this)} multiple={false}>
          <InboxOutlined className="d-zone" style={{ fontSize: 38 }} />
          <p style={{ padding: 5, textAlign: 'center' }}>Click or drag csv to this area to upload</p>
        </Dropzone>
        {message ? (
          message
        ) : (
          <aside>
            <p>
              Note: <b>name</b> and <b>personCategory</b> are required
            </p>
            <p>
              Possible values for <b>personCategory</b> are <b>Prospective Tenant</b> and <b>Prospective Employee</b>{' '}
              only (case sensitive).
            </p>
          </aside>
        )}
      </section>
    );
  }
}
