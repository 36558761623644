import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
  LoadingOutlined,
} from '@ant-design/icons';
import { Space, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { get, isEmpty } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useQuery } from 'react-apollo';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { INVESTORS } from './apollo_queries';
import { TableMainLabel } from '../components/styled';
import NumberFormat from 'react-number-format';

const columns = [
  {
    title: 'KYC',
    dataIndex: 'hasFailedKyc',
    render: (hasFailedKyc, { hasNotYetRequestKyc }) => (
      <div>
        {hasFailedKyc ? (
          <CloseCircleTwoTone size="large" twoToneColor="#e74c3c" />
        ) : hasNotYetRequestKyc ? (
          <ExclamationCircleTwoTone size="large" twoToneColor="#8e44ad" />
        ) : (
          <CheckCircleTwoTone size="large" twoToneColor="#1abc9c" />
        )}
      </div>
    ),
  },
  {
    title: 'Name',
    render: ({ individualAccount }) =>
      !isEmpty(individualAccount) ? (
        <TableMainLabel>{`${individualAccount.firstName} ${individualAccount.lastName}`}</TableMainLabel>
      ) : (
        <TableMainLabel style={{ color: '#c0392b' }}>
          No account setup
        </TableMainLabel>
      ),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    sorter: true,
    showSorterTooltip: false,
    responsive: ['md'],
  },
  {
    title: 'Pending Deposit',
    dataIndex: 'pendingDepositTotal',
    render: (i) => (
      <NumberFormat
        displayType="text"
        value={i}
        thousandSeparator={true}
        prefix={'$'}
        renderText={(value) => <div>{value}</div>}
      />
    ),
  },
  {
    title: 'Pending Invest',
    dataIndex: 'pendingInvestTotal',
    render: (i) => (
      <NumberFormat
        displayType="text"
        value={i}
        thousandSeparator={true}
        prefix={'$'}
        renderText={(value) => <div>{value}</div>}
      />
    ),
  },
  {
    title: 'Deposit',
    dataIndex: 'currentDepositTotal',
    render: (i) => (
      <NumberFormat
        displayType="text"
        value={i}
        thousandSeparator={true}
        prefix={'$'}
        renderText={(value) => <div>{value}</div>}
      />
    ),
  },
  {
    title: 'Invest',
    dataIndex: 'currentInvestTotal',
    render: (i) => (
      <NumberFormat
        displayType="text"
        value={i}
        thousandSeparator={true}
        prefix={'$'}
        renderText={(value) => <div>{value}</div>}
      />
    ),
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    sorter: true,
    showSorterTooltip: false,
    render: (t) => moment(t).fromNow(),
    responsive: ['lg'],
  },
  {
    title: 'Action',
    render: (_, item) => (
      <Space size="middle">
        <Link style={{ color: '#3498db' }} to={`/users/${item._id}`}>
          Open
        </Link>
      </Space>
    ),
  },
];

export function Investors({ totalUsers = 0, constLoading = false }) {
  const history = useHistory();
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(50);
  const [sortBy, setSortBy] = useState('updatedAt');
  const [sortDirection, setSortDirection] = useState('DESC');
  const { data, loading, error, refetch } = useQuery(INVESTORS, {
    variables: {
      count: pageCount,
      q: query,
      page,
      sortBy,
      sortDirection,
    },
  });

  if (error) {
    return <div>Error. Please refresh the page or contact JC</div>;
  }

  const items = get(data, 'users', []);

  const onChange = (pagination, _, sorter) => {
    if (pagination.current !== page) {
      setPage(pagination.current);
      history.push(`?p=${pagination.current}`);
    }

    if (sorter) {
      if (sorter.field !== sortBy) {
        setSortBy(sorter.field);
      }

      if (sorter.order) {
        setSortDirection(sorter.order === 'ascend' ? 'ASC' : 'DESC');
      }
    }

    if (pageCount !== pagination.pageSize) {
      setPageCount(pagination.pageSize);
    }
  };

  return (
    <Fragment>
      <br />
      <Search
        placeholder="Type here..."
        enterButton="Search"
        size="large"
        onSearch={() => refetch()}
        onChange={(e) => setQuery(e.target.value)}
        value={query}
      />
      <br />
      <br />
      {(loading || constLoading) && (
        <div className="web-load-wrap">
          <LoadingOutlined />
        </div>
      )}
      <Table
        rowKey={'_id'}
        columns={columns}
        dataSource={items}
        onChange={onChange}
        pagination={{
          total: totalUsers,
          pageSize: pageCount,
          position: ['bottomCenter'],
          current: page,
          hideOnSinglePage: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
        }}
        size={pageCount}
        loading={loading || constLoading}
      />
      <div style={{ marginTop: 10 }}></div>
      <CloseCircleTwoTone twoToneColor="#e74c3c" />
      <small style={{ marginLeft: 8, marginRight: 20 }}>
        Has failed KYC verification(s)
      </small>
      <ExclamationCircleTwoTone twoToneColor="#8e44ad" />
      <small style={{ marginLeft: 8 }}>No KYC requests made yet</small>
    </Fragment>
  );
}
