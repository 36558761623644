import React, { useEffect } from 'react';

export function Hours() {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location.href =
        'https://constitutionmgmt.typeform.com/to/ZfPXw4D7';
    }
  }, []);

  return (
    <a href="https://constitutionmgmt.typeform.com/to/ZfPXw4D7">Go to hours</a>
  );
}
