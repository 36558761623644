import {
  Button,
  Modal,
  Form,
  Select,
  InputNumber,
  message,
  Switch,
} from 'antd';
import React, { useState } from 'react';
import { currencyFormatter, currencyParser } from '../pages/PropertyDetails';
import { Fragment } from 'react';
import NumberFormat from 'react-number-format';
import { useMutation } from 'react-apollo';
import { ISSUE_FUND_CONSTI, REDEEM_LOCAL } from '../pages/apollo_mutations';

const FormItem = Form.Item;
const { Option } = Select;

export function FundSilaAccount({
  accountName = 'Constitution',
  userAccountId = '',
  bankAccounts = [],
  availableFund = 0,
}) {
  const [fundModal, showFundModal] = useState(false);
  const [form] = Form.useForm();
  const [issue, issueData] = useMutation(ISSUE_FUND_CONSTI);
  const [redeem, redeemData] = useMutation(REDEEM_LOCAL);
  const [isWithdraw, setProcess] = useState(false);

  return (
    <Fragment>
      <Button
        type="dashed"
        htmlType="button"
        onClick={() => showFundModal(true)}
        style={{ marginRight: 20 }}
        disabled={issueData.loading}
        loading={issueData.loading}
      >
        Fund / Withdraw
      </Button>

      <Modal
        title={accountName}
        visible={fundModal}
        onOk={() => {
          return form.submit();
        }}
        okType={isWithdraw ? 'danger' : 'primary'}
        onCancel={() => showFundModal(false)}
        okText={isWithdraw ? 'Withdraw' : 'Fund'}
        confirmLoading={issueData.loading || redeemData.loading}
      >
        <div style={{ background: 'white' }}>
          <Form
            onFinish={async (values) => {
              console.log({ values });
              if (!values.accountName) {
                return message.error('Bank required');
              }
              if (!values.amount) {
                return message.error('Amount required');
              }
              try {
                let data = null;
                if (isWithdraw) {
                  data = await redeem({
                    variables: {
                      ...values,
                      amount: parseFloat(values.amount),
                      userAccountId,
                    },
                  });
                } else {
                  data = await issue({
                    variables: {
                      ...values,
                      amount: parseFloat(values.amount),
                      userAccountId,
                    },
                  });
                }

                if (data) {
                  message.success('Success');
                  showFundModal(false);
                }
              } catch (error) {
                message.success(error.message);
              }
            }}
            name="fund"
            layout="vertical"
            form={form}
          >
            <FormItem label="Toggle operation here">
              <Switch
                checkedChildren="Fund"
                unCheckedChildren="Withdraw"
                checked={isWithdraw}
                onChange={(mode) => setProcess(mode)}
              />
            </FormItem>

            <FormItem label="Balance">
              <NumberFormat
                displayType="text"
                value={availableFund}
                thousandSeparator={true}
                prefix={'$'}
                renderText={(value) => <div>{value}</div>}
              />
            </FormItem>

            <FormItem name="accountName" label="Select a bank account" required>
              <Select style={{ width: '100%' }}>
                {bankAccounts.map(
                  ({
                    account_name,
                    account_number,
                    account_status,
                    account_type,
                    bankAccountNameLinked,
                  }) => (
                    <Option key={account_name} value={account_name}>
                      {`${bankAccountNameLinked} <${account_number}> | ${account_status} | ${account_type}`}
                    </Option>
                  )
                )}
              </Select>
            </FormItem>

            <FormItem name="amount" label="Amount" required>
              <InputNumber
                formatter={currencyFormatter}
                parser={currencyParser}
                style={{ width: '100%' }}
                required
              />
            </FormItem>
          </Form>
        </div>
      </Modal>
    </Fragment>
  );
}
