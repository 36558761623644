import React from 'react';
import { Button, Input, Form, message, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from 'react-apollo';
import { CBT } from '../pages/apollo_queries';
import { ASSIGN_WALLETS, CREATE_ENTITY } from '../pages/apollo_mutations';
import { US_STATES } from '../data/geo';
import { get } from 'lodash';

const FormItem = Form.Item;
const { Option } = Select;

export function RegisterEntityForm({
  refetch = () => Promise.resolve({}),
  closeAc = () => {},
  entityAccounts = null,
}) {
  const [createEntity, createEntityData] = useMutation(CREATE_ENTITY);
  const [assign, assignData] = useMutation(ASSIGN_WALLETS);
  const [createEntityForm] = Form.useForm();
  const { data, loading, error } = useQuery(CBT);
  const hasEmptyEntityAccounts = entityAccounts && entityAccounts.length === 0;

  if (loading || assignData.loading) {
    return (
      <div className="web-load-wrap">
        <LoadingOutlined />
      </div>
    );
  }

  if (error) {
    return (
      <div className="web-chat__main-content">
        Server error. Please refresh the page
      </div>
    );
  }

  const getBusinessTypes = data?.getBusinessTypes || [];

  return (
    <Form
      form={createEntityForm}
      initialValues={{}}
      onFinish={async (values) => {
        try {
          const resp = await createEntity({ variables: values });
          const userAccountId = get(resp, 'data.createEntity._id', '');

          if (hasEmptyEntityAccounts) {
            await assign({ variables: { userAccountId } });
          }

          await refetch();
          closeAc();
          message.success('Success');
        } catch (error) {
          message.error(
            error.message
              ? error.message.replace('GraphQL error:', '')
              : 'Please try again',
            8
          );
        }
      }}
      name="register-entity"
    >
      <div className="create-b b-n m-c">
        <FormItem name="accountName" label="Business name" required>
          <Input name="accountName" placeholder="BUSINESS NAME" required />
        </FormItem>

        <FormItem name="ein" label="EIN" required>
          <Input name="ein" placeholder="320567252" required />
        </FormItem>

        <FormItem name="address" label="Address" required>
          <Input name="address" placeholder="ADDRESS" required />
        </FormItem>

        <FormItem name="state" label="State" required>
          <Select
            showSearch
            filterOption={(iv, option) => {
              return (
                option &&
                typeof option.children === 'string' &&
                option.children.toLowerCase().includes(iv)
              );
            }}
          >
            {US_STATES.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem name="city" label="City" required>
          <Input name="city" placeholder="CITY" required />
        </FormItem>

        <FormItem name="zip" label="ZIP" required>
          <Input name="zip" placeholder="ZIP" required />
        </FormItem>

        <FormItem name="phone" label="Phone" required>
          <Input
            name="phone"
            placeholder="2036658995 (CORRECT FORMAT)"
            type="tel"
            required
          />
        </FormItem>

        <FormItem name="business_type" label="Business type" required>
          <Select>
            {getBusinessTypes.map((item) => (
              <Option key={item.name} value={item.name}>
                {item.label}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem name="business_website" label="Business website">
          <Input
            name="business_website"
            placeholder="https://app.constitutionmgmt.com/"
            type="url"
          />
        </FormItem>

        <FormItem>
          <Button
            type="dashed"
            htmlType="button"
            style={{ marginRight: 8 }}
            onClick={() => {
              createEntityForm.resetFields();
            }}
          >
            Reset
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: 8 }}
            disabled={createEntityData.loading}
            loading={createEntityData.loading}
          >
            Save
          </Button>
        </FormItem>
      </div>
    </Form>
  );
}
