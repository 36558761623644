import React, { Fragment, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useMutation, useQuery, useSubscription } from '@apollo/react-hooks';
import { INVESTMENT_PAYOUT } from './apollo_queries';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { get, isEmpty, omit } from 'lodash';
import {
  Alert,
  Button,
  Descriptions,
  Input,
  message,
  Select,
  Switch,
} from 'antd';
import { EditableTable } from '../components/EditableTable';
import { BULK_PAYOUT } from './apollo_mutations';
import { LAST_TRANSACTION } from './apollo_subscriptions';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

export const TRANSACTION_TYPES = [
  'Fees Earned',
  'Interest Earned',
  'Principal Repaid',
];

export function CreatePayout({
  entityAccounts = [],
  constLoading = false,
  hasIndividualUserAccount = false,
  hasEntityUserAccount = false,
}) {
  const history = useHistory();
  const match = useRouteMatch();
  const investmentId = get(match, 'params.id', '');
  const { data, error, loading, refetch } = useQuery(INVESTMENT_PAYOUT, {
    variables: { investmentId },
  });
  const [amountToSend, setAmountToSend] = useState('');
  const [generalNote, setGeneralNote] = useState('Interest Earned');
  const [uniqueItems, setItems] = useState([]);
  const [manualItems, setManualItems] = useState([]);
  const [bulkPayout, bulkPayoutData] = useMutation(BULK_PAYOUT);
  const [successPayoutId, setPayoutId] = useState('');
  const [successPayoutIdManual, setPayoutIdManual] = useState('');
  const funds = get(data, 'getInvestment.funds', []);
  const [businessUserAccountId, setBusinessUserAccountId] = useState('');
  const [adminUserAccount, setAdminUserAccount] = useState({});
  const lastTransactionData = useSubscription(LAST_TRANSACTION, {
    variables: { id: businessUserAccountId },
  });
  const [whosLoading, setWhosLoading] = useState('');
  const [oweAmountUse, setOweAmountUse] = useState(false);

  const [isSelectable, showSelect] = useState(false);
  const [isSelectableManual, showSelectManual] = useState(false);

  const idsub = get(lastTransactionData, 'data.lastTransactionStatus.id', '');
  const msg = get(
    lastTransactionData,
    'data.lastTransactionStatus.message',
    ''
  );

  useEffect(() => {
    if (!isEmpty(entityAccounts)) {
      const d = entityAccounts.filter((i) => i.isDefault)[0];
      if (!isEmpty(d)) {
        setAdminUserAccount(d);
        setBusinessUserAccountId(d._id);
      }
    }
  }, [entityAccounts]);

  useEffect(() => {
    async function lt() {
      if (idsub) {
        message.info(`Last transaction update: ${msg}`, 6);
        await refetch();
      }
    }

    lt();
  }, [idsub, refetch, msg]);

  useEffect(() => {
    const uItems = [];
    const mItems = [];
    let items = {};

    if (!isEmpty(funds)) {
      funds.forEach((item) => {
        const { userAccount, isManual, status } = item;
        if (status === 'success') {
          if (!isManual) {
            if (
              items[userAccount._id] &&
              typeof items[userAccount._id] === 'object'
            ) {
              items[userAccount._id]['amount'] =
                items[userAccount._id]['amount'] + item['amount'];
            } else {
              items[userAccount._id] = {
                ...item.userAccount,
                key: item.userAccount._id,
                amount: item.amount,
                status: item.status,
                note: '',
              };
            }
          } else {
            const dupeItem = omit(item, ['_id']);
            mItems.push({
              ...dupeItem,
              key: item.userAccount._id,
              note: '',
              ...item.userAccount,
            });
          }
        }
      });

      Object.keys(items).forEach((id) => {
        uItems.push(items[id]);
      });

      setManualItems(mItems);
      setItems(uItems);
    }
  }, [funds, setItems, loading]);

  if (loading || constLoading) {
    return (
      <div className="web-load-wrap">
        <LoadingOutlined />
      </div>
    );
  }

  if (!hasIndividualUserAccount || !hasEntityUserAccount) {
    return (
      <div className="web-chat__main-content">
        <Link to="/register-to-sila">Register business account here</Link>
      </div>
    );
  }

  const {
    address,
    investmentAmount,
    investmentBalance,
    fundingAmount,
    status,
  } = get(data, 'getInvestment', {});

  const columns = [
    {
      title: 'Account',
      render: (_, item) => (
        <span>
          {item.type === 'entity'
            ? item.accountName
            : `${item.firstName} ${item.lastName} (Individual)`}
        </span>
      ),
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      render: (percentage) => <span>{percentage}</span>,
    },
    {
      title: 'Invested',
      dataIndex: 'invested',
      render: (invested) => (
        <NumberFormat
          displayType="text"
          value={invested}
          thousandSeparator={true}
          prefix={'$'}
          renderText={(value) => <div>{value}</div>}
        />
      ),
    },
    {
      title: 'Amount to Send',
      dataIndex: 'computed',
      render: (computed) => (
        <NumberFormat
          displayType="text"
          value={computed}
          thousandSeparator={true}
          prefix={'$'}
          renderText={(value) => <div>{value}</div>}
        />
      ),
      editable: true,
    },
    {
      title: 'Type',
      dataIndex: 'note',
      editable: true,
    },
  ];

  const { kyc, transactions = [] } = adminUserAccount;

  let pendingDeposit = 0;

  if (!isEmpty(transactions)) {
    transactions.forEach((i) => {
      if (
        (i.status === 'pending' || i.status === 'queued') &&
        i.transaction_type === 'issue'
      ) {
        pendingDeposit = pendingDeposit + i.sila_amount / 100;
      }
    });
  }

  const handleSubmit = async (payoutItems = []) => {
    let msg = '';

    if (isEmpty(payoutItems)) {
      msg = 'Recipient required';
      return message.error(msg, 4);
    }

    // if (!pendingDeposit && amountToSend > availableFund) {
    //   msg = 'Not enough fund for this transaction';
    //   return message.error(msg, 4);
    // }

    // if (
    //   pendingDeposit &&
    //   pendingDeposit > 0 &&
    //   amountToSend > availableFund + pendingDeposit
    // ) {
    //   msg = 'Not enough fund for this transaction';
    //   return message.error(msg);
    // }

    // if (
    //   pendingDeposit &&
    //   pendingDeposit > 0 &&
    //   amountToSend > availableFund &&
    //   amountToSend <= availableFund + pendingDeposit
    // ) {
    //   msg = 'Please wait for incoming deposit to proceed';
    //   return message.error(msg);
    // }

    setWhosLoading('auto');

    try {
      const bulkPayoutInput = payoutItems
        .filter((i) => parseFloat(i.computed) > 0)
        .map((item) => ({
          userAccountId: item._id,
          amount: Math.round(parseFloat(item.computed) * 100) / 100,
          note: item.note,
        }));
      const { data } = await bulkPayout({
        variables: {
          data: bulkPayoutInput,
          totalAmount: amountToSend,
          title: generalNote,
          investmentId,
        },
      });

      if (data && data.bulkPayout) {
        setPayoutId(get(data, 'bulkPayout._id', ''));
      }

      setTimeout(async () => {
        await refetch();
      }, 100);

      message.success('Success');
      setWhosLoading('');
    } catch (error) {
      message.error(error.message, 6);
      setWhosLoading('');
    }
  };

  const handleSubmitManual = async (payoutItems = []) => {
    let msg = '';

    if (!oweAmountUse) {
      if (isEmpty(payoutItems)) {
        msg = 'Recipient required';
        return message.error(msg, 4);
      }

      // if (!pendingDeposit && amountToSend > availableFund) {
      //   msg = 'Not enough fund for this transaction';
      //   return message.error(msg, 4);
      // }

      // if (
      //   pendingDeposit &&
      //   pendingDeposit > 0 &&
      //   amountToSend > availableFund + pendingDeposit
      // ) {
      //   msg = 'Not enough fund for this transaction';
      //   return message.error(msg);
      // }

      // if (
      //   pendingDeposit &&
      //   pendingDeposit > 0 &&
      //   amountToSend > availableFund &&
      //   amountToSend <= availableFund + pendingDeposit
      // ) {
      //   msg = 'Please wait for incoming deposit to proceed';
      //   return message.error(msg);
      // }
    }

    setWhosLoading('manual');

    try {
      const bulkPayoutInput = payoutItems
        .filter((i) => parseFloat(i.computed) > 0)
        .map((item) => ({
          userAccountId: item._id,
          amount: Math.round(parseFloat(item.computed) * 100) / 100,
          note: item.note,
        }));

      const tAmount = bulkPayoutInput
        .map((i) => i.amount)
        .reduce((p, c) => p + c);

      if (tAmount > 0) {
        const { data } = await bulkPayout({
          variables: {
            data: bulkPayoutInput,
            totalAmount: oweAmountUse ? tAmount : amountToSend,
            title: generalNote,
            investmentId,
          },
        });

        if (data && data.bulkPayout) {
          setPayoutIdManual(get(data, 'bulkPayout._id', ''));
        }

        setTimeout(async () => {
          await refetch();
        }, 100);

        message.success('Success');
        setWhosLoading('');
      } else {
        message.warning('Total is 0, nothing is proccessed at this time');
      }
    } catch (error) {
      message.error(error.message, 6);
      setWhosLoading('');
    }
  };

  const availableFund = get(adminUserAccount, 'availableFund', 0);

  return (
    <div style={{ padding: 10, paddingBottom: 100 }}>
      {error && <Alert message={error.message} />}
      <Descriptions
        title="Investment Loan"
        size="small"
        extra={
          <Button onClick={async () => refetch()} type="primary">
            Refresh
          </Button>
        }
        bordered
      >
        <Descriptions.Item label="Address">{address}</Descriptions.Item>
        <Descriptions.Item label="Investment Amount">
          <NumberFormat
            displayType="text"
            value={investmentAmount}
            thousandSeparator={true}
            prefix={'$'}
            renderText={(value) => <div>{value}</div>}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Funding Amount">
          <NumberFormat
            displayType="text"
            value={fundingAmount}
            thousandSeparator={true}
            prefix={'$'}
            renderText={(value) => <div>{value}</div>}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Investment Balance">
          <NumberFormat
            displayType="text"
            value={investmentBalance}
            thousandSeparator={true}
            prefix={'$'}
            renderText={(value) => <div>{value}</div>}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Constitution Balance">
          <NumberFormat
            displayType="text"
            value={availableFund}
            thousandSeparator={true}
            prefix={'$'}
            renderText={(value) => <div>{value}</div>}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          {status === 'available_investment'
            ? 'Available Investment'
            : 'Previously Funded'}
        </Descriptions.Item>
      </Descriptions>

      <div style={{ height: 20 }}></div>

      {kyc === 'passed' ? (
        <Fragment>
          <Descriptions title="Payout" size="small" />

          {!isEmpty(uniqueItems) || !isEmpty(manualItems) ? (
            <Fragment>
              <h4>Type</h4>
              <Select
                size="large"
                onChange={(value) => setGeneralNote(value)}
                value={generalNote}
                style={{ width: 520 }}
              >
                {TRANSACTION_TYPES.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>

              <div style={{ height: 20 }}></div>
              <h4>Amount</h4>
              <Input
                placeholder="Set total amount to send (USD)"
                value={amountToSend}
                onChange={(e) => {
                  if (
                    e.target &&
                    parseFloat(e.target.value) &&
                    !isNaN(parseFloat(e.target.value))
                  ) {
                    setAmountToSend(parseFloat(e.target.value));
                  } else {
                    setAmountToSend('');
                  }
                }}
                style={{ width: 520 }}
                size="large"
                type="number"
              />

              <div style={{ height: 20 }}></div>

              {amountToSend && amountToSend > 0 && (
                <NumberFormat
                  displayType="text"
                  value={amountToSend}
                  thousandSeparator={true}
                  prefix={'Total amount to send: $'}
                  renderText={(value) => (
                    <h2 style={{ color: '#1abc9c' }}>{value}</h2>
                  )}
                />
              )}

              <div style={{ height: 20 }}></div>

              <Switch
                checkedChildren="All"
                unCheckedChildren="Select Recipients"
                defaultChecked
                checked={isSelectable}
                onChange={(mode) => showSelect(mode)}
              />

              <div style={{ height: 10 }}></div>

              <EditableTable
                title="Investor Summary"
                originData={uniqueItems.map((item) => ({
                  ...item,
                  percentage: `${
                    investmentAmount && investmentAmount > 0
                      ? ((item.amount / investmentAmount) * 100).toFixed(4)
                      : 0
                  }%`,
                  invested: item.amount.toFixed(2),
                  computed:
                    investmentAmount && investmentAmount > 0
                      ? (
                          (item.amount / investmentAmount) *
                          amountToSend
                        ).toFixed(2)
                      : 0,
                }))}
                localColumns={columns}
                loading={whosLoading === 'auto' && bulkPayoutData.loading}
                generalNote={generalNote}
                onSubmit={handleSubmit}
                amountToSend={amountToSend}
                pushToPayout={() => history.push(`/payouts/${successPayoutId}`)}
                payoutId={successPayoutId}
                isSelectable={isSelectable}
              />

              <div style={{ height: 25 }}></div>
              <hr />

              <Switch
                style={{ backgroundColor: 'rebeccapurple' }}
                checkedChildren="All"
                unCheckedChildren="Select Recipients"
                defaultChecked
                checked={isSelectableManual}
                onChange={(mode) => showSelectManual(mode)}
              />

              <Switch
                style={{ backgroundColor: '#34495e', marginLeft: 20 }}
                checkedChildren="Use computed amount"
                unCheckedChildren="Use owe amount"
                defaultChecked
                checked={oweAmountUse}
                onChange={(mode) => setOweAmountUse(mode)}
              />

              <div style={{ height: 10 }}></div>

              <EditableTable
                title="Manually Added Investor Summary"
                originData={manualItems.map((item) => ({
                  ...item,
                  percentage: `${item.currentlyOwnedPercentage.toFixed(4)}%`,
                  invested: item.amount.toFixed(2),
                  computed:
                    oweAmountUse && item.currentlyOwedAmount
                      ? item.currentlyOwedAmount.toFixed(2)
                      : (
                          (item.currentlyOwnedPercentage / 100) *
                          amountToSend
                        ).toFixed(2),
                }))}
                localColumns={columns}
                loading={whosLoading === 'manual' && bulkPayoutData.loading}
                generalNote={generalNote}
                onSubmit={handleSubmitManual}
                amountToSend={amountToSend}
                pushToPayout={() =>
                  history.push(`/payouts/${successPayoutIdManual}`)
                }
                payoutId={successPayoutIdManual}
                isSelectable={isSelectableManual}
                isUsingOweAmount={oweAmountUse}
              />
            </Fragment>
          ) : (
            <div>No investments found</div>
          )}
        </Fragment>
      ) : (
        <div>Please wait for KYC to pass</div>
      )}
    </div>
  );
}
