import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Input, Form, Select, message, DatePicker, Checkbox, Row, Col } from 'antd';
import { useMutation } from 'react-apollo';

import { CREATE_SESSION } from './apollo_mutations';
import { omit, get, isEmpty } from 'lodash';

const Option = Select.Option;
const TextArea = Input.TextArea;
const FormItem = Form.Item;

function CreateSession() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [job, renderJob] = useState(false);
  const [display, setDisplay] = useState({});

  const [mutate] = useMutation(CREATE_SESSION);

  return (
    <div className="web-chat__main-content">
      <div className="tr__header">
        <div className="top_header">
          <div style={{ fontWeight: 'bolder' }} className="sub-text">
            Create a new session
          </div>
          <div className="sub-text">
            Adding valid phone number and email will trigger our automatic SMS and Email services
          </div>
        </div>
        <div className="tr__b">
          <Button style={{ marginLeft: 10 }} role="button" onClick={() => history.push('/')} type="primary" danger>
            Back
          </Button>
        </div>
      </div>
      <div className="creating">
        <div className="create-b b-n b-r m-c">
          <Form
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 20 }}
            onFinish={async (values) => {
              let media = [];
              const input = omit(values, ['imageUrl']);

              if (values && values.imageUrl) {
                media.push({
                  previewLink: values.imageUrl,
                  downloadLink: values.imageUrl,
                });
              }

              const toSendSms = values && !!values.phoneNumber;
              const toSendEmail = values && !!values.email;

              input.media = media;
              input.toSendEmail = toSendEmail;
              input.toSendSms = toSendSms;
              input.phoneNumber = values && values.phoneNumber && `+1${values.phoneNumber}`;
              input.showingAt = values && values.showingAt && values.showingAt.toString();

              try {
                const { data } = await mutate({ variables: { data: input } });
                const session = get(data, 'createSession', {});
                setDisplay(session);
              } catch (error) {
                message.info('Error', error.message);
              }
            }}
            initialValues={{ propertyId: null, personCategory: null }}
            name="create-session"
          >
            <div style={{ background: 'white' }}>
              <FormItem
                name="name"
                label="Name"
                required
                rules={[{ type: 'string', required: true, message: 'Required' }]}
              >
                <Input name="name" placeholder="Name" />
              </FormItem>

              <FormItem
                name="address"
                label="Address"
                required
                rules={[{ type: 'string', required: true, message: 'Required' }]}
              >
                <Input name="address" placeholder="Address (property address if prospective tenant)" />
              </FormItem>

              <FormItem
                name="personCategory"
                label="Person Category"
                required
                rules={[{ type: 'string', required: true, message: 'Required' }]}
              >
                <Select
                  name="personCategory"
                  style={{ width: '100%' }}
                  onSelect={(e) => (e === 'Prospective Employee' ? renderJob(true) : renderJob(false))}
                >
                  <Option value={null}>Select type of recipient</Option>
                  <Option value="Prospective Tenant">Propective Tenant</Option>
                  <Option value="Prospective Employee">Propective Employee</Option>
                </Select>
              </FormItem>

              <FormItem name="email" label="Email" rules={[{ type: 'email', message: 'Invalid format' }]}>
                <Input name="email" placeholder="Email" type="email" />
              </FormItem>

              <FormItem name="phoneNumber" label="Phone number" rules={[{ len: 10, message: 'Invalid format' }]}>
                <Input name="phonerNumber" placeholder="10 digit US number e.g. 2033496942" type="number" />
              </FormItem>

              <FormItem name="city" label="City">
                <Input name="city" placeholder="City" />
              </FormItem>

              <FormItem name="state" label="State">
                <Input name="state" placeholder="State" />
              </FormItem>

              <FormItem name="propertyId" label="Property Address">
                <Select name="propertyId" style={{ width: '100%' }}>
                  <Option value={null}>Select property address</Option>
                  <Option value="5835626">221 Jefferson St</Option>
                  <Option value="5362854">167 E Main St</Option>
                  <Option value="5365404">31 East St</Option>
                  <Option value="5743818">2352 Barnum Ave</Option>
                  <Option value="5830888">158 Park St</Option>
                </Select>
              </FormItem>

              <FormItem name="imageUrl" label="Image" rules={[{ type: 'url', message: 'Invalid format' }]}>
                <Input name="imageUrl" placeholder="Image URL e.g. https://www.pet.com/dog.jpg" />
              </FormItem>

              {job && form.getFieldValue('personCategory') === 'Prospective Employee' && (
                <FormItem
                  name="job"
                  label="Job type"
                  rules={[
                    { required: form.getFieldValue('personCategory') === 'Prospective Employee', message: 'Required' },
                  ]}
                >
                  <Input name="job" placeholder="Job (if propective employee only)" />
                </FormItem>
              )}

              <FormItem name="subject" label="Subject">
                <Input name="subject" placeholder="Subect (if email is provided)" />
              </FormItem>

              <FormItem name="text" label="Message">
                <TextArea name="text" placeholder="Your message" />
              </FormItem>

              <FormItem name="showingAt" label="Showing date" className="add-plan-datepicker-field" initialValue={null}>
                <DatePicker
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY-MM-DD HH:mm"
                  style={{ width: '100%' }}
                  className="date-picker"
                />
              </FormItem>

              <FormItem name="comment" label="Comment">
                <TextArea name="comment" placeholder="Constitution management comment" />
              </FormItem>

              <Row>
                <Col span={6}></Col>
                <Col span={6}>
                  <FormItem name="leadInterested" valuePropName="checked">
                    <Checkbox>Lead Interested</Checkbox>
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem name="sectionEight" valuePropName="checked">
                    <Checkbox>Section 8</Checkbox>
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem name="confirmed" valuePropName="checked">
                    <Checkbox>Confirmed</Checkbox>
                  </FormItem>
                </Col>
              </Row>

              <FormItem
                wrapperCol={{
                  sm: {
                    span: 16,
                    offset: 6,
                  },
                }}
              >
                <Button type="default" htmlType="button" style={{ marginRight: 8 }} onClick={() => form.resetFields()}>
                  Reset
                </Button>

                <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
                  Submit
                </Button>

                {!isEmpty(display) && display._id && (
                  <Button type="danger" htmlType="button" onClick={() => history.push(`/session/${display._id}`)}>
                    Go to new session
                  </Button>
                )}
              </FormItem>
            </div>
          </Form>
        </div>
        <pre className="create-s b-n m-c">
          <samp>{isEmpty(display) ? 'Results here (empty)' : JSON.stringify(display, null, 4)}</samp>
        </pre>
      </div>
    </div>
  );
}

export default CreateSession;
