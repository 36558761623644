import { LoadingOutlined } from '@ant-design/icons';
import { Button, Table, Space } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import { useState } from 'react';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { AddTagModal } from '../components/AddTagModal';
import { TableMainLabel } from '../components/styled';
import { UpdateTagModal } from '../components/UpdateTagModal';
import { TAGS } from './apollo_queries';

export function Tags() {
  const history = useHistory();
  const [tagAdd, showTagAdd] = useState(false);
  const [selectedTag, selectTag] = useState(null);
  const { data, error, loading, refetch } = useQuery(TAGS);

  const columns = [
    {
      title: 'Label',
      dataIndex: 'name',
      render: (t) => <TableMainLabel>{t}</TableMainLabel>,
    },
    {
      title: 'Color',
      dataIndex: 'color',
      render: (t) => (
        <div
          style={{ height: 28, width: 28, background: t, borderRadius: '50%' }}
        ></div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (t) => <TableMainLabel>{t}</TableMainLabel>,
    },
    {
      title: 'Action',
      render: (_, item) => (
        <Space size="middle">
          <Button danger onClick={() => selectTag(item)}>
            Update
          </Button>
        </Space>
      ),
    },
  ];

  if (loading) {
    return (
      <div className="web-load-wrap">
        <LoadingOutlined />
      </div>
    );
  }

  if (error) {
    return <div>Please refresh page, if error persists contact JC</div>;
  }

  const allTags = data?.tags || [];

  return (
    <div style={{ paddingBottom: 30 }} className="web-chat__main-content">
      <div className="tr__header">
        <div className="top_header">
          <div style={{ fontWeight: 'bolder' }} className="sub-text">
            Tags
          </div>
        </div>
        <div className="tr__b">
          <Button
            style={{ marginLeft: 10 }}
            role="button"
            onClick={() => showTagAdd(true)}
            type="primary"
          >
            Create
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            role="button"
            onClick={() => history.goBack()}
            type="primary"
            danger
          >
            Back
          </Button>
        </div>
      </div>
      <div className="creating">
        <div className="create-b b-n m-c">
          <div style={{ background: 'white' }}>
            <Table
              columns={columns}
              dataSource={allTags}
              rowKey={'_id'}
              size={20}
              pagination={{
                pageSize: 20,
              }}
            />
          </div>
        </div>
      </div>
      <AddTagModal
        visible={tagAdd}
        handleClose={() => showTagAdd(false)}
        title="Add tag"
        refetch={refetch}
      />
      {selectedTag && (
        <UpdateTagModal
          visible={!!selectedTag}
          handleClose={() => selectTag(null)}
          title={isEmpty(selectedTag) ? 'Update tag' : selectedTag.name}
          refetch={refetch}
          {...selectedTag}
        />
      )}
    </div>
  );
}
