import { LoadingOutlined } from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { useQuery } from 'react-apollo';
import { Link, useHistory } from 'react-router-dom';
import { SILA_FAILURES } from './apollo_queries';
import moment from 'moment';

const columns = [
  {
    title: 'Title',
    dataIndex: 'rawTag',
  },
  {
    title: 'Type',
    dataIndex: 'type',
  },
  {
    title: 'Last updated',
    dataIndex: 'updatedAt',
    render: (d) => <span>{moment(parseInt(d)).format('LLL')}</span>,
  },
  {
    title: 'Action',
    dataIndex: '_id',
    render: (id) => (
      <Space size="middle">
        <Link style={{ color: '#1abc9c' }} to={`/sila-failures/${id}`}>
          Update
        </Link>
      </Space>
    ),
  },
];

export function SilaFailures() {
  const { data, error, loading } = useQuery(SILA_FAILURES, {
    variables: { type: 'kyc' },
  });
  const history = useHistory();

  if (loading) {
    return (
      <div className="web-load-wrap">
        <LoadingOutlined />
      </div>
    );
  }

  if (error) {
    return (
      <div className="web-chat__main-content wc__solo">
        Error, please refresh page or check your internet connection. If error
        persists contact JC
      </div>
    );
  }

  const items = get(data, 'silaFailures', []);

  return (
    <div className="web-chat__main-content wc__solo">
      <div className="tr__header">
        <div className="top_header">
          <div style={{ fontWeight: 'bolder' }} className="sub-text">
            Sila Failures
          </div>
        </div>
        <div className="tr__b">
          <Button
            style={{ marginLeft: 10 }}
            role="button"
            onClick={() => history.push('/')}
            type="primary"
            danger
          >
            Back
          </Button>
        </div>
      </div>
      <div className="creating creating-v">
        <Table
          rowKey={'_id'}
          columns={columns}
          dataSource={items}
          pagination={{
            position: ['bottomCenter'],
            hideOnSinglePage: true,
          }}
          size={100}
          loading={loading}
        />
      </div>
    </div>
  );
}
