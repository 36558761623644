import React, { Component } from 'react';

import Message from './Message';

class Messages extends Component {
  componentDidMount() {
    this.props.subscribeToNewMessages();
  }

  componentDidUpdate(prevProps) {
    const { messages, onNewMessage } = this.props;
    if (messages.length - prevProps.messages.length === 1) {
      onNewMessage();
    }
  }

  render() {
    const { messages = [] } = this.props;

    return (
      <div className="m-flex-m">
        {messages.map((item, index) => (
          <Message
            currentMessage={item}
            nextMessage={index === messages.length - 1 ? null : messages[index + 1]}
            key={item._id}
          />
        ))}
      </div>
    );
  }
}

export default Messages;
