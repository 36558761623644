import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { Table, Input, Space, Switch, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { GET_SESSIONS, SESSION_COUNT } from './apollo_queries';
import { UserStatus } from '../components/UserStatus';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
    showSorterTooltip: false,
    render: (_, item) => <UserStatus {...item} />,
  },
  {
    title: 'Phone',
    dataIndex: 'phoneNumber',
    sorter: true,
    showSorterTooltip: false,
    responsive: ['md'],
  },
  {
    title: 'Address',
    dataIndex: 'address',
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: 'Showing Time',
    dataIndex: 'showingAt',
    sorter: true,
    showSorterTooltip: false,
    render: (t) => (t ? moment(parseInt(t)).format('LLL') : <small>N/A</small>),
    responsive: ['lg'],
  },
  {
    title: 'Lead Interested',
    dataIndex: 'leadInterested',
    render: (t) => (t ? 'Yes' : 'No'),
    responsive: ['lg'],
  },
  {
    title: 'Confirmed',
    dataIndex: 'confirmed',
    render: (t) => (t ? 'Yes' : 'No'),
    responsive: ['lg'],
  },
  {
    title: 'Section 8',
    dataIndex: 'sectionEight',
    render: (t) => (t ? 'Yes' : 'No'),
    responsive: ['lg'],
  },
  {
    title: 'Action',
    render: (_, item) => (
      <Space size="middle">
        <Link style={{ color: '#1abc9c' }} to={`/session/${item._id}/update`}>
          Update
        </Link>
        <Link style={{ color: '#3498db' }} to={`/session/${item._id}`}>
          Open
        </Link>
      </Space>
    ),
  },
];

const { Search } = Input;

function Home() {
  // initial load hooks
  const totalSession = useQuery(SESSION_COUNT, {
    variables: { personCategory: 'Prospective Tenant' },
  });
  const total = get(totalSession, 'data.sessionCount.count', 50);
  const history = useHistory();
  const location = useLocation();

  // local state
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState('updatedAt');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [pageCount, setPageCount] = useState(50);
  const [unread, showUnread] = useState(false);

  const { p } = qs.parse(location.search);

  useEffect(() => {
    if (p) {
      setPage(parseInt(p));
    }
  }, [p]);

  // updating hooks
  const { data, loading, refetch } = useQuery(GET_SESSIONS, {
    variables: {
      count: 10,
      q: query,
      page,
      sortBy,
      sortDirection,
      personCategory: 'Prospective Tenant',
      onlyWithUnread: unread,
    },
  });
  const sessions = get(data, 'getSessions', []);

  useEffect(() => {
    async function r() {
      await refetch();
    }

    r();
    // eslint-disable-next-line
  }, []);

  const onChange = (pagination, _, sorter) => {
    if (pagination.current !== page) {
      setPage(pagination.current);
      history.push(`?p=${pagination.current}`);
    }

    if (sorter) {
      if (sorter.field !== sortBy) {
        setSortBy(sorter.field);
      }

      if (sorter.order) {
        setSortDirection(sorter.order === 'ascend' ? 'ASC' : 'DESC');
      }
    }

    if (pageCount !== pagination.pageSize) {
      setPageCount(pagination.pageSize);
    }
  };

  return (
    <div className="web-chat__main-content">
      <div className="tr__header">
        <div className="top_header">
          <div style={{ fontWeight: 'bolder' }} className="sub-text">
            Prospective Tenants Sessions
          </div>
        </div>
        <div className="tr__b">
          <Switch
            checkedChildren="All"
            unCheckedChildren="Only with unread"
            defaultChecked
            checked={unread}
            onChange={(mode) => showUnread(mode)}
            loading={loading || totalSession.loading}
          />
          <Button
            style={{ marginLeft: 15 }}
            role="button"
            onClick={() => history.goBack()}
            type="primary"
            danger
          >
            Back
          </Button>
        </div>
      </div>
      <div className="creating">
        <div className="create-b b-n m-c">
          <div style={{ background: 'white' }}>
            <Search
              placeholder="Search for name, phone, email, property ID, city or state"
              enterButton="Search"
              size="large"
              onSearch={() => refetch()}
              onChange={(e) => setQuery(e.target.value)}
              value={query}
            />
            <br />
            <br />
            {(loading || totalSession.loading) && (
              <div className="web-load-wrap">
                <LoadingOutlined />
              </div>
            )}
            <Table
              rowKey={'_id'}
              columns={columns}
              dataSource={sessions}
              onChange={onChange}
              pagination={{
                total,
                pageSize: pageCount,
                position: ['bottomCenter'],
                current: page,
                hideOnSinglePage: true,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
              }}
              size={pageCount}
              loading={loading || totalSession.loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
