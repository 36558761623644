import { Button, Form, Input, message, Upload } from 'antd';
import React from 'react';
import { useMutation } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import Editor from 'react-quill';
import { CREATE_ARTICLE } from './apollo_mutations';
import { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import store from 'store2';
import { get, isEmpty, omit } from 'lodash';
import CreatableSelect from 'react-select/creatable';

const FormItem = Form.Item;

export function AddArticle() {
  const [addArticle, { loading }] = useMutation(CREATE_ARTICLE);
  const [content, setContent] = useState('');
  const [articleId, setArticleId] = useState('');
  const [tagsLocal, setTagsLocal] = useState([]);
  const history = useHistory();
  const [form] = Form.useForm();

  return (
    <div className="web-chat__main-content">
      <div className="tr__header">
        <div className="top_header">
          <div style={{ fontWeight: 'bolder' }} className="sub-text">
            Add an article
          </div>
        </div>
        <div className="tr__b">
          <Button
            style={{ marginLeft: 10 }}
            role="button"
            onClick={() => history.goBack()}
            type="primary"
            danger
          >
            Back
          </Button>
        </div>
      </div>
      <div className="creating">
        <div className="create-b b-n m-c">
          <div style={{ background: 'white' }}>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              form={form}
              initialValues={{ route: 'blog' }}
              onFinish={async (values) => {
                const authorEmail = store.get('constitution_email');
                const { banner } = values;
                const data = omit(values, ['banner']);

                if (!content) {
                  return message.error('Content is required');
                }

                if (!isEmpty(banner)) {
                  data['banner'] = banner[0]['originFileObj'];
                }

                if (!isEmpty(tagsLocal)) {
                  data['tags'] = tagsLocal.map((i) => i.value.toLowerCase());
                }

                data['createdBy'] = authorEmail;
                data['updateBy'] = authorEmail;
                data['content'] = content;

                try {
                  const resp = await addArticle({ variables: data });
                  const id = get(resp, 'data.createArticle._id', null);
                  setArticleId(id);
                  message.success('Success');
                } catch (error) {
                  message.error(error.message);
                }
              }}
              style={{ padding: 10, paddingRight: 50, paddingLeft: 0 }}
            >
              <FormItem name="title" label="Title" required>
                <Input
                  name="title"
                  placeholder="Title of the article"
                  required
                />
              </FormItem>

              <FormItem name="subtitle" label="Subtitle">
                <Input name="subtitle" placeholder="Subtitle is not required" />
              </FormItem>

              <FormItem label="Tags">
                <CreatableSelect
                  styles={{
                    control: (styles) => ({ ...styles, borderRadius: 2 }),
                  }}
                  onChange={(allTags) => {
                    if (!isEmpty(allTags)) {
                      return setTagsLocal(allTags);
                    }

                    return null;
                  }}
                  value={tagsLocal}
                  isClearable
                  isSearchable
                  isMulti
                />
              </FormItem>

              <FormItem label="Content" required>
                <Editor theme="snow" value={content} onChange={setContent} />
              </FormItem>

              <FormItem name="route" label="Route" required>
                <Input
                  name="route"
                  placeholder="https://constlending.com/blog"
                  required
                  disabled
                />
              </FormItem>

              <FormItem name="slug" label="Slug">
                <Input
                  name="slug"
                  placeholder="my-slug ---> https://constlending.com/my-route/my-slug"
                />
              </FormItem>

              <FormItem
                name="banner"
                label="Article banner"
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }

                  return e && e.fileList;
                }}
              >
                <Upload
                  name="banner"
                  listType="picture"
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>
                    Click to set banner image
                  </Button>
                </Upload>
              </FormItem>

              <FormItem
                wrapperCol={{
                  sm: {
                    span: 16,
                    offset: 4,
                  },
                }}
              >
                <Button
                  type="default"
                  htmlType="button"
                  style={{ marginRight: 8 }}
                  onClick={() => {
                    form.resetFields();
                    setArticleId('');
                  }}
                  disabled={loading}
                >
                  Reset
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 8 }}
                  disabled={loading}
                  loading={loading}
                >
                  Submit
                </Button>
                {articleId && (
                  <div>
                    <a
                      href={`/articles/${articleId}`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Success. Click here to edit article.
                    </a>
                  </div>
                )}
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
