import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { useQuery } from 'react-apollo';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Alert, Button, Table } from 'antd';
import { get, isEmpty } from 'lodash';
import { PAYOUTS } from './apollo_queries';
import { LoadingOutlined } from '@ant-design/icons';

const columns = [
  {
    title: 'Address',
    dataIndex: 'investment',
    render: (inv) => (
      <span>{!isEmpty(inv) && inv.address ? inv.address : '-'}</span>
    ),
  },
  {
    title: 'Sender',
    dataIndex: 'email',
  },
  {
    title: 'Total amount',
    dataIndex: 'amount',
    render: (amount) => (
      <NumberFormat
        displayType="text"
        value={amount}
        thousandSeparator={true}
        prefix={'$'}
        renderText={(value) => <span>{value}</span>}
      />
    ),
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    render: (d) => <span>{moment(d).format('LLL')}</span>,
  },
  {
    title: 'Action',
    render: (_, item) => <Link to={`/payouts/${item._id}`}>Open</Link>,
  },
];

export function Payouts({
  totalPayouts = 0,
  hasIndividualUserAccount = false,
  hasEntityUserAccount = false,
  constLoading = false,
}) {
  const [page, setPage] = useState(1);
  const { data, loading, error } = useQuery(PAYOUTS, {
    variables: { count: 10, page },
  });
  const history = useHistory();
  const location = useLocation();
  const { p } = qs.parse(location.search);

  useEffect(() => {
    if (p) {
      setPage(parseInt(p));
    }
  }, [p]);

  if (loading || constLoading) {
    return (
      <div className="web-load-wrap">
        <LoadingOutlined />
      </div>
    );
  }

  if (!hasIndividualUserAccount || !hasEntityUserAccount) {
    return (
      <div className="web-chat__main-content">
        <Link to="/register-to-sila">Register business account here</Link>
      </div>
    );
  }

  const onChange = (pagination) => {
    if (pagination.current !== page) {
      setPage(pagination.current);
      history.push(`/payouts?p=${pagination.current}`);
    }
  };

  const items = get(data, 'payouts', []);

  return (
    <div style={{ padding: 20, paddingRight: 10, paddingLeft: 10 }}>
      {error && <Alert message={error && error.message} />}
      <Button
        type="primary"
        onClick={() => history.push('/properties-and-investments')}
        style={{ marginBottom: 20 }}
      >
        New payout
      </Button>
      <Table
        title={() => <h3>Payouts</h3>}
        rowKey={'_id'}
        columns={columns}
        dataSource={items}
        onChange={onChange}
        pagination={{
          total: totalPayouts,
          pageSize: 10,
          position: ['bottomCenter'],
          current: page,
          hideOnSinglePage: true,
        }}
        bordered
        loading={loading}
      />
    </div>
  );
}
