import { Button, Descriptions, Divider, message, Table } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useMutation, useSubscription } from '@apollo/react-hooks';
import { CERTIFY, CHECK_KYC } from '../pages/apollo_mutations';
import {
  LINK_ACCOUNT_SUB,
  UPDATE_KYC_USER,
} from '../pages/apollo_subscriptions';

const { Item } = Descriptions;

export const columns = [
  {
    title: 'Account Link Status',
    dataIndex: 'account_link_status',
  },
  {
    title: 'Account Name',
    dataIndex: 'bankAccountNameLinked',
  },
  {
    title: 'Account Number',
    dataIndex: 'account_number',
  },
  {
    title: 'Account Status',
    dataIndex: 'account_status',
  },
  {
    title: 'Account Type',
    dataIndex: 'account_type',
  },
];

export function EntityInfo({
  refetch = () => Promise.resolve({}),
  entityAccount = {},
}) {
  const {
    kyc = '',
    bankAccounts = [],
    isCertified = false,
    accountName = '',
    isDefault = false,
  } = entityAccount;
  const [checkKyc, checkKycData] = useMutation(CHECK_KYC);
  const [certify, certifyData] = useMutation(CERTIFY);

  return (
    <div>
      <Descriptions size="small" title={accountName} bordered>
        <Item label="Entity">{accountName}</Item>
        <Item label="KYC Status">{kyc}</Item>
        <Item label="Certified">{isCertified ? 'yes' : 'no'}</Item>
      </Descriptions>

      {kyc.toLowerCase() !== 'passed' && (
        <Button
          disabled={checkKycData.loading}
          loading={checkKycData.loading}
          type="primary"
          htmlType="button"
          onClick={async () => {
            try {
              await checkKyc({
                variables: {
                  userAccountId: entityAccount && entityAccount._id,
                },
              });
              await refetch();
              message.success('Request success');
            } catch (error) {
              message.error(
                error.message
                  ? error.message.replace('GraphQL error:', '')
                  : 'Please try again',
                8
              );
            }
          }}
          style={{ marginTop: 15, marginBottom: 15, marginRight: 15 }}
        >
          Check KYC
        </Button>
      )}

      {!isCertified && (
        <Button
          disabled={certifyData.loading}
          loading={certifyData.loading}
          type="dashed"
          htmlType="button"
          danger
          onClick={async () => {
            try {
              await certify({
                variables: {
                  userAccountId: !isEmpty(entityAccount)
                    ? entityAccount._id
                    : '',
                },
              });
              await refetch();
              message.success('Request success');
            } catch (error) {
              message.error(
                error.message
                  ? error.message.replace('GraphQL error:', '')
                  : 'Please try again',
                8
              );
            }
          }}
          style={{ marginTop: 15, marginBottom: 15, marginRight: 15 }}
        >
          Certify Business
        </Button>
      )}

      {isDefault && (
        <Button
          disabled={true}
          onClick={async () => {
            // try {
            //   await setDefault({
            //     variables: {
            //       userAccountId: entityAccount && entityAccount._id,
            //     },
            //   });
            //   await refetch();
            //   message.success('Request success');
            // } catch (error) {
            //   message.error(
            //     error.message
            //       ? error.message.replace('GraphQL error:', '')
            //       : 'Please try again',
            //     8
            //   );
            // }
          }}
          style={{ marginTop: 15, marginBottom: 15, marginRight: 15 }}
          htmlType="button"
        >
          Default entity account
        </Button>
      )}

      <div></div>

      {isDefault && (
        <small>
          Reassigning a new default entity account will require to assign new
          wallet to each investment property/loan, to do this please contact JC
          for assistance
        </small>
      )}

      <div style={{ height: 20 }} />

      {!isEmpty(bankAccounts) ? (
        <Table
          dataSource={bankAccounts}
          columns={columns}
          rowKey="account_name"
          size="small"
        ></Table>
      ) : (
        <div>No bank account linked</div>
      )}
      <Divider />
    </div>
  );
}

export function Entities({
  entityAccounts = [],
  refetch = () => Promise.resolve({}),
}) {
  return entityAccounts.map((item, i) => (
    <EntityInfo key={i} entityAccount={item} refetch={refetch} />
  ));
}
