import { Input, Modal, Select, Form, message, Button } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useMutation } from 'react-apollo';
import { ChromePicker } from 'react-color';
import { tagTypes } from '../guide';
import { DELETE_TAG, UPDATE_TAG } from '../pages/apollo_mutations';

const FormItem = Form.Item;
const { Option } = Select;

export function UpdateTagModal({
  visible = false,
  title = '',
  handleClose = () => {},
  refetch = () => Promise.resolve({}),
  _id = '',
  color = '',
  name = '',
  type = '',
}) {
  const [form] = Form.useForm();
  const [colorLocal, setColorLocal] = useState(color);
  const handleChangeColor = (color) => {
    setColorLocal(color);
  };
  const [updateTag, updateData] = useMutation(UPDATE_TAG);
  const [deleteTag, deleteData] = useMutation(DELETE_TAG);

  return (
    <Modal
      visible={visible}
      title={title}
      onOk={() => form.submit()}
      onCancel={handleClose}
      closable
      confirmLoading={updateData.loading}
      footer={[
        <Button key={'1'} onClick={handleClose}>
          Cancel
        </Button>,
        <Button
          key={'2'}
          danger
          loading={deleteData.lo}
          onClick={async () => {
            await deleteTag({ variables: { tagId: _id } });
            await refetch();

            return handleClose();
          }}
        >
          Delete
        </Button>,
        <Button
          key={'3'}
          type="primary"
          loading={updateData.loading}
          onClick={() => form.submit()}
        >
          Submit
        </Button>,
      ]}
    >
      <div style={{ background: 'white', padding: 20, paddingTop: 5 }}>
        <Form
          initialValues={{ type, name }}
          layout="vertical"
          name="update_tag"
          form={form}
          onFinish={async (values) => {
            if (!colorLocal) {
              return message.error('Color is required');
            }

            if (!name) {
              return message.error('Label is required');
            }

            try {
              await updateTag({
                variables: {
                  data: { ...values, color: colorLocal.hex },
                  tagId: _id,
                },
              });
              await refetch();

              return handleClose();
            } catch (error) {
              message.error(error.message);
            }
          }}
        >
          <FormItem name="name" label="Label" required>
            <Input name="name" placeholder="Tag label" required />
          </FormItem>
          <FormItem name="type" label="Tag type" required>
            <Select required>
              {tagTypes.items.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem name="color" label="Tag color" required>
            <ChromePicker
              onChange={handleChangeColor}
              onSwatchHover={handleChangeColor}
              color={colorLocal}
              width={410}
            />
          </FormItem>
        </Form>
      </div>
    </Modal>
  );
}
