import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useRouteMatch, Link } from 'react-router-dom';
import {
  Button,
  Input,
  Form,
  message,
  DatePicker,
  Upload,
  Select,
  Modal,
  Table,
  InputNumber,
  Tag,
  Checkbox,
} from 'antd';
import { useMutation, useQuery, useSubscription } from 'react-apollo';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';
import { omit, get, isEmpty } from 'lodash';
import NumberFormat from 'react-number-format';
import { GET_INVESTMENT, TAGS } from './apollo_queries';
import {
  DELETE_INVESMENT,
  INVEST_ADMIN,
  REDEEM,
  UPDATE_INVESTMENT,
  UPDATE_INVESTMENT_DOCUMENT,
} from './apollo_mutations';
import { LAST_TRANSACTION } from './apollo_subscriptions';
import { DocumentUploaderForm } from '../components/DocumentUploaderForm';
import { tagTypes } from '../guide';

const FormItem = Form.Item;
const { Option } = Select;

const H3 = styled.h3`
  color: #95a5a6;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

export const locale = 'en-us';

export const currencyFormatter = (value) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
  }).format(value);
};

export const currencyFormatterNoDecimal = (value) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(value);
};

export const currencyParser = (val) => {
  try {
    // for when the input gets clears
    if (typeof val === 'string' && !val.length) {
      val = '0.0';
    }

    // detecting and parsing between comma and dot
    let group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
    let decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
    let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
    reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
    //  => 1232.21 €

    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, '');
    //  => 1232.21

    // appending digits properly
    const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
    const needsDigitsAppended = digitsAfterDecimalCount > 2;

    if (needsDigitsAppended) {
      reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
    }

    return Number.isNaN(reversedVal) ? 0 : reversedVal;
  } catch (error) {
    console.error(error);
  }
};

export const currencyParserNoDecimal = (val) => {
  try {
    if (typeof val === 'string' && !val.length) {
      val = '0';
    }

    let group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');

    let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');

    reversedVal = reversedVal.replace(/[^0-9]/g, '');

    return Number.isNaN(reversedVal) ? 0 : reversedVal;
  } catch (error) {
    console.error(error);
  }
};

function Document({
  name = '',
  isAttached = true,
  handleAttach = () => {},
  handleDetach = () => {},
  link = '',
  loading = false,
}) {
  return (
    <Flex>
      <a href={link}>{name}</a>
      {isAttached ? (
        <Button type="primary" htmlType="button" onClick={handleDetach} danger>
          {loading ? <LoadingOutlined /> : 'Unattach'}
        </Button>
      ) : (
        <Button type="primary" htmlType="button" onClick={handleAttach}>
          {loading ? <LoadingOutlined /> : 'Attach'}
        </Button>
      )}
    </Flex>
  );
}

export function PropertyDetails({
  entityAccounts,
  constLoading,
  hasEntityUserAccount,
  hasEntityBankAccount,
  hasIndividualUserAccount,
  hasIndividualBankAccount,
}) {
  const [fromConsti, setFromConsti] = useState(false);
  const filtered = isEmpty(entityAccounts)
    ? []
    : entityAccounts.filter((item) => item.isDefault);
  const selectedUserAcount = isEmpty(filtered) ? null : filtered[0];
  const bankAccountsOfSelected = selectedUserAcount
    ? selectedUserAcount.bankAccounts
    : [];

  const history = useHistory();
  const match = useRouteMatch();
  const [transactionsModal, showTransactionsModal] = useState(false);
  const investmentId = get(match, 'params.id', '');
  const [form] = Form.useForm();
  const [smallForm] = Form.useForm();
  const [docId, setDocId] = useState('');
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const { data, loading, refetch, error } = useQuery(GET_INVESTMENT, {
    variables: { investmentId, page, count: pageCount },
  });
  const { data: tagData, loading: tagLoading } = useQuery(TAGS);
  const [update, i] = useMutation(UPDATE_INVESTMENT);
  const [updateDoc, doc] = useMutation(UPDATE_INVESTMENT_DOCUMENT);
  const [redeem, redeemData] = useMutation(REDEEM);
  const [investAdmin, investAdminData] = useMutation(INVEST_ADMIN);
  const [deleteMutate, deleteData] = useMutation(DELETE_INVESMENT);
  const investment = get(data, 'getInvestment', null);
  const transactions = get(investment, 'transactionItems.items', []);
  const totalTransaction = get(investment, 'transactionItems.total', 0);

  const deleteInvestment = async () => {
    try {
      const dData = await deleteMutate({ variables: { investmentId } });
      if (dData) {
        history.push('/properties-and-investments');
      }
    } catch (error) {
      message.error(`${error.message}`);
    }
  };

  const updateDocument = async (id, isAttached) => {
    try {
      setDocId(id);
      await updateDoc({ variables: { investmentDocumentId: id, isAttached } });
      await refetch();
      message.info('Update attachments success');
    } catch (error) {
      message.error(error.message);
    }
  };

  if (loading || constLoading || tagLoading) {
    return (
      <div className="web-load-wrap">
        <LoadingOutlined />
      </div>
    );
  }

  if (!hasIndividualUserAccount || !hasEntityUserAccount) {
    return (
      <div className="web-chat__main-content">
        <Link to="/register-to-sila">Register business account here</Link>
      </div>
    );
  }

  if (error) {
    return (
      <div className="web-chat__main-content">
        Error, please refresh the page
      </div>
    );
  }

  const onChange = (pagination, _, sorter) => {
    if (pagination.current !== page) {
      setPage(pagination.current);
      history.push(`?p=${pagination.current}`);
    }

    if (pageCount !== pagination.pageSize) {
      setPageCount(pagination.pageSize);
    }
  };

  let imageUrl = '';
  let documents = [];

  if (investment) {
    imageUrl = investment.imageUrl;
    documents = investment.documents;
  }

  const initialValues = omit(investment, [
    'funds',
    'imageUrl',
    'documents',
    'seriesNoteListing',
  ]);

  const columns = [
    {
      title: 'Transaction Type',
      dataIndex: 'type',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (amt) => (
        <NumberFormat
          displayType="text"
          value={parseFloat(amt)}
          thousandSeparator={true}
          prefix={'$'}
          renderText={(value) => <div>{value}</div>}
        />
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) =>
        status === 'success' ? (
          <Tag color="green">{status.toUpperCase()}</Tag>
        ) : (
          <Tag color="red">{status.toUpperCase()}</Tag>
        ),
    },
    {
      title: 'Sender',
      dataIndex: 'userAccount',
      render: (u) => <span>{u && u.accountName}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'userAccount',
      render: (u) => <span>{u && u.email}</span>,
    },
    {
      title: 'Create',
      dataIndex: 'createdAt',
      render: (createdAt) => <span>{moment(createdAt).format('LLL')}</span>,
    },
  ];

  let pendingRedeem = 0;

  if (!isEmpty(transactions)) {
    transactions.forEach((i) => {
      if (i.status === 'pending' && i.type === 'Withdraw') {
        pendingRedeem = pendingRedeem + i.amount;
      }
    });
  }

  const tags = data?.getInvestment?.typedTags || [];
  let propertyTagLocal = '';
  let loanTagLocal = '';

  if (!isEmpty(tags)) {
    const ls = tags.filter((i) => i && i.type === tagTypes.loan.name);
    const ps = tags.filter((i) => i && i.type === tagTypes.property.name);

    if (!isEmpty(ls)) {
      loanTagLocal = ls[0]?._id;
    }

    if (!isEmpty(ps)) {
      propertyTagLocal = ps[0]?._id;
    }
  }

  const allTags = tagData?.tags || [];

  const loanTags = allTags.filter((i) => i.type === tagTypes.loan.name);
  const propTags = allTags.filter((i) => i.type === tagTypes.property.name);

  return (
    <div className="web-chat__main-content">
      <div className="tr__header">
        <div className="top_header">
          <Modal
            width={1100}
            title="Transactions"
            visible={transactionsModal}
            onOk={() => showTransactionsModal(false)}
            onCancel={() => showTransactionsModal(false)}
          >
            <Table
              dataSource={transactions}
              columns={columns}
              rowKey="transaction_id"
              onChange={onChange}
              pagination={{
                total: totalTransaction,
                position: ['bottomCenter'],
                current: page,
                pageSize: pageCount,
                pageSizeOptions: ['10', '20', '30', '50'],
                hideOnSinglePage: true,
              }}
              loading={loading || constLoading}
            />
          </Modal>
          <div style={{ fontWeight: 'bolder' }} className="sub-text">
            {`Update property ${
              initialValues && initialValues.address
                ? `- ${initialValues.address}`
                : ''
            }`}
          </div>
          <div style={{ fontWeight: 'bolder' }} className="sub-text">
            <NumberFormat
              displayType="text"
              value={
                investment && investment.investmentAmount
                  ? investment.investmentAmount
                  : 0
              }
              thousandSeparator={true}
              prefix={'Investment amount: $'}
              renderText={(value) => <div>{value}</div>}
            />
          </div>
        </div>
        <div className="tr__b">
          {!isEmpty(selectedUserAcount) && (
            <Button
              style={{ marginRight: 10, marginLeft: 10 }}
              onClick={() => {}}
            >
              <NumberFormat
                displayType="text"
                value={get(selectedUserAcount, 'availableFund', 0).toFixed(2)}
                thousandSeparator={true}
                prefix={'$'}
                renderText={(value) => (
                  <div
                    style={{
                      fontWeight: 'bolder',
                      color: '#34495e',

                      fontStyle: 'italic',
                    }}
                  >
                    {`Constitution Balance:  ${value}`}
                  </div>
                )}
              />
            </Button>
          )}

          <Button
            style={{ marginRight: 10, marginLeft: 10 }}
            role="button"
            onClick={() => history.push(`/create-payout/${investmentId}`)}
            type="dashed"
          >
            Create payout
          </Button>

          <Button
            style={{ marginRight: 10, marginLeft: 10 }}
            role="button"
            onClick={async () => await refetch()}
            loading={loading}
            disabled={loading}
          >
            Refresh
          </Button>

          <Button
            style={{ marginRight: 10, marginLeft: 10 }}
            role="button"
            onClick={() => showTransactionsModal(true)}
            type="primary"
          >
            Transactions
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            role="button"
            onClick={() => history.goBack()}
            type="primary"
            danger
          >
            Back
          </Button>
        </div>
      </div>
      <div className="creating">
        <div className="create-b b-n b-r m-c">
          <div style={{ background: 'white' }}>
            <Form
              onFinish={async (values) => {
                if (!values.amount || !values.accountName) {
                  return message.error('Amount and bank account are required');
                }

                if (
                  investment &&
                  parseFloat(values.amount) > investment.investmentBalance
                ) {
                  message.error('Not enough balance');
                  return null;
                }

                try {
                  const { data } = await redeem({
                    variables: {
                      ...values,
                      investmentId,
                      userAccountId: selectedUserAcount._id,
                      amount: parseFloat(values.amount),
                    },
                  });

                  if (data && data.redeemAdmin) {
                    const me = get(data, 'redeemAdmin.message', '');
                    message.success(`${me}`.replace('.', ''), 8);
                  }

                  await refetch();
                  smallForm.resetFields();
                } catch (error) {
                  message.error(`${error.message}`.replace('GraphQL error:'));
                }
              }}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 20 }}
              name="withdraw"
              form={smallForm}
            >
              {!hasIndividualBankAccount && !hasEntityBankAccount && (
                <Fragment>
                  <H3>Message</H3>
                  <FormItem label="Status">No bank account linked</FormItem>
                </Fragment>
              )}
              <H3>Balance</H3>
              <FormItem label="Funded">
                <NumberFormat
                  displayType="text"
                  value={
                    investment && investment.investmentBalance
                      ? investment.investmentBalance
                      : 0
                  }
                  thousandSeparator={true}
                  prefix={'$'}
                  renderText={(value) => <div>{value}</div>}
                />
              </FormItem>

              <FormItem name="amount" label="Amount" required>
                <InputNumber
                  formatter={currencyFormatter}
                  parser={currencyParser}
                  style={{ width: '100%' }}
                  required
                />
              </FormItem>
              {selectedUserAcount && (
                <FormItem name="accountName" label="Select a bank account">
                  {!isEmpty(bankAccountsOfSelected) ? (
                    <Select style={{ width: 400 }}>
                      {bankAccountsOfSelected.map(
                        ({
                          account_name,
                          account_number,
                          account_status,
                          account_type,
                          bankAccountNameLinked,
                        }) => (
                          <Option key={account_name} value={account_name}>
                            {`${bankAccountNameLinked} <${account_number}> | ${account_status} | ${account_type}`}
                          </Option>
                        )
                      )}
                    </Select>
                  ) : (
                    <Link to="/register-to-sila">
                      Link a bank account to your default entity account here
                    </Link>
                  )}
                </FormItem>
              )}
              <FormItem label="Withdraw from Constlending Wallet">
                <Checkbox
                  checked={fromConsti}
                  onChange={(e) => setFromConsti(e.target?.checked || false)}
                />
              </FormItem>
              <FormItem label="Action">
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 8 }}
                  disabled={redeemData.loading}
                >
                  {redeemData.loading ? <LoadingOutlined /> : 'Withdraw'}
                </Button>
                <Button
                  htmlType="button"
                  style={{ marginRight: 8 }}
                  disabled={investAdminData.loading}
                  onClick={async () => {
                    const values = smallForm.getFieldsValue();
                    const { amount } = values;
                    if (!amount) {
                      return message.error('Amount is required');
                    }

                    try {
                      const { data } = await investAdmin({
                        variables: {
                          investmentId,
                          userAccountId: selectedUserAcount._id,
                          amount: parseFloat(values.amount),
                        },
                      });

                      if (data && data.investAdmin) {
                        const me = get(data, 'investAdmin.message', '');
                        message.success(`${me}`.replace('.', ''), 8);
                      }

                      await refetch();
                      smallForm.resetFields();
                    } catch (error) {
                      message.error(
                        `${error.message}`.replace('GraphQL error:')
                      );
                    }
                  }}
                >
                  {investAdminData.loading ? (
                    <LoadingOutlined />
                  ) : (
                    <div>
                      Fund this loan
                      <small>&nbsp;(bank account not required)</small>
                    </div>
                  )}
                </Button>
                <Button
                  htmlType="button"
                  style={{ marginRight: 8 }}
                  disabled={investAdminData.loading}
                  onClick={async () => {
                    const values = smallForm.getFieldsValue();
                    const { amount } = values;
                    if (!amount) {
                      return message.error('Amount is required');
                    }

                    try {
                      const { data } = await investAdmin({
                        variables: {
                          investmentId,
                          userAccountId: selectedUserAcount._id,
                          amount: parseFloat(values.amount),
                          isTransfer: true,
                        },
                      });

                      if (data && data.investAdmin) {
                        const me = get(data, 'investAdmin.message', '');
                        message.success(`${me}`.replace('.', ''), 8);
                      }

                      await refetch();
                      smallForm.resetFields();
                    } catch (error) {
                      message.error(
                        `${error.message}`.replace('GraphQL error:')
                      );
                    }
                  }}
                >
                  {investAdminData.loading ? (
                    <LoadingOutlined />
                  ) : (
                    <div>Transfer to Constlending Wallet</div>
                  )}
                </Button>
                <br />
              </FormItem>
            </Form>
          </div>
          <div style={{ background: 'white' }}>
            {!isEmpty(documents) && (
              <FormItem label="Current attachments">
                {documents.map((item) => (
                  <Document
                    loading={doc.loading && item._id === docId}
                    name={item.name}
                    link={item.url}
                    isAttached={item.isAttached}
                    key={item._id}
                    handleAttach={() => updateDocument(item._id, true)}
                    handleDetach={() => updateDocument(item._id, false)}
                  />
                ))}
              </FormItem>
            )}

            <DocumentUploaderForm
              investmentId={investmentId}
              refetch={refetch}
            />
          </div>
          <Form
            form={form}
            initialValues={{
              ...initialValues,
              propertyTag: propertyTagLocal,
              loanTag: loanTagLocal,
              maturityDate: investment.maturityDate
                ? moment(investment.maturityDate)
                : null,
            }}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 20 }}
            onFinish={async (values) => {
              const {
                image,
                maturityDate,
                documents,
                seriesNoteListing,
                loanTag,
                propertyTag,
              } = values;

              const tags = [];

              if (loanTag) {
                tags.push(loanTag);
              }

              if (propertyTag) {
                tags.push(propertyTag);
              }

              const data = omit(values, [
                'image',
                'documents',
                'maturityDate',
                'seriesNoteListing',
                'loanTag',
                'propertyTag',
              ]);

              const payload = { data };
              payload.data['tags'] = tags;

              payload['investmentId'] = investmentId;

              if (!isEmpty(image)) {
                payload['image'] = image[0]['originFileObj'];
              }
              if (!isEmpty(seriesNoteListing)) {
                payload['seriesNoteListing'] =
                  seriesNoteListing[0]['originFileObj'];
              }

              if (maturityDate) {
                payload['data']['maturityDate'] = moment(maturityDate).format();
              }
              if (!isEmpty(documents)) {
                payload['documents'] = documents.map(
                  (item) => item['originFileObj']
                );
              }

              if (payload?.data?.asIsValuePurchasePrice) {
                payload.data[
                  'asIsValuePurchasePrice'
                ] = payload.data.asIsValuePurchasePrice.toString();
              }

              if (payload?.data?.rehabBudget) {
                payload.data[
                  'rehabBudget'
                ] = payload.data.rehabBudget.toString();
              }

              if (payload?.data?.arv) {
                payload.data['arv'] = payload.data.arv.toString();
              }

              payload['data']['investmentAmount'] = parseFloat(
                values.investmentAmount
              );

              payload['data']['fundingAmount'] = parseFloat(
                values.fundingAmount
              );

              try {
                const { data } = await update({ variables: payload });

                if (data) {
                  message.info('Update success');
                }

                await refetch();
              } catch (error) {
                message.error('Error', error.message);
              }
            }}
            name="update-investment"
          >
            <div style={{ background: 'white' }}>
              {(imageUrl ||
                !isEmpty(documents) ||
                (investment && !!investment.seriesNoteListing)) && (
                <H3>Files</H3>
              )}
              {imageUrl && (
                <FormItem label="Current property image">
                  <img
                    alt="Property"
                    style={{ marginBottom: 30 }}
                    width={300}
                    src={imageUrl}
                  />
                </FormItem>
              )}

              {investment && investment.seriesNoteListing && (
                <FormItem label="Current">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={investment.seriesNoteListing}
                  >
                    Series Note Listing
                  </a>
                </FormItem>
              )}

              <H3>Information</H3>
              <FormItem name="address" label="Address" required>
                <Input name="address" placeholder="Property address" required />
              </FormItem>
              <FormItem name="city" label="City" required>
                <Input name="city" placeholder="City" required />
              </FormItem>
              <FormItem name="state" label="State" required>
                <Input name="state" placeholder="State" required />
              </FormItem>
              <FormItem name="zipCode" label="ZIP code" required>
                <Input name="zipCode" placeholder="ZIP code" required />
              </FormItem>

              <FormItem name="propertyTag" label="Property tag">
                <Select required>
                  {propTags.map((item) => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem name="loanTag" label="Loan tag">
                <Select required>
                  {loanTags.map((item) => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem
                name="image"
                label="Property Image"
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }

                  return e && e.fileList;
                }}
              >
                <Upload
                  name="image"
                  listType="picture"
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>
                    Click to upload display image
                  </Button>
                </Upload>
              </FormItem>

              <FormItem name="status" label="Investment status">
                <Select placeholder="Select here">
                  <Option value="previously_funded">Previously Funded</Option>
                  <Option value="available_investment">
                    Available Investment
                  </Option>
                </Select>
              </FormItem>

              <FormItem name="loanStatus" label="Loan status">
                <Select placeholder="Select here">
                  <Option value="active">Active</Option>
                  <Option value="repaid">Repaid</Option>
                </Select>
              </FormItem>

              <FormItem
                name="seriesNoteListing"
                label="Series Note Listing"
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }

                  return e && e.fileList;
                }}
              >
                <Upload
                  name="seriesNoteListing"
                  listType="text"
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>
                    Click to upload series note listing
                  </Button>
                </Upload>
              </FormItem>

              <H3>Payment Terms</H3>
              <FormItem name="originalLoanTerm" label="Original Loan Term">
                <Input
                  name="originalLoanTerm"
                  placeholder="Original Loan Term"
                  type="number"
                  addonAfter="months"
                />
              </FormItem>
              <FormItem
                name="originalLoanTermInfo"
                label="Original Loan Term info"
              >
                <Input
                  name="originalLoanTermInfo"
                  placeholder="Original Loan Term info"
                />
              </FormItem>
              <FormItem name="maturityDate" label="Maturity Date">
                <DatePicker placeholder="Maturity Date" name="maturityDate" />
              </FormItem>
              <FormItem name="extensionOption" label="Extension Option">
                <Input name="extensionOption" placeholder="Extension Option" />
              </FormItem>
              <FormItem
                name="extensionOptionInfo"
                label="Extension Option info"
              >
                <Input
                  name="extensionOptionInfo"
                  placeholder="Extension Option info"
                />
              </FormItem>
              <FormItem name="extensionFee" label="Extension Fee">
                <Input
                  name="extensionFee"
                  placeholder="Extension Fee"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>
              <FormItem name="extensionFeeInfo" label="Extension Fee info">
                <Input
                  name="extensionFeeInfo"
                  placeholder="Extension Fee info"
                />
              </FormItem>
              <FormItem name="prepaymentPenalty" label="Prepayment Penalty">
                <Input
                  name="prepaymentPenalty"
                  placeholder="Prepayment Penalty"
                />
              </FormItem>
              <FormItem
                name="prepaymentPenaltyInfo"
                label="Prepayment Penalty info"
              >
                <Input
                  name="prepaymentPenaltyInfo"
                  placeholder="Prepayment Penalty info"
                />
              </FormItem>

              <H3>Yield Details</H3>
              <FormItem
                name="investorYieldMaturity"
                label="Investor Yield to Maturity"
              >
                <Input
                  name="investorYieldMaturity"
                  placeholder="Investor Yield to Maturity"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>
              <FormItem
                name="investorYieldMaturityInfo"
                label="Investor Yield to Maturity info"
              >
                <Input
                  name="investorYieldMaturityInfo"
                  placeholder="Investor Yield to Maturity info"
                />
              </FormItem>

              <FormItem
                name="borrowerPaymentInsurance"
                label="Borrower Payment Insurance"
              >
                <Input
                  name="borrowerPaymentInsurance"
                  placeholder="Borrower Payment Insurance"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>
              <FormItem
                name="borrowerPaymentInsuranceInfo"
                label="Borrower Payment Insurance Info"
              >
                <Input
                  name="borrowerPaymentInsuranceInfo"
                  placeholder="Borrower Payment Insurance Info"
                />
              </FormItem>

              <FormItem name="servicingFee" label="Servicing Fee">
                <Input
                  name="servicingFee"
                  placeholder="Servicing Fee"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>
              <FormItem name="netYield" label="Net Yield">
                <Input
                  name="netYield"
                  placeholder="Net Yield"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>

              <H3>Note Information</H3>
              <FormItem
                name="investmentAmount"
                label="Investment Amount"
                required
              >
                <InputNumber
                  formatter={currencyFormatterNoDecimal}
                  parser={currencyParser}
                  style={{ width: '100%' }}
                  required
                />
              </FormItem>
              <FormItem
                name="investmentAmountInfo"
                label="Investment Amount info"
              >
                <Input
                  name="investmentAmountInfo"
                  placeholder="Investment Amount info"
                />
              </FormItem>

              <FormItem name="fundingAmount" label="Funding Amount" required>
                <InputNumber
                  formatter={currencyFormatterNoDecimal}
                  parser={currencyParser}
                  style={{ width: '100%' }}
                  required
                />
              </FormItem>

              <FormItem name="percentFunded" label="Percent Funded">
                <Input
                  name="percentFunded"
                  placeholder="Percent Funded"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>

              <FormItem name="notePosition" label="Note Position">
                <Input name="notePosition" placeholder="Note Position" />
              </FormItem>
              <FormItem name="notePositionInfo" label="Note Position info">
                <Input
                  name="notePositionInfo"
                  placeholder="Note Position info"
                />
              </FormItem>
              <FormItem name="asIsLoanToValue" label="As-Is Loan to Value">
                <Input
                  name="asIsLoanToValue"
                  placeholder="As-Is Loan to Value"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>
              <FormItem name="arvLoanToValue" label="ARV Loan to Value">
                <Input
                  name="arvLoanToValue"
                  placeholder="ARV Loan to Value"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>
              <FormItem name="repaymentType" label="Repayment Type">
                <Input name="repaymentType" placeholder="Repayment Type" />
              </FormItem>
              <FormItem name="repaymentTypeInfo" label="Repayment Type Info">
                <Input
                  name="repaymentTypeInfo"
                  placeholder="Repayment Type Info"
                />
              </FormItem>
              <FormItem name="termLength" label="Term Length">
                <Input
                  name="termLength"
                  placeholder="Term Length"
                  addonAfter="months"
                  type="number"
                />
              </FormItem>
              <FormItem name="termLengthInfo" label="Term Length info">
                <Input name="termLengthInfo" placeholder="Term Length info" />
              </FormItem>
              <FormItem name="loanPurpose" label="Loan Purpose">
                <Input name="loanPurpose" placeholder="Loan Purpose" />
              </FormItem>
              <FormItem name="loanPurposeInfo" label="Loan Purpose info">
                <Input name="loanPurposeInfo" placeholder="Loan Purpose info" />
              </FormItem>
              <FormItem name="exitFee" label="Exit Fee">
                <Input
                  name="exitFee"
                  placeholder="Exit Fee"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>
              <FormItem name="exitFeeInfo" label="Exit Fee info">
                <Input name="exitFeeInfo" placeholder="Exit Fee info" />
              </FormItem>
              <FormItem
                name="borrowerCreditScore"
                label="Borrower Credit Score"
              >
                <Input
                  name="borrowerCreditScore"
                  placeholder="Borrower Credit Score"
                />
              </FormItem>

              <H3>Property Details</H3>
              <FormItem name="noteListing" label="Note listing #" required>
                <Input
                  name="noteListing"
                  placeholder="Note listing #"
                  required
                />
              </FormItem>

              <FormItem name="performance" label="Performance">
                <Input name="performance" placeholder="Performance" />
              </FormItem>

              <FormItem name="numberOfUnits" label="Number of Units">
                <Input name="numberOfUnits" placeholder="Number of Units" />
              </FormItem>
              <FormItem
                name="asIsValuePurchasePrice"
                label="As-Is Value or Purchase Price"
              >
                <InputNumber
                  formatter={currencyFormatter}
                  parser={currencyParser}
                  style={{ width: '100%' }}
                />
              </FormItem>
              <FormItem name="asIsLtv" label="As-Is LTV">
                <Input
                  name="asIsLtv"
                  placeholder="As-Is LTV"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>
              <FormItem name="rehabBudget" label="Rehab Budget">
                <InputNumber
                  formatter={currencyFormatter}
                  parser={currencyParser}
                  style={{ width: '100%' }}
                />
              </FormItem>
              <FormItem name="rehabBudgetInfo" label="Rehab Budget info">
                <Input name="rehabBudgetInfo" placeholder="Rehab Budget info" />
              </FormItem>
              <FormItem name="arv" label="ARV">
                <InputNumber
                  formatter={currencyFormatter}
                  parser={currencyParser}
                  style={{ width: '100%' }}
                />
              </FormItem>
              <FormItem name="arvLtv" label="ARV LTV">
                <Input
                  name="arvLtv"
                  placeholder="ARV LTV"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>

              <H3>Risk Rating</H3>
              <FormItem name="riskRating" label="Rating">
                <Input name="riskRating" placeholder="Rating" />
              </FormItem>
              <FormItem name="riskRatingInfo" label="Description">
                <Input name="riskRatingInfo" placeholder="Description" />
              </FormItem>

              <H3>Deal Overview</H3>
              <FormItem name="dealOverview" label="Description">
                <Input name="dealOverview" placeholder="Description" />
              </FormItem>

              <FormItem
                wrapperCol={{
                  sm: {
                    span: 16,
                    offset: 6,
                  },
                }}
              >
                <Button
                  type="default"
                  htmlType="button"
                  style={{ marginRight: 8 }}
                  onClick={() => {
                    form.resetFields();
                  }}
                  disabled={i.loading}
                >
                  Reset
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 12 }}
                  disabled={i.loading}
                >
                  {i.loading ? <LoadingOutlined /> : 'Save'}
                </Button>

                <Button
                  loading={deleteData.loading}
                  onClick={deleteInvestment}
                  type="primary"
                  htmlType="button"
                  style={{ marginRight: 8 }}
                  danger
                >
                  Delete
                </Button>
              </FormItem>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
