import React, { useEffect } from 'react';

export function Expenses() {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location.href =
        'https://constitutionmgmt.typeform.com/to/etfFzaHi';
    }
  }, []);

  return (
    <a href="https://constitutionmgmt.typeform.com/to/etfFzaHi">
      Go to expenses
    </a>
  );
}
