import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import get from 'lodash/get';
import store from 'store2';
import { useHistory } from 'react-router-dom';

import { AppWrapper } from './styles';
import Sidebar from './components/Sidebar';
import ChatApp from './pages/ChatApp';
import Home from './pages/Home';
import ProspectiveTenant from './pages/ProspectiveTenant';
import ProspectiveEmployee from './pages/ProspectiveEmployee';
import CreateSession from './pages/CreateSession';
import UpdateSession from './pages/UpdateSession';
import { CONST_USER, INITIALIZE_AUTH } from './pages/apollo_queries';
import SendFromCsv from './pages/SendFromCsv';
import SendSMSToEmployees from './pages/SendSMSToEmployees';
import Employee from './pages/Employee';
import { Properties } from './pages/Properties';
import { PropertyDetails } from './pages/PropertyDetails';
import { AddProperty } from './pages/AddProperty';
import { RegisterToSila } from './pages/RegisterToSila';
import { isEmpty } from 'lodash';
import { SilaFailures } from './pages/SilaFailures';
import { SilaFailureDetails } from './pages/SilaFailureDetails';
import { AddSilaFailure } from './pages/AddSilaFailure';
import { Documents } from './pages/Documents';
import { CreatePayout } from './pages/CreatePayout';
import { PayoutDetail } from './pages/PayoutDetail';
import { Payouts } from './pages/Payouts';
import { Investors } from './pages/Investors';
import { InvestorDetails } from './pages/InvestorDetails';
import { Articles } from './pages/Articles';
import { UpdateArticle } from './pages/UpdateArticle';
import { AddArticle } from './pages/AddArticle';
import { Tags } from './pages/Tags';
import { SilaUserHandles } from './pages/SilaUserHandles';
import { Borrowers } from './pages/Borrowers';
import { Payments } from './pages/Payments';

const { Sider, Content } = Layout;
const ADMIN_EMAILS = [
  'joselie@constitutionmgmt.com',
  'ricardo@constitutionmgmt.com',
  'kyle@constitutionmgmt.com',
  'joe@constitutionmgmt.com',
  'bryan@constitutionmgmt.com',
  'alvin@constitutionmgmt.com',
  'paul@constitutionmgmt.com',
  'jerwin@constitutionmgmt.com',
  'vincent@constitutionmgmt.com',
];

function ClientApp() {
  const history = useHistory();
  const { data, error, loading } = useQuery(INITIALIZE_AUTH);
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    function authCheck() {
      if (error && !loading) {
        const networkErrorString = get(
          error,
          'networkError.result.errors[0].message',
          ''
        );

        if (
          (networkErrorString &&
            (networkErrorString.includes('jwt expired') ||
              networkErrorString.includes('Login required'))) ||
          error.message.includes('Login required')
        ) {
          store.remove('constitution-token');
          history.push('/login?error=login_required');
        }
      }
    }

    authCheck();
  }, [error, history, loading]);

  const {
    data: constData,
    error: constError,
    loading: constLoading,
    refetch,
  } = useQuery(CONST_USER);
  const emailAdmin = get(data, 'initializeAuth.email', '');
  const isAllowed = emailAdmin && ADMIN_EMAILS.includes(emailAdmin);
  const userAtt = get(constData, 'admin', {});
  const hasIndividualUserAccount = !isEmpty(
    get(constData, 'admin.individualAccount', false)
  );
  const hasIndividualBankAccount = !isEmpty(
    get(constData, 'admin.individualAccount.bankAccounts', false)
  );
  const hasEntityUserAccount = !isEmpty(
    get(constData, 'admin.entityAccounts[0]', false)
  );
  const hasEntityBankAccount = !isEmpty(
    get(constData, 'admin.entityAccounts[0].bankAccounts', false)
  );
  const individualAccount = get(constData, 'admin.individualAccount', null);
  const entityAccounts = get(constData, 'admin.entityAccounts', null);

  const sila = {
    constData,
    constError,
    constLoading,
    refetch,
    hasIndividualBankAccount,
    hasIndividualUserAccount,
    hasEntityBankAccount,
    hasEntityUserAccount,
    individualAccount,
    entityAccounts,
    ...userAtt,
  };

  return (
    <AppWrapper>
      <Layout>
        <Sider
          collapsible
          collapsed={collapse}
          onCollapse={(collapse) => setCollapse(collapse)}
          width={260}
          className="main-s"
        >
          <Sidebar isAllowed={isAllowed} />
        </Sider>
        <Sider
          breakpoint="xs"
          collapsedWidth="0"
          collapsible
          width={200}
          className="mobile-s"
        >
          <Sidebar isAllowed={isAllowed} />
        </Sider>
        <Layout>
          <Content>
            <Switch>
              <Route path="/" render={() => <Home {...sila} />} exact />
              <Route
                path="/prospective-tenant"
                render={() => <ProspectiveTenant {...sila} />}
                exact
              />
              <Route
                path="/prospective-employee"
                render={() => (
                  <ProspectiveEmployee personCategory="Prospective Employee" />
                )}
                exact
              />
              <Route path="/create-session" component={CreateSession} exact />
              <Route path="/session/:id" component={ChatApp} exact />
              <Route
                path="/session/:id/update"
                component={UpdateSession}
                exact
              />
              <Route path="/send-csv" component={SendFromCsv} exact />
              <Route
                path="/employee"
                render={() => <Employee personCategory="Employee" />}
                exact
              />
              <Route
                path="/send-csv-employee"
                component={SendSMSToEmployees}
                exact
              />
              <Route
                path="/properties-and-investments"
                render={() => <Properties {...sila} />}
                exact
              />
              <Route
                path="/properties-and-investments/:id"
                render={() => <PropertyDetails {...sila} />}
                exact
              />
              <Route
                path="/add-property"
                render={() => <AddProperty {...sila} />}
                exact
              />
              <Route
                path="/register-to-sila"
                render={() => <RegisterToSila {...sila} />}
                exact
              />
              <Route
                path="/sila-failures"
                render={() => <SilaFailures {...sila} />}
                exact
              />
              <Route
                path="/sila-failures/:id"
                render={() => <SilaFailureDetails {...sila} />}
                exact
              />
              <Route
                path="/add-sila-failure"
                render={() => <AddSilaFailure {...sila} />}
                exact
              />
              <Route
                path="/documents"
                render={() => <Documents {...sila} />}
                exact
              />
              <Route
                path="/create-payout/:id"
                render={() => <CreatePayout {...sila} />}
                exact
              />
              <Route
                path="/payouts"
                render={() => <Payouts {...sila} />}
                exact
              />
              <Route
                path="/payouts/:id"
                render={() => <PayoutDetail {...sila} />}
                exact
              />
              <Route
                path="/users"
                render={() => <Investors {...sila} />}
                exact
              />

              <Route
                path="/users/:id"
                render={() => <InvestorDetails {...sila} />}
                exact
              />

              <Route
                path="/articles"
                render={() => <Articles {...sila} />}
                exact
              />
              <Route path="/articles/:id" component={UpdateArticle} exact />
              <Route path="/add-article" component={AddArticle} exact />
              <Route path="/tags" component={Tags} exact />
              <Route
                path="/sila-user-handles"
                component={SilaUserHandles}
                exact
              />
              <Route path="/borrowers" component={Borrowers} exact />
              <Route path="/payments" component={Payments} exact />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </AppWrapper>
  );
}

export default ClientApp;
