import React from 'react';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import ClientApp from './ClientApp';
import Login from './Login';
import Auth from './Auth';
import { Hours } from './Hours';
import { Repairs } from './Repairs';
import { Expenses } from './Expenses';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect from="/login/failure" to="/login" />
        <Route path="/login/success/:token" component={Auth} />
        <Route path="/login" component={Login} exact />
        <Route path="/hours" component={Hours} exact />
        <Route path="/repairs" component={Repairs} exact />
        <Route path="/expenses" component={Expenses} exact />
        <Route path="/" component={ClientApp} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
