import React, { useEffect } from 'react';
import get from 'lodash/get';
import store from 'store2';
import { useRouteMatch, useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

function Auth() {
  const match = useRouteMatch();
  const history = useHistory();
  const token = get(match, 'params.token', '');

  useEffect(() => {
    function saveToken() {
      if (token) {
        store.set('constitution-token', token);
        const decoded = jwtDecode(token);
        const email = get(decoded, 'email', '');
        store.set('constitution_email', email);

        return history.push('/');
      } else {
        return history.push('/login');
      }
    }

    saveToken();
  }, [history, token]);

  return <div> </div>;
}

export default Auth;
