import gql from 'graphql-tag';

export const GET_SESSIONS = gql`
  query getSessions(
    $page: Float!
    $count: Float!
    $q: String
    $sortBy: String
    $sortDirection: String
    $personCategory: String
    $onlyWithUnread: Boolean
  ) {
    getSessions(
      page: $page
      count: $count
      q: $q
      sortBy: $sortBy
      sortDirection: $sortDirection
      personCategory: $personCategory
      onlyWithUnread: $onlyWithUnread
    ) {
      _id
      initialMessageReplied
      phoneNumber
      propertyId
      email
      name
      state
      city
      address
      showingAt
      confirmed
      leadInterested
      sectionEight
      createdAt
      updatedAt
      personCategory
      comment
      job
      unreadMessages
    }
  }
`;

export const GET_SESSION = gql`
  query getSession($id: String!, $mode: String) {
    getSession(id: $id) {
      _id
      initialMessageReplied
      phoneNumber
      propertyId
      email
      name
      state
      city
      total(mode: $mode)
      address
      showingAt
      confirmed
      leadInterested
      sectionEight
      createdAt
      updatedAt
      personCategory
      comment
      job
    }
  }
`;

export const GET_MESSAGES = gql`
  query getMessages($data: MessagesPagination!) {
    getMessages(data: $data) {
      _id
      sessionId
      owner
      text
      media {
        downloadLink
        previewLink
        type
      }
      error
      createdAt
      updatedAt
    }
  }
`;

export const GET_EMAILS = gql`
  query getEmails($data: EmailPagination!) {
    getEmails(data: $data) {
      _id
      owner
      text
      media {
        previewLink
        downloadLink
        type
      }
      error
      subject
      createdAt
      updatedAt
    }
  }
`;

export const SESSION_COUNT = gql`
  query sessionCount($personCategory: String) {
    sessionCount(personCategory: $personCategory) {
      count
    }
  }
`;

export const INITIALIZE_AUTH = gql`
  query initializeAuth {
    initializeAuth {
      name
      email
    }
  }
`;

export const CONST_USER = gql`
  query admin {
    admin {
      _id
      ppm
      subscriptionAgreement
      email
      isAdmin
      isCertified
      totalPayouts
      totalUsers
      totalArticles
      pendingRewards {
        _id
        amount
        accountName
        userAccountId
        consumed
        createdAt
      }
      individualAccount {
        _id
        accountName
        firstName
        lastName
        kyc
        email
        availableFund
        bankAccounts {
          account_number
          account_type
          account_name
          account_link_status
          account_status
          active
          bankAccountNameLinked
          createdAt
        }
      }
      entityAccounts {
        _id
        accountName
        kyc
        email
        isCertified
        isDefault
        availableFund
        transactions(count: ${5}, page: ${1}) {
          status
          sila_amount
          transaction_id
          transaction_type
        }
        bankAccounts {
          account_number
          account_type
          account_name
          account_link_status
          account_status
          active
          bankAccountNameLinked
          createdAt
        }
      }
    }
  }
`;

export const GET_INVESTMENTS = gql`
  query getInvestments(
    $page: Float!
    $count: Float!
    $status: String
    $sortBy: String
    $sortDirection: String
    $q: String
  ) {
    getInvestments(
      page: $page
      count: $count
      status: $status
      sortBy: $sortBy
      sortDirection: $sortDirection
      q: $q
    ) {
      _id
      address
      investmentAmount
      investmentBalance
      termLength
      propertyType
      imageUrl
      city
      state
      zipCode
      term
      amount
      createdAt
      updatedAt
      maturityDate
      status
    }
  }
`;

export const INVESTMENT_COUNT = gql`
  query investmentCount {
    investmentCount
  }
`;

export const GET_INVESTMENT = gql`
  query getInvestment($investmentId: String!, $count: Float!, $page: Float!) {
    getInvestment(investmentId: $investmentId) {
      _id
      investmentBalance
      address
      investorYieldMaturity
      investorYieldMaturityInfo
      borrowerPaymentInsurance
      borrowerPaymentInsuranceInfo
      servicingFee
      netYield
      investmentAmount
      investmentAmountInfo
      notePosition
      notePositionInfo
      asIsLoanToValue
      arvLoanToValue
      repaymentType
      termLength
      termLengthInfo
      loanPurpose
      loanPurposeInfo
      exitFee
      exitFeeInfo
      borrowerCreditScore
      propertyType
      numberOfUnits
      asIsValuePurchasePrice
      percentFunded
      asIsLtv
      rehabBudget
      rehabBudgetInfo
      loanStatus
      status
      arv
      arvLtv
      ltv
      riskRating
      riskRatingInfo
      dealOverview
      originalLoanTerm
      originalLoanTermInfo
      extensionOption
      extensionOptionInfo
      extensionFee
      extensionFeeInfo
      prepaymentPenalty
      prepaymentPenaltyInfo
      imageUrl
      city
      state
      zipCode
      term
      amount
      createdAt
      updatedAt
      maturityDate
      fundingAmount
      noteListing
      seriesNoteListing
      performance
      earnings
      documents {
        _id
        name
        url
        investmentId
        isAttached
        createdAt
        updatedAt
      }
      funds {
        _id
        investmentId
        amount
        createdAt
        updatedAt
      }
      transactions(count: $count, page: $page) {
        status
        sila_amount
        transaction_type
        created
        transaction_id
      }
      transactionItems(count: $count, page: $page) {
        total
        items {
          type
          amount
          status
          createdAt
          userAccount {
            accountName
            email
          }
        }
      }
      typedTags {
        _id
        name
        type
        color
        createdAt
        updatedAt
      }
    }
  }
`;

export const NAICS_CODES = gql`
  query NAICS_CODES {
    getNaicsCodes {
      subcategory
      code
    }
  }
`;

export const BUSINESS_TYPES = gql`
  query BUSINESS_TYPES {
    getBusinessTypes {
      name
      label
      uuid
      requires_certification
    }
  }
`;

export const CBT = gql`
  query NBT {
    getBusinessTypes {
      name
      label
      uuid
      requires_certification
    }
  }
`;

export const SILA_FAILURES = gql`
  query silaFailures($type: String!) {
    silaFailures(type: $type) {
      _id
      formattedTag
      rawTag
      instruction
      type
      requirements
      createdAt
      updatedAt
    }
  }
`;

export const SILA_FAILURE = gql`
  query silaFailure($id: String!) {
    silaFailure(id: $id) {
      _id
      formattedTag
      rawTag
      instruction
      type
      requirements
    }
  }
`;

export const PAYOUTS = gql`
  query payouts(
    $page: Float
    $count: Float
    $sortBy: String
    $sortDirection: String
  ) {
    payouts(
      page: $page
      count: $count
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      _id
      title
      email
      amount
      createdAt
      investment {
        _id
        address
        investmentAmount
        investmentBalance
      }
    }
  }
`;

export const INVESTMENT_PAYOUT = gql`
  query getInvestment($investmentId: String!) {
    getInvestment(investmentId: $investmentId) {
      _id
      address
      investmentAmount
      investmentBalance
      fundingAmount
      status
      funds {
        _id
        amount
        status
        isManual
        currentlyOwedAmount
        currentlyOwnedPercentage
        userAccount {
          _id
          accountName
          type
          lastName
          firstName
        }
      }
    }
  }
`;

export const PAYOUT = gql`
  query payout($payoutId: String!) {
    payout(payoutId: $payoutId) {
      _id
      amount
      email
      investment {
        _id
        address
        investmentAmount
        investmentBalance
        fundingAmount
        status
      }
      transactions {
        transaction_id
        bank_account_name
        sila_amount
        status
        transaction_type
        created_epoch
        last_update_epoch
        toAccount {
          _id
          firstName
          lastName
          type
          address
          accountName
        }
      }
    }
  }
`;

export const INVESTORS = gql`
  query users(
    $q: String
    $page: Float!
    $sortBy: String
    $sortDirection: String
    $count: Float!
  ) {
    users(
      q: $q
      page: $page
      sortBy: $sortBy
      sortDirection: $sortDirection
      count: $count
    ) {
      _id
      isAdmin
      email
      createdAt
      hasFailedKyc
      hasNotYetRequestKyc
      pendingInvestTotal
      pendingDepositTotal
      currentInvestTotal
      currentDepositTotal
      individualAccount {
        _id
        firstName
        lastName
      }
    }
  }
`;

export const BORROWERS = gql`
  query borrowers(
    $q: String
    $page: Float!
    $sortBy: String
    $sortDirection: String
    $count: Float!
  ) {
    borrowers(
      q: $q
      page: $page
      sortBy: $sortBy
      sortDirection: $sortDirection
      count: $count
    ) {
      _id
      isAdmin
      email
      firstName
      lastName
      createdAt
      hasFailedKyc
      hasNotYetRequestKyc
      pendingInvestTotal
      pendingDepositTotal
      currentInvestTotal
      currentDepositTotal
      individualAccount {
        _id
        firstName
        lastName
      }
    }
  }
`;

export const INVESTOR = gql`
  query getUserByAdmin($userId: String!, $page: Float!, $count: Float!) {
    getUserByAdmin(userId: $userId) {
      _id
      address
      isAdmin
      email
      firstName
      lastName
      phoneNumber
      updatedAt
      createdAt
      hasFailedKyc
      transactionItemsCount
      individualAccount {
        _id
        kyc
        firstName
        lastName
        accountName
        kycFails {
          _id
          silaFailure {
            _id
            rawTag
          }
          resolved
          documentStatus
          documents {
            id
            filename
            url
          }
        }
      }
      entityAccounts {
        _id
        kyc
        accountName
        address
        kycFails {
          _id
          silaFailure {
            _id
            rawTag
          }
          resolved
          documentStatus
          documents {
            id
            filename
            url
          }
        }
      }
      transactionItems(transactionItemInput: { count: $count, page: $page }) {
        _id
        amount
        status
        createdAt
        type
        userAccount {
          firstName
          lastName
          accountName
          _id
        }
      }
    }
  }
`;

export const ARTICLES = gql`
  query articles(
    $q: String
    $sortDirection: String
    $sortBy: String
    $count: Float!
    $page: Float!
  ) {
    articles(
      q: $q
      sortDirection: $sortDirection
      sortBy: $sortBy
      count: $count
      page: $page
    ) {
      _id
      title
      subtitle
      slug
      parentId
      banner
      route
      tags
      updateBy
      createdBy
      createdAt
      updatedAt
      archived
      content
    }
  }
`;

export const ARTICLE = gql`
  query article($id: String!) {
    article(id: $id) {
      _id
      title
      subtitle
      slug
      parentId
      banner
      route
      tags
      updateBy
      createdBy
      createdAt
      updatedAt
      content
      archived
    }
  }
`;

export const TAGS = gql`
  query tags {
    tags {
      _id
      name
      type
      color
      createdAt
      updatedAt
    }
  }
`;

export const HANDLES = gql`
  query {
    userAccounts {
      _id
      email
      firstName
      lastName
      accountName
      type
      createdAt
      kyc
      isCertified
      pendingInvestTotal
      pendingDepositTotal
      currentInvestTotal
      currentDepositTotal
      user {
        _id
        email
        isAdmin
      }
    }
  }
`;

export const PAYMENTS = gql`
  query adminPayments($transactionItemInput: TransactionItemInput) {
    adminPayments(transactionItemInput: $transactionItemInput) {
      _id
      amount
      status
      createdAt
      type
      userAccount {
        firstName
        lastName
        accountName
        _id
      }
    }
  }
`;
