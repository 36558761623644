import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Input, Form, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { LoadingOutlined } from '@ant-design/icons';
import Editor from 'react-quill';

import { CREATE_SILA_FAILURE } from './apollo_mutations';
import { get, trim } from 'lodash';

const FormItem = Form.Item;

export function AddSilaFailure() {
  const history = useHistory();
  const [instructionLocal, setInstructionLocal] = useState('');
  const [requirementsLocal, setRequirementsLocal] = useState('');

  const [create, { loading, error }] = useMutation(CREATE_SILA_FAILURE);

  if (loading) {
    return (
      <div className="web-load-wrap">
        <LoadingOutlined />
      </div>
    );
  }

  if (error) {
    return (
      <div className="web-chat__main-content">
        Error, please refresh the page
      </div>
    );
  }

  return (
    <div className="web-chat__main-content">
      <div className="tr__header">
        <div className="top_header">
          <div style={{ fontWeight: 'bolder' }} className="sub-text">
            Add a Sila Failure
          </div>
        </div>
        <div className="tr__b">
          <Button
            style={{ marginLeft: 10 }}
            role="button"
            onClick={() => history.push('/')}
            type="primary"
            danger
          >
            Back
          </Button>
        </div>
      </div>
      <div className="creating">
        <div className="create-b b-n b-r m-c">
          <div style={{ background: 'white' }}>
            <Form
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 20 }}
              onFinish={async (values) => {
                try {
                  const data = { ...values };

                  if (instructionLocal) {
                    data['instruction'] = instructionLocal;
                  }

                  if (requirementsLocal) {
                    data['requirements'] = requirementsLocal;
                  }

                  if (values.rawTag) {
                    const formattedTag = trim(values.rawTag)
                      .replace(/\s/g, '-')
                      .toLowerCase();
                    data.formattedTag = formattedTag;
                  }

                  const resp = await create({ variables: { data } });

                  if (resp && resp.data) {
                    message.success('Sucess');
                    const id = get(resp, 'data.createSilaFailure._id', '');

                    if (id) {
                      history.push(`/sila-failures/${id}`);
                    }
                  }
                } catch (error) {
                  message.error(error.message);
                }
              }}
            >
              <FormItem name="rawTag" label="Title" required>
                <Input name="rawTag" placeholder="Failure title" required />
              </FormItem>
              <FormItem name="type" label="Type" required>
                <Input name="type" placeholder="Sila type" required />
              </FormItem>
              <FormItem label="Instruction to client">
                <Editor
                  theme="snow"
                  value={instructionLocal}
                  onChange={setInstructionLocal}
                />
              </FormItem>
              <FormItem label="Requirements">
                <Editor
                  theme="snow"
                  value={requirementsLocal}
                  onChange={setRequirementsLocal}
                />
              </FormItem>
              <FormItem label="Action">
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 8 }}
                  disabled={loading}
                >
                  Submit
                </Button>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
