import React from 'react';
import store from 'store2';
import { Menu } from 'antd';
import {
  MailOutlined,
  LogoutOutlined,
  DatabaseOutlined,
  UsergroupAddOutlined,
  PlusSquareTwoTone,
  CloudUploadOutlined,
  SettingOutlined,
  UserSwitchOutlined,
  ProfileOutlined,
  // UsergroupDeleteOutlined,
  // MoneyCollectTwoTone,
  // HomeTwoTone,
  // RightSquareFilled,
  // ExclamationCircleTwoTone,
  // PlusCircleFilled,
  // BookOutlined,
  // PaperClipOutlined,
  // FileMarkdownTwoTone,
  // StarTwoTone,
  // FastBackwardOutlined,
  // FastBackwardFilled,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const { SubMenu } = Menu;

function Sidebar({ isAllowed = false }) {
  const history = useHistory();

  const handleClick = (item) => {
    if (item.key === 'logout') {
      store.clearAll();
      return history.push('/login');
    } else {
      switch (item.key) {
        case '1':
          return history.push('/');
        case '2':
          return history.push('/prospective-tenant');
        case '3':
          return history.push('/prospective-employee');
        case '4':
          return history.push('/create-session');
        case '5':
          return history.push('/send-csv');
        case '6':
          return history.push('/employee');
        case '7':
          return history.push('/send-csv-employee');
        // case '8':
        //   return history.push(isAllowed ? '/properties-and-investments' : '/');
        // case '9':
        //   return history.push(isAllowed ? '/add-property' : '/');
        // case '10':
        //   return history.push(isAllowed ? '/register-to-sila' : '/');
        // case '11':
        //   return history.push(isAllowed ? '/sila-failures' : '/');
        // case '12':
        //   return history.push(isAllowed ? '/add-sila-failure' : '/');
        // case '13':
        //   return history.push('/documents');
        // case '14':
        //   return history.push('/payouts');
        // case '15':
        //   return history.push('/users');
        // case '16':
        //   return history.push('/articles');
        // case '17':
        //   return history.push('/tags');
        // case '18':
        //   return history.push('/sila-user-handles');
        // case '19':
        //   return history.push('/borrowers');
        // case '20':
        //   return history.push('/payments');
        default:
          return history.push('/');
      }
    }
  };

  return (
    <Menu
      onClick={handleClick}
      style={{ width: '100%' }}
      mode="inline"
      theme="dark"
    >
      <Menu.Item key="1" icon={<MailOutlined />}>
        CM Sessions
      </Menu.Item>
      <Menu.Item key="2" icon={<DatabaseOutlined />}>
        Prospective Tenants
      </Menu.Item>
      <Menu.Item key="3" icon={<UsergroupAddOutlined />}>
        Prospective Employee
      </Menu.Item>
      <Menu.Item key="4" icon={<PlusSquareTwoTone />}>
        Create a Session
      </Menu.Item>
      <Menu.Item key="5" icon={<CloudUploadOutlined />}>
        Send from CSV
      </Menu.Item>

      <SubMenu
        key={'cm'}
        title={
          <span>
            <SettingOutlined />
            <span className="cm-side">Construction Management</span>
          </span>
        }
      >
        <Menu.Item key={'6'} icon={<UserSwitchOutlined />}>
          Employees
        </Menu.Item>
        <Menu.Item key={'7'} icon={<ProfileOutlined />}>
          Send from CSV
        </Menu.Item>
      </SubMenu>

      {/* {isAllowed && (
        <SubMenu
          key={'la'}
          title={
            <span>
              <UsergroupDeleteOutlined />
              <span className="cm-side">Lending Administrator</span>
            </span>
          }
        >
          <Menu.Item key={'10'} icon={<RightSquareFilled />}>
            Manage Sila
          </Menu.Item>
          <Menu.Item key={'8'} icon={<MoneyCollectTwoTone />}>
            Properties / Investments
          </Menu.Item>
          <Menu.Item key={'9'} icon={<HomeTwoTone />}>
            Add Property
          </Menu.Item>

          <Menu.Item key={'11'} icon={<ExclamationCircleTwoTone />}>
            Sila Failures
          </Menu.Item>

          <Menu.Item key={'12'} icon={<PlusCircleFilled />}>
            Add Sila Failure
          </Menu.Item>

          <Menu.Item key={'13'} icon={<BookOutlined />}>
            Documents
          </Menu.Item>
          <Menu.Item key={'14'} icon={<MoneyCollectTwoTone />}>
            Payouts
          </Menu.Item>
          <Menu.Item key={'15'} icon={<UsergroupAddOutlined />}>
            Users
          </Menu.Item>
          <Menu.Item key={'16'} icon={<PaperClipOutlined />}>
            CMS
          </Menu.Item>
          <Menu.Item key={'17'} icon={<FileMarkdownTwoTone />}>
            Tags
          </Menu.Item>
          <Menu.Item key={'18'} icon={<StarTwoTone />}>
            Sila User Handles
          </Menu.Item>
          <Menu.Item key={'19'} icon={<FastBackwardOutlined />}>
            Borrowers
          </Menu.Item>
          <Menu.Item key={'20'} icon={<FastBackwardFilled />}>
            Payments
          </Menu.Item>
        </SubMenu>
      )} */}

      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );
}

export default Sidebar;
