import React, { useState } from 'react';
import { Layout, message, Button, Modal, Input, Popover } from 'antd';
import { CSVLink } from 'react-csv';
import { Table } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';
import { Link } from 'react-router-dom';

import CSVUploader from '../components/CSVUploader';
import { CREATE_EMPLOYEE_SESSION_BULK } from './apollo_mutations';

const { TextArea } = Input;

function Message({ text, onSave = () => {} }) {
  const [editModal, showEditModal] = useState(false);
  const [messageLocal, setMessageLocal] = useState(text || '');
  return (
    <div>
      <Popover
        content={
          <div style={{ width: 540 }}>
            <TextArea
              style={{ textAlign: 'left' }}
              rows={12}
              value={messageLocal}
              onChange={(e) => setMessageLocal(e.target.value)}
              disabled
            />
          </div>
        }
      >
        <div style={{ display: 'inline' }}>{truncate(text, { length: 40, separator: ' ' })}</div>
      </Popover>
      <Button onClick={() => showEditModal(true)} style={{ display: 'inline', float: 'right' }} danger>
        Edit
      </Button>
      <Modal
        title="Edit message to employee"
        visible={editModal}
        onCancel={() => showEditModal(false)}
        onOk={() => {
          onSave(messageLocal);
          showEditModal(false);
        }}
        width={680}
        destroyOnClose
      >
        <TextArea
          style={{ textAlign: 'left' }}
          rows={12}
          value={messageLocal}
          onChange={(e) => setMessageLocal(e.target.value)}
        />
      </Modal>
    </div>
  );
}

const { Content } = Layout;

function SendSMSToEmployees() {
  const [uploadItems, setUploadItems] = useState([]);

  const [mutate] = useMutation(CREATE_EMPLOYEE_SESSION_BULK);

  const csvData = [['workerName', 'phoneNumber', 'projectAddress', 'dailyTaskDescription', 'startTime', 'endTime']];
  csvData[0].push('message');
  const columns = csvData[0].map((item) => ({
    title: item,
    dataIndex: item,
    key: item,
    render: (text, record, index) =>
      item === 'message' ? (
        <Message
          text={text}
          onSave={(newText) => {
            const itemsCopy = [...uploadItems];
            itemsCopy[index] = { ...record, message: newText };
            setUploadItems(itemsCopy);
          }}
        />
      ) : (
        <span>{text}</span>
      ),
  }));

  const handleSubmit = async () => {
    try {
      if (isEmpty(uploadItems)) {
        message.error('CSV should contain items');
        return null;
      }

      const items = uploadItems.map((item) => ({
        phoneNumber: item.phoneNumber,
        message: item.message,
        name: item.workerName,
        personCategory: 'Employee',
      }));

      const { data } = await mutate({
        variables: {
          data: {
            items,
          },
        },
      });

      if (data) {
        setUploadItems([]);
        message.success(`Successfully saved (${data?.createEmployeeSessionBulk.length})`);
      }
    } catch (error) {
      message.error('Please try again');
    }
  };

  return (
    <Layout className="layout">
      <Content style={{ padding: 10 }}>
        <CSVLink data={csvData}>
          <DownloadOutlined /> Download accepted CSV format here
        </CSVLink>
        <CSVUploader
          onDrop={(result) => {
            setUploadItems(result);
          }}
          message={
            <aside>
              <p>
                <b>All fields are required</b>
              </p>
            </aside>
          }
        />
        <Table rowKey="phoneNumber" dataSource={uploadItems} columns={columns} />
        <Button type="primary" onClick={handleSubmit} style={{ marginTop: 15, marginRight: 10 }}>
          Submit
        </Button>
        <Button type="primary" danger onClick={() => setUploadItems([])} style={{ marginTop: 15, marginRight: 10 }}>
          Clear
        </Button>
        <Link to="/employee" style={{ marginTop: 15 }}>
          Go to Employees list
        </Link>
      </Content>
    </Layout>
  );
}

export default SendSMSToEmployees;
