import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { message } from 'antd';

import { LoginWrapper } from './styles';
import google from './google/web/1x/btn_google_signin_dark_normal_web.png';

const Login = () => {
  const location = useLocation();
  const { error } = qs.parse(location.search);

  useEffect(() => {
    function checkErrorParam() {
      if (error && error === 'login_required') {
        message.error('Login required');
      }
    }

    checkErrorParam();
  });

  return (
    <LoginWrapper>
      <div className="l-box">
        <h2>Constitution G-Suite Login</h2>
        <a href={process.env.REACT_APP_GOOGLE_AUTH}>
          <img
            className="google-icon"
            src={google}
            alt="Google Signin"
            role="button"
            onClick={() => {}}
          />
        </a>
      </div>
    </LoginWrapper>
  );
};

export default Login;
