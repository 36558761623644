import { Alert, Button, Card, Form, Input, message, Tag, Upload } from 'antd';
import React, { Fragment } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useMutation, useQuery } from 'react-apollo';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Editor from 'react-quill';
import { DELETE_ARTICLE, UPDATE_ARTICLE } from './apollo_mutations';
import { useState } from 'react';
import moment from 'moment';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import store from 'store2';
import { get, isEmpty, omit } from 'lodash';
import { ARTICLE } from './apollo_queries';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const FormItem = Form.Item;

export function UpdateArticle() {
  const match = useRouteMatch();
  const history = useHistory();
  const [tagsLocal, setTagsLocal] = useState([]);
  const [contentLocal, setContentLocal] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const id = get(match, 'params.id', '');
  const { data, loading, error, refetch } = useQuery(ARTICLE, {
    variables: { id },
  });
  const [updateArticle, updateData] = useMutation(UPDATE_ARTICLE);
  const [deleteArticle, deleteData] = useMutation(DELETE_ARTICLE);

  const {
    title,
    subtitle,
    slug,
    banner,
    route,
    updatedAt,
    content,
    archived,
    tags,
  } = get(data, 'article', {});

  const [form] = Form.useForm();

  useEffect(() => {
    setContentLocal(content || '');
  }, [content, loading]);

  useEffect(() => {
    if (!isEmpty(tags)) {
      const ts = tags.map((i) => ({ value: i, label: i }));
      setTagsLocal(ts);
    } else {
      setTagsLocal([]);
    }
  }, [tags, loading]);

  if (loading) {
    return (
      <div className="web-load-wrap">
        <LoadingOutlined />
      </div>
    );
  }

  if (error) {
    return (
      <div className="web-chat__main-content">
        Please refresh page, if error persists contact JC
      </div>
    );
  }

  return (
    <div style={{ paddingBottom: 30 }} className="web-chat__main-content">
      <div className="tr__header">
        <div className="top_header">
          <div style={{ fontWeight: 'bolder' }} className="sub-text">
            View / Update article
          </div>
        </div>
        <div className="tr__b">
          <Button
            style={{
              marginLeft: 10,
              background: archived ? '#3498db' : '#1abc9c',
              color: '#fff',
            }}
            role="button"
            onClick={async () => {
              const authorEmail = store.get('constitution_email');
              const data = {
                updateBy: authorEmail,
                id,
                archived: !archived,
              };

              try {
                await updateArticle({ variables: data });
                await refetch();
                message.success('Success');
              } catch (error) {
                message.error(error.message);
              }
            }}
          >
            {archived ? 'Unarchived' : 'Archived'}
          </Button>
          <Button
            style={{ marginLeft: 20 }}
            role="button"
            onClick={() => setIsEditing(!isEditing)}
          >
            {isEditing ? 'View' : 'Edit'}
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            role="button"
            onClick={() => history.goBack()}
            type="primary"
            danger
          >
            Back
          </Button>
        </div>
      </div>
      <div className="creating">
        <div className="create-b b-n m-c">
          <div style={{ background: 'white' }}>
            {isEditing ? (
              <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                form={form}
                initialValues={{ title, subtitle }}
                onFinish={async (values) => {
                  const authorEmail = store.get('constitution_email');
                  const data = omit(values, ['banner']);

                  if (!content) {
                    return message.error('Content is required');
                  }

                  if (!isEmpty(values.banner)) {
                    data['banner'] = values.banner[0]['originFileObj'];
                  }

                  if (!isEmpty(tagsLocal)) {
                    data['tags'] = tagsLocal.map((i) => i.value.toLowerCase());
                  }

                  data['updateBy'] = authorEmail;
                  data['content'] = contentLocal;
                  data['id'] = id;

                  try {
                    await updateArticle({ variables: data });
                    await refetch();
                    message.success('Success');
                    setIsEditing(false);
                  } catch (error) {
                    message.error(error.message);
                  }
                }}
                style={{ padding: 10, paddingRight: 50, paddingLeft: 0 }}
              >
                <FormItem name="title" label="Title" required>
                  <Input
                    name="title"
                    placeholder="Title of the article"
                    required
                  />
                </FormItem>

                <FormItem name="subtitle" label="Subtitle">
                  <Input
                    name="subtitle"
                    placeholder="Subtitle is not required"
                  />
                </FormItem>

                <FormItem label="Tags">
                  <CreatableSelect
                    styles={{
                      control: (styles) => ({ ...styles, borderRadius: 2 }),
                    }}
                    onChange={(allTags) => {
                      if (!isEmpty(allTags)) {
                        return setTagsLocal(allTags);
                      }

                      return null;
                    }}
                    value={tagsLocal}
                    isClearable
                    isSearchable
                    isMulti
                  />
                </FormItem>

                <FormItem label="Content" required>
                  <Editor
                    theme="snow"
                    value={contentLocal}
                    onChange={setContentLocal}
                  />
                </FormItem>

                <FormItem
                  name="banner"
                  label="Article banner"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => {
                    if (Array.isArray(e)) {
                      return e;
                    }

                    return e && e.fileList;
                  }}
                >
                  <Upload
                    name="banner"
                    listType="picture"
                    beforeUpload={() => false}
                  >
                    <Button icon={<UploadOutlined />}>
                      Click to set banner image
                    </Button>
                  </Upload>
                </FormItem>
                {banner && (
                  <FormItem label="Current banner">
                    <img
                      src={banner}
                      alt="banner"
                      style={{ width: 160, height: 90, marginTop: 10 }}
                    />
                  </FormItem>
                )}

                <FormItem
                  wrapperCol={{
                    sm: {
                      span: 16,
                      offset: 4,
                    },
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: 8 }}
                    disabled={updateData.loading}
                    loading={updateData.loading}
                  >
                    Save
                  </Button>
                </FormItem>
              </Form>
            ) : (
              <Card
                loading={loading}
                style={{ width: '100%' }}
                cover={<img alt={title} src={banner} style={{ height: 220 }} />}
              >
                <Card
                  style={{ border: 'none' }}
                  title={
                    <Fragment>
                      <span
                        style={{
                          fontSize: 16,
                          letterSpacing: 0.6,
                          fontWeight: 'bold',
                        }}
                      >
                        {title}
                      </span>
                      {subtitle && (
                        <span
                          style={{
                            fontSize: 10,
                            letterSpacing: 0.6,
                            marginLeft: 10,
                            fontWeight: 'bold',
                            opacity: 0.6,
                          }}
                        >
                          {subtitle}
                        </span>
                      )}
                    </Fragment>
                  }
                >
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                  <small>
                    <div>{`Last updated: ${moment(updatedAt).format(
                      'LLL'
                    )}`}</div>
                  </small>
                  <div style={{ height: 20 }}></div>
                  {!isEmpty(tags) &&
                    tags.map((i) => (
                      <Tag key={i} color="blue">
                        {i}
                      </Tag>
                    ))}
                </Card>
              </Card>
            )}

            <Card
              title={
                <h5 style={{ padding: 0, margin: 0 }}>
                  Article will be available at
                </h5>
              }
              style={{ marginTop: 20 }}
            >
              <Alert
                message={
                  <pre style={{ margin: 0, padding: 0, fontSize: 12 }}>
                    <samp>{`Current domain + /${route}${
                      slug ? `/${slug}` : ''
                    } -> https://staging.constlending.com/${route}${
                      slug ? `/${slug}` : ''
                    } (sample only)`}</samp>
                  </pre>
                }
              />
            </Card>
            <Button
              loading={deleteData.loading}
              disabled={deleteData.loading}
              style={{
                background: '#c0392b',
                color: '#fff',
                marginTop: 20,
                marginBottom: 40,
              }}
              onClick={() => {
                MySwal.fire({
                  title: 'Please confirm delete action',
                  icon: 'warning',
                  confirmButtonText: 'Delete',
                  confirmButtonColor: '#e74c3c',
                  showCancelButton: true,
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    await deleteArticle({ variables: { id } });
                    window.location.href = '/articles';
                  }
                });
              }}
            >
              Delete article permanently
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
